import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import ExcursionImage from "components/products/ExcursionImage";
import {money, mkt_money} from "components/utils/Money.jsx";
import {duration_time_by_duration_type} from "components/utils/Duration.jsx";

function Product(props){
    const renderIncludedFeatures = () => {
        console.log("props.product.product_includeds", props.product)
        if (props.product.product_includeds && props.product.product_includeds.length > 0) {
            return (
                <div className="feature-included">
                    <h2>{i18n.t("excursions.what's_included?")}</h2>
                    <ul>
                        {props.product.product_includeds.map((incl) => (
                            <li key={incl.id}>{incl.description}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const renderNotIncludedFeatures = () => {
        if (props.product.product_not_includeds && props.product.product_not_includeds.length > 0) {
            return (
                <div className="feature-not-included">
                    <h2>{i18n.t("excursions.what's_not_included?")}</h2>
                    <ul>
                        {props.product.product_not_includeds.map((incl) => (
                            <li key={incl.id}>{incl.description}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const createSelectItemDataLayer = () => {
        window.dataLayer.push({ ecommerce: null }); 
        window.dataLayer.push({
            'event': "select_item",
            'ecommerce': {
              'item_list_id': "products_list",
              'item_list_name': "Products List",
              'items': [
                {
                    'item_id': props.product.id,
                    'item_name': props.product.title,
                    'price': mkt_money(props.product),
                    'item_brand': (props.product.agency ? props.product.agency?.name : "Outdoor Index"),
                    'item_category': (props.product.zone && props.product.zone.name || ""),
                    'item_variant': 'Excursion',
                    'affiliation': 'Outdoor index',
                    'quantity': '1'
                  }
              ]
             }
        });
    };
    
  return (
        <>
            <div className={"slide posts "+ (props.product && props.product.zone && props.product.zone.name.toLowerCase().split(" ").join("-") || "") }>				
                
                <div className="free">
                    {props.product.private_check &&
                                <span className="flex-left"> 
                                    <span className="tooltip">
                                        <span className='mdl-chip brand-label'>
                                            <span className='mdl-chip__text'>
                                                {i18n.t("ondemand.label")}
                                            </span>
                                        </span>
                                    </span>
                                </span>
                    } 

                    <ExcursionImage
                        key={"excursion-image-"+ props.product.id}
                        product = {props.product}
                        image_format={props.image_format}
                        skeleton_height={(props.skeleton_height ? props.skeleton_height : 352)}
                        createSelectItemDataLayer={createSelectItemDataLayer}
                    />
                </div>

                <div className="info-slide">
                    {props.product.free_check && 
                        <h4 className="suggestions"> {i18n.t('home.suggestions')}</h4>
                    }
                    {
                    props.product.zone &&
                        <p className="zone">{props.product.zone.name}</p>
                    }
                    
                    <h3><a href={"/products/" + (props.product.slug != null ? props.product.slug : props.product.id)} title="View Details" onClick={() => createSelectItemDataLayer()} target="_blank">{props.product.title}</a></h3>
                    
                    {
                        props.product.free_check && props.product.thumbnail_description &&
                            <p className="thumbnail" >{props.product.thumbnail_description}</p>
                    }
                    
                    {/* Cambiar segundo parametro a false si no quieres mostra duration_type */}
                    <p className="price">
                        {i18n.t('from')} {money(props.product)} | {duration_time_by_duration_type(props.product, false)}
                    </p>
                    {
                        props.extra_info &&
                            <div className="landing-extra-info">
                                {renderIncludedFeatures()}
                                {renderNotIncludedFeatures()}
                                <div className="landing-product-container">
                                    <a class="landing-product-view"  href={"/products/" + (props.product.slug != null ? props.product.slug : props.product.id)}  title="View Details" target="_blank">{i18n.t('active_admin.landing.more_info') }</a>
                                </div>    
                            </div>
                            
                    }
                </div>
            </div>
        </>
  );
}

export default Product

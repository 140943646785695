import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "about_us": {
    "content": {
      "from_the_inside_out": "Several kilometers of long walks and trails can testify to the uncountable conversations about nature, mountains, geography, local communities, and the way of life “at the end of the world”. On each of these chats with people from all over the world, no matter where they were from, I started to notice the same question: ¿is there anything else I can do here? ¿how can I get there? ¿What other activities can you do around? ¿what can I do after these?",
      "in_this_way": "That´s how motivation was raised and with a solid problem to solve, Viewnia.com was born. An agency with a strong focus on connecting travelers with an appropriate nature experience for each of them, this by offering the widest selection of outdoor excursions on every area of Patagonia available on market."
    },
    "foot": {
      "viewnia_com_check_us_out": "<strong>Outdoor Index/ Puerto Natales, Patagonia. Chile.</strong>"
    },
    "head": {
      "about_us": "About Us",
      "creating_a_quality_online": "“On every walk through nature, you receive much more than you are looking for” (John Muir)",
      "creating_a_quality_online_sub": "I was 29 when I quit my graphic designer full-time job and full youth depression to accept a job guiding tours in Patagonia. Had no knowledge of trekking, less of the tourism industry. At that stage, interacting with nature was only about a short walk in the forest or enjoying a horse ride or bike. Although everything was a bit naive, I understood the basic principle of my new job: to connect. Being a facilitator allows & amplifies the connection of the tourist with their surroundings by giving the proper information and support for the experience to be as significant as possible. Sometimes the situation required to share a lot of information with others just to share the silence and let the views and the vast nature of the area do the job."
    },
    "last": {
      "viewnia_com_believes_in_a": "<strong>Outdoor Index is a marketplace specialized in promoting and selling adventure excursions and outdoor activities in Chile, connecting outdoor enthusiasts with meaningful experiences. We focus on offering local and authentic experiences in remote destinations, facilitating the search, information, and booking of activities through a modern and high-quality platform that guarantees safety and ease of use.</strong>",
      "viewnia_com_believes_in_a_10": "An adventure can be a transcendental experience towards personal growth. Best decisions are born from a crisis. For some people that crisis or challenge can be a half-day walk for others can be ice climbing. For some a kayak navigation down the river for others an 8-day trekking expedition. To help you to transform your trip into a life-changing and transcendental experience is one of our priorities.",
      "viewnia_com_believes_in_a_11": "<strong>4.Activation of touristic ecosystems.</strong>",
      "viewnia_com_believes_in_a_12": "When visiting a place as a tourist is very important to know that your visit is not only a money income for the operator that you are adventuring with. With your spending you will have the chance to support the little market of the community, or maybe the gas station, and more. Your visit to the local souvenir store for local handmade products and by that your visit is transformed into a whole activation of the economic ecosystem of the community.",
      "viewnia_com_believes_in_a_13": "We want to be activators of these ecosystems by generating movement around all the touristic chains of every locality that we work on, supporting local littler businesses, generating exhibitions, and promoting a collaborative spirit.",
      "viewnia_com_believes_in_a_2": "<strong>1.Diversify to protect</strong>",
      "viewnia_com_believes_in_a_3": "The wide amount of excursions we offer has always been a big differentiation factor by covering vastly every place on which we work. We focus on little-known places to decongest those who are very well known and had been affected and eroded by their high population demand. Is an indirect way to conserve and very important and very transcendental on the user experience on a different kind of trip.",
      "viewnia_com_believes_in_a_4": "¿Do you help to conserve by choosing differently? Yes! The number of corners to contemplate in Patagonia is infinite, the chances of finding that WOW moment are everywhere, behind every tree. Your decisions and places you visit or stop visiting are an important way of conservation. That´s why we always are going to support new places, new routes, new operators, and new destinies.",
      "viewnia_com_believes_in_a_5": "<strong>2.To be agents for change for conscious tourism.</strong>",
      "viewnia_com_believes_in_a_6": "Tourism has environmental, socio-cultural, and economic effects. It moves and interacts inside of these three factors actively. Our purpose is to be agents for change by educating towards more responsible practices on these subjects.",
      "viewnia_com_believes_in_a_7": "To be an agent for change is an active role, persistent, and focused. The change for conscious tourism is to change toward responsible decisions, for both tourists and operators. A conscious focus allows you to value up a trip from the story, allows you to increase the capacity of wonder, allows your trip to transcend by giving purpose and teachings, and that stays on you for much longer than your trip.",
      "viewnia_com_believes_in_a_8": "<strong>3.Foment adventure as a personal growth experience.</strong>",
      "viewnia_com_believes_in_a_9": "Interaction with nature is an enormous resource of challenge and it´s challenging that pushes us to go further, going out of our comfort zone and testing our capacities, decision-making will, our head, and our mind. To visualize and maybe to integrate a better version of ourselves."
    }
  },
  "accommodation": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "category": "Category",
    "description": "About the product",
    "location": "Location",
    "meeting_address": "Address",
    "our_location": "Our location",
    "policies": "Policies",
    "price": "Price",
    "profiles": "Profiles",
    "save_excursion": "Save product",
    "title": "Title",
    "vacancies": "Vacancies",
    "zone": "Zone"
  },
  "active_admin": {
    "access_denied": {
      "message": "You are not authorized to perform this action."
    },
    "admin_users": "Admin Users",
    "agencies": "Agencies",
    "agency": "Agency",
    "any": "Any",
    "batch_actions": {
      "action_label": "%{title} Selected",
      "button_label": "Batch Actions",
      "default_confirmation": "Are you sure you want to do this?",
      "delete_confirmation": "Are you sure you want to delete these %{plural_model}?",
      "labels": {
        "destroy": "Delete"
      },
      "selection_toggle_explanation": "(Toggle Selection)",
      "succesfully_destroyed": {
        "one": "Successfully deleted 1 %{model}",
        "other": "Successfully deleted %{count} %{plural_model}"
      }
    },
    "blank_slate": {
      "content": "There are no %{resource_name} yet.",
      "link": "Create one"
    },
    "blog": "Blog",
    "cancel": "Cancel",
    "categories": "Categories",
    "comments": {
      "add": "Add Comment",
      "author": "Author",
      "author_missing": "Anonymous",
      "author_type": "Author Type",
      "body": "Body",
      "created_at": "Created",
      "delete": "Delete Comment",
      "delete_confirmation": "Are you sure you want to delete these comments?",
      "errors": {
        "empty_text": "Comment wasn't saved, text was empty."
      },
      "no_comments_yet": "No comments yet.",
      "resource": "Resource",
      "resource_type": "Resource Type",
      "title_content": "Comments (%{count})"
    },
    "comments_tab": "Comments",
    "covers": "Home",
    "create_another": "Create another %{model}",
    "dashboard": "Dashboard",
    "dashboard_welcome": {
      "call_to_action": "To add dashboard sections, checkout 'app/admin/dashboard.rb'",
      "welcome": "Welcome to Active Admin. This is the default dashboard page."
    },
    "delete": "Delete",
    "delete_confirmation": "Are you sure you want to delete this?",
    "delete_model": "Delete %{model}",
    "details": "%{model} Details",
    "devise": {
      "change_password": {
        "submit": "Change my password",
        "title": "Change your password"
      },
      "email": {
        "title": "Email"
      },
      "links": {
        "forgot_your_password": "Forgot your password?",
        "resend_confirmation_instructions": "Resend confirmation instructions",
        "resend_unlock_instructions": "Resend unlock instructions",
        "sign_in": "Sign in",
        "sign_in_with_omniauth_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "login": {
        "remember_me": "Remember me",
        "submit": "Login",
        "title": "Login"
      },
      "password": {
        "title": "Password"
      },
      "password_confirmation": {
        "title": "Confirm Password"
      },
      "resend_confirmation_instructions": {
        "submit": "Resend confirmation instructions",
        "title": "Resend confirmation instructions"
      },
      "reset_password": {
        "submit": "Reset My Password",
        "title": "Forgot your password?"
      },
      "sign_up": {
        "submit": "Sign up",
        "title": "Sign up"
      },
      "subdomain": {
        "title": "Subdomain"
      },
      "unlock": {
        "submit": "Resend unlock instructions",
        "title": "Resend unlock instructions"
      },
      "username": {
        "title": "Username"
      }
    },
    "download": "Download:",
    "dropdown_actions": {
      "button_label": "Actions"
    },
    "edit": "Edit",
    "edit_model": "Edit %{model}",
    "empty": "Empty",
    "excursion_images": "Excursion images",
    "excursions": "Excursions",
    "filters": {
      "buttons": {
        "clear": "Clear Filters",
        "filter": "Filter"
      },
      "predicates": {
        "contains": "Contains",
        "ends_with": "Ends with",
        "equals": "Equals",
        "from": "From",
        "greater_than": "Greater than",
        "gteq_datetime": "Greater or equal to",
        "less_than": "Less than",
        "lteq_datetime": "Lesser or equal to",
        "starts_with": "Starts with",
        "to": "To"
      }
    },
    "has_many_delete": "Delete",
    "has_many_new": "Add New %{model}",
    "has_many_remove": "Remove",
    "home": "Home",
    "home_collections": "Home Collections",
    "index_list": {
      "block": "List",
      "blog": "Blog",
      "grid": "Grid",
      "table": "Table"
    },
    "kpis": {
      "abandoned_cart_subtitle": "Orders left in the backpack",
      "abandoned_cart_title": "Abandoned Backpacks",
      "conversion_chart_subtitle": "Conversion rate from the last year",
      "conversion_chart_title": "Conversion Chart",
      "conversion_last_year": "Conversion rate last year",
      "conversion_subtitle": "Updated to date",
      "conversion_title": "Conversion rate",
      "countries": "Countries",
      "countries_subtitle": "Those who visit us the most",
      "country_anon": "Anonymous",
      "goals": "Goal",
      "last_day": "Previous day",
      "new_orders_subtitle": "Registered this month",
      "new_orders_title": "New Orders",
      "new_users_subtitle": "Registered last month",
      "new_users_subtitle_box": "Visit flows of the day",
      "new_users_title": "New Users",
      "new_users_title_box": "Today's users",
      "no_sale_goal": "No sale goal defined",
      "observed_dollar": "Observed dollar value",
      "observed_dollar_subtitle": "Dollar value define by the Central Bank",
      "paid_orders_subtitle": "Registered this month",
      "paid_orders_title": "Paid Orders",
      "posts_viewed_subtitle": "Most popular for now",
      "posts_viewed_title": "Most viewed post",
      "products_selled_subtitle": "In the last year",
      "products_selled_title": "Best Selling Products",
      "products_viewed_subtitle": "Most popular for now",
      "products_viewed_title": "Most viewed products",
      "sale_goals_subtitle": "Total yield for this month",
      "sale_goals_title": "Sales target",
      "show_more": "See more",
      "to": "to",
      "today_excursions_subtitle": "Tourists leaving today for their tour.",
      "today_excursions_title": "Excursions today",
      "users": "Users",
      "users_month_subtitle": "Statistical balance of monthly users.",
      "users_month_title": "Monthly statistics",
      "users_number": "Number of users",
      "users_total_subtitle": "Registered from the start",
      "users_total_title": "Total Users",
      "visitors": "Visitors",
      "visits_today_subtitle": "Flows for today",
      "visits_today_title": "Today's visits"
    },
    "landing": {
      "body": "Body",
      "cover": "Cover",
      "excerpt": "Here you can complete all the necessary information to create a Landing. Always make sure that the cover photo you upload have a good resolution and are attractive to the tourist. Remember to give clear and reliable information.",
      "meta_description": "Meta description",
      "meta_title": "Meta title",
      "more_info": "More info",
      "place": "Place",
      "product": "Excursion",
      "review": "Review",
      "save_landing": "Save landing",
      "tilte1": "Create Landing",
      "title": "Title",
      "title2": "Meta",
      "title3": "Cover",
      "title4": "Reviews",
      "title5": "FAQ"
    },
    "landings": "Landings",
    "logout": "Logout",
    "macro_zones": "Macro Zones",
    "mailer": "Mailer",
    "main_content": "Please implement %{model}#main_content to display content.",
    "move": "Move",
    "new_model": "New %{model}",
    "newsletter_covers": "Newsletter covers",
    "next": "Next",
    "notifications": "Notifications",
    "observed_dollar_update": "Update",
    "orders": "Orders",
    "pages": "Pages",
    "pagination": {
      "empty": "No %{model} found",
      "entry": {
        "one": "entry",
        "other": "entries"
      },
      "multiple": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{total}</b> in total",
      "multiple_without_total": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b>",
      "one": "Displaying <b>1</b> %{model}",
      "one_page": "Displaying <b>all %{n}</b> %{model}",
      "per_page": "Per page: "
    },
    "payment_methods": "Payment methods",
    "post": {
      "author_name": "Author name",
      "author_sm": "Author Social Media",
      "content": "Content",
      "cover": "Cover",
      "label": "Label",
      "published": "Published",
      "recommended_posts": "Recommended Posts",
      "subtitle": "Subtitle",
      "tags": "Tag",
      "title": "Title",
      "unpublished": "Unpublished"
    },
    "powered_by": "Powered by %{active_admin} %{version}",
    "previous": "Previous",
    "private": "Private",
    "products": "Products",
    "profiles": "Profiles",
    "promotions": "Promotions",
    "properties": "Properties",
    "question_subjects": "Question subjects",
    "questions": "Questions",
    "requests": "Requests",
    "review": {
      "author": "Name",
      "body": "Review text",
      "country": "Country",
      "cover": "Cover",
      "excerpt": "Here you can complete all the necessary information to create a Review. Always make sure that the cover photo you upload have a good resolution and is correct. Remember to read y check the information.",
      "language": "Language",
      "rating": "Rating",
      "review_date": "Review date",
      "save_review": "Save review",
      "tilte1": "Create Review",
      "title2": "Cover",
      "url": "URL"
    },
    "reviews": "Reviews",
    "sale_goals": "Sale Goals",
    "sales": "Sales",
    "save_home_collections": "Save Collection",
    "scopes": {
      "all": "All"
    },
    "search_status": {
      "current_filters": "Current filters:",
      "current_scope": "Scope:",
      "headline": "Search status:",
      "no_current_filters": "None"
    },
    "season": {
      "child_clp_price": "Childs in CLP",
      "child_usd_price": "Childs in dollars",
      "daily_quota": "Availability",
      "guardar_temporada": "Save season",
      "mkt_clp_price": "CLP price",
      "mkt_price": "Marketing price",
      "mkt_usd_price": "USD price",
      "name": "Name",
      "product": "Excursion",
      "single_supplement_clp_price": "CLP",
      "single_supplement_usd_price": "Dollars",
      "start_time": "Start time",
      "title_ages": "Ages",
      "title_daily_quota": "Daily quota",
      "title_days": "Days",
      "title_prices_adults": "Adults price",
      "title_prices_kids": "Kids price",
      "title_season": "1) Season information",
      "title_single_supplement": "Single Supplement",
      "valid_from": "Valid from",
      "valid_until": "Valid until"
    },
    "seasons": "Seasons",
    "settings": "Settings",
    "sidebars": {
      "filters": "Filters",
      "search_status": "Search Status"
    },
    "status_tag": {
      "no": "No",
      "unset": "No",
      "yes": "Yes"
    },
    "suppliers": "Suppliers",
    "tags": "Tags",
    "tourists": "Tourists",
    "unsupported_browser": {
      "headline": "Please note that ActiveAdmin no longer supports Internet Explorer versions 8 or less.",
      "recommendation": "We recommend that you <a href=\"http://browsehappy.com/\">upgrade your browser</a> to improve your experience.",
      "turn_off_compatibility_view": "If you are using IE 9 or later, make sure you <a href=\"https://support.microsoft.com/en-us/help/17471\">turn off \"Compatibility View\"</a>."
    },
    "users": "Users",
    "view": "View",
    "visits": "Visits",
    "zone": {
      "country": "Country",
      "description": "Description",
      "name": "Name",
      "sort_order": "Sort order",
      "state": "State"
    },
    "zones": "Zones"
  },
  "activerecord": {
    "attributes": {
      "active_admin/comment": {
        "author_type": "Author type",
        "body": "Body",
        "created_at": "Created",
        "namespace": "Namespace",
        "resource_type": "Resource type",
        "updated_at": "Updated"
      },
      "order": {
        "channel": "Channel:",
        "clp_charge": "CLP charge",
        "confirmed_at": "Purchase:",
        "customer_phone": "Phone:",
        "departure_at": "Departure:",
        "email": "Email:",
        "extra": "Extra:",
        "instructions": "Instructions:",
        "language": "Language:",
        "manual_currency": "Currency:",
        "manual_total": "Paid:",
        "partial_payment": "Payment:",
        "pax_manual": "Pax No:",
        "payment_method": "Method:",
        "product": "Product:",
        "product_name": "Product",
        "usd_charge": "USD charge",
        "username": "Client:"
      },
      "season": {
        "fri_check": "Fri",
        "mon_check": "Mon",
        "name": "Name",
        "product": "Excursion",
        "sat_check": "Sat",
        "start_time": "Start time",
        "sun_check": "Sun",
        "thu_check": "Thu",
        "tue_check": "Tue",
        "valid_from": "Valid from",
        "valid_until": "Valid until",
        "wed_check": "Wed"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "active_admin/comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "season": "Season",
      "seasons": "Seasons",
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "activities": {
    "bikes": "Bikes",
    "birdwatching": "Birdwatching",
    "cruises": "Cruises",
    "flyfishing": "Fly flyfishing",
    "helicopter": "Helicopter",
    "horseback": "Horseback",
    "icehike": "Ice Hike",
    "kayak": "Kayak",
    "mountaineering": "Mountaineering",
    "multiactivity": "Multi Activity",
    "navigations": "Navigations",
    "photosafari": "Photo Safari",
    "rafting": "Rafting",
    "ski": "Ski",
    "snowshoes": "Snowshoes",
    "trekking": "Trekking"
  },
  "add_row": "Add",
  "address": "Address",
  "agencies": {
    "account_number": "Account number",
    "account_type": "Account type",
    "actions": "Actions",
    "active": "Approved",
    "address": "Address",
    "agency": "Agency",
    "agency_bank": "Add bank account",
    "agency_info": "Information",
    "agency_information": "Agency information",
    "agency_type": "Agency Type",
    "apply_changes": "Apply changes",
    "bank_name": "Bank name",
    "change_availability": "Manage your dates",
    "commercial_information": {
      "account_type": "Account type",
      "bank_account": "Bank account",
      "bank_name": "Bank name",
      "commercial_name": "Commercial name",
      "id_rut": "ID / Rut",
      "name": "Name",
      "self": "Commercial information"
    },
    "country": "Country",
    "create_activity": "Create activity",
    "create_agency": "Create new agency",
    "create_excursion": "Create Excursion",
    "create_product": "Create product",
    "created_at": "Created at",
    "date_range": "Modify according to date range",
    "duration": "Duration",
    "edit_excerpt": "All the information requested to create the supplier profile is of vital importance to establish the commercial link with Outdoor Index. Please enter up to date information.",
    "english": "Traducir al ingles →",
    "excursions": "Excursions",
    "for_booking": "for booking",
    "holder_email": "Holder Email",
    "holder_id": "Holder ID",
    "holder_name": "Holder Name",
    "index_excerpt": "Here is the list of your products. You can create, edit and manage the dates. Each product created will remain in the \"Pending\" state until it is reviewed by the Outdoor Index team.",
    "list_title": "A list of all your dates",
    "manage_dates": "Dates",
    "manage_dates_excerpt": "Here you have a calendar to add all your products. You can create, edit and manage the dates. Each product created will remain in the \"Pending\" state until it is reviewed by the Outdoor Index team.",
    "max_words": "150 words",
    "name": "Name",
    "new_excursion_excerpt": "Here you can complete all the necessary information to create a product. Always make sure that the photos you upload have a good resolution and are attractive to the tourist. Remember to give clear and reliable information.",
    "no_dates": "No dates yet, please click on the calendar to create one.",
    "numbers": "Numbers",
    "owner": "Owner",
    "pending_changes": "Pending",
    "person_in_charge": "person in charge",
    "products": "Products",
    "products_to_add": "Add products",
    "products_to_remove": "Remove products",
    "review": "Review",
    "rooms_to_add": "Rooms to add",
    "rooms_to_remove": "Rooms to remove",
    "set_price": "Set price",
    "spanish": "Translate to spanish →",
    "state": "State",
    "supplier_info": "Information",
    "supplier_information": "Supplier information",
    "supplier_review": "Supplier review ",
    "update_info": "Save profile",
    "website_url": "Website URL",
    "zone": "Zone"
  },
  "alert": {
    "confirm_to": "You must confirm that you are not a robot.",
    "couldn't_save": "Couldn't save, please try again later.",
    "couldn't_send_review": "Couldn't send review",
    "error": "The password must have at least 8 characters, one letter, and one number.",
    "invalid_action": "Invalid action: amount can't be lower than 0",
    "invalid_changes": "Invalid changes",
    "invalid_language": "Invalid language",
    "no_products_in_your_order": "No products in your order",
    "not_enough_availability": "Not enough availability for that date range. Check other accommodations or book fewer units.",
    "please_sign_the_waiver": "Please sign the waiver with your name.",
    "review_already_submitted": "Review already submitted",
    "signature_and_tourist": "Signature and tourist name must be identical.",
    "something_went_wrong": "Something went wrong. Please try again or contact us.",
    "you_must_agree_to": "You must agree to our terms and conditions"
  },
  "applicants": {
    "additional_info": "4) Additional information",
    "applicants_number": "2) How many people will participate in the Excursion?",
    "applicants_phone": "1) Leave us your contact information",
    "dates_sub": "3) In which days do you wanna make this Excursion?",
    "email": "Email",
    "lastname": "Lastname",
    "name": "Name",
    "phone_placeholder": "Phone"
  },
  "back": "Back",
  "back_to": "Back to",
  "banner": {
    "do_you_need_travel_insurance": "Do you need travel insurance?",
    "get_a_quote": "Get a Quote",
    "in_partnership_with_world": "Outdoor Index in partnership with World Nomads give you the best insurance available for Chilean Patagonia."
  },
  "blog": {
    "travel_nature_growth": "Travel, nature & growth",
    "we_connect_you_with": "We connect you with leading content creators, from the world and multiple disciplines, to inform and nurture you about nature, travel and growth, we read your comments in rrss!"
  },
  "categories": {
    "general": "general",
    "general_category_search": "General %{category_name} search",
    "general_zone_search": "Search suggestions in %{zone_name}",
    "other_excursions_to_do": "Other experiences to do in %{zone_name}",
    "recommended_for_you": "Experiences to do in %{zone_name}",
    "search": "search"
  },
  "category": "Category",
  "charges": "Charges:",
  "chat": {
    "begin": "Begin",
    "create_message": "Send message",
    "enter_email": "Enter your email...",
    "enter_message": "Enter a message...",
    "enter_your_name": "Enter your name...",
    "hi": "Hello",
    "messages": "Messages",
    "new-message": "New message",
    "our_chat_service": "Our chat service is available Monday through Friday from 10 am to 6 pm (UTC / GMT -3). If you write to us within these hours, we will respond you in a few minutes.",
    "user": "User"
  },
  "chat_body": "Body",
  "chat_btn": "Send Message",
  "chat_email": "Email",
  "chat_name": "Name",
  "chat_phone": "Phone",
  "chat_subject": "Subject",
  "chat_subtitle": "We usually respond in a few hours",
  "chat_title": "How can we help?",
  "checkout": {
    "100_p": "100%",
    "100_per": "Paid 100%",
    "100_pers": "Paid 100%",
    "30_p": "30%",
    "30_per": "Paid 30%",
    "30_pers": "Paid 30%",
    "accept": "Yes, accept",
    "after_this_step": "After this step, Outdoor Index will contact all providers.",
    "all_transactions_have_an_extra": "You can pay using your debit or credit card in the installments that you agree with your bank. Outdoor Index will receive the full amount of the sale.",
    "cancel": "Cancel",
    "choose_payment": "Choose the payment you desire",
    "complete_payment": "Pay in full",
    "confirm_checkout_excerpt": "Before proceeding please check everything is correct, once you accept this order you will not be able to change it.",
    "confirm_checkout_excerpt_2": "(You can still create a new order.)",
    "continue_shopping": "Continue Shopping",
    "disclaimer": "About your reservation",
    "do_checkout": "Checkout",
    "everything_looks_fine": "Everything looks fine",
    "get_a_refund_if": "Get a refund if you cancel within 24 hours of purchase.",
    "i_hereby_accept": "I accept the",
    "i_need_to_change_something": "I need to change something",
    "important!": "Important",
    "next_departure": "Next departure: Oct/26/2018",
    "not_available": "Not available",
    "only_for_reservation": "Reserve",
    "pay_now": "Pay now",
    "payment_excerpt": "Before buying, make sure that all the details of your products are correct. Check the number of reservations and their costs. Remember to read our cancellation policy.",
    "please_confirm_your_details": "Please confirm your details",
    "providers_confirm_availability": "When all providers confirm your order, you will be contacted.",
    "ready": "Ready",
    "review": "Review",
    "some_products_deleted": "Some products are no longer available",
    "your_details_confirmation_and_payment": "Detail of your products and payment",
    "your_itinerary": "Your itinerary in detail"
  },
  "checkout_bar": {
    "assign_tourists": "Assign",
    "checkout": "Payment",
    "itinerary": "Itinerary",
    "tourists": "Group",
    "voucher": "Voucher"
  },
  "child_guest": "Child",
  "child_guests": "Childs",
  "clp": "CLP",
  "comments": {
    "body": "Write your comment here...",
    "comments": "Comments",
    "post_a_comment": "Post a comment!",
    "replies": "Replies",
    "reply": "Reply",
    "submit": "Submit"
  },
  "continue": "Continue",
  "coupon": "Coupon",
  "covid": {
    "are_we": "¿ARE WE GETTING RESERVATIONS?",
    "flights": "FLIGHTS",
    "for_more": "FOR MORE EXPERT INFORMATION",
    "for_more_expert": "For more expert info about COVID-19 and local protocols for security on your next trip, we recommend to visit the resources given by the Chilean government on the next links: <strong><a href=\"https://www.gob.cl/coronavirus/\">www.gob.cl/coronavirus</a></strong> - <strong><a href=\"https://www.sernatur.cl\">www.sernatur.cl</a></strong>",
    "for_the_latest": "For the latest info regarding your flights, we recommend to contact your airline as soon as possible. Some companies have canceled and/or established restrictions, protocols and process that you should be knowing of.",
    "hotels": "HOTELS",
    "if_for_some": "If for some COVID-19 related reason it is necessary to reschedule your trip, you will be able to have a 100% credit of the money already paid for a trip until April 2024, less any hotel fee or commission from some operator impossible to recover. We going to be persistent on trying to recover as much money as possible having in count the conditions.</br></br>For more details related to Outdoor Index reservation policies during times of COVI-19 and for the future, you can continue reading our <strong><a href=\"https://www.outdoorindex.cl/terms\">“Terms & Conditions section”</a></strong> on our site.",
    "in_case_of_a": "In case of a muli-day experience or a program, we want to tell you that every lodging has rigorously sanitized and it follows with protocols actively. Governmental organisms have also collaborated with trainings and inspections to assure an appropriate management.",
    "in_case_of_your": "In case of your product counts with transport, as in the case of lodging, we have follow the protocols required by the government specialized agencies, including the amount of people bey vehicle. Gel sanitizer will be on reach, and masks will be used during traveling.",
    "keep_your": "KEEP YOUR SELF INFORMED",
    "make_your_reservation": "MAKE YOUR RESERVATION WITHOUT UNCERTAINTIES",
    "on_destiny": "ON DESTINY",
    "on_reaching_destiny": "On reaching destiny and always will be a straight local phone with someone in charge to contact Outdoor Index to let us help you on any issue. The referred of the company that receives you will follow every local protocol signed by Outdoor Index.",
    "our_here_described": "Our here described policies regarding COVID-19 are subject to changed according recommendations and requirements of the local government, the Health  ministry, and any government entity managing tourism related subjects. We do try to be leaders on this matter, on the local ecosystem we are constantly monitoring the situations and any changes on the areas our operators work.",
    "our_main_policy": "Our main policy on these days is flexibility, so we want you to be able to cancel or re-schedule your trip close to the date it necessary. We want you to be relaxed regarding this subject. We´ve been working on tourism for several years always producing experiences in Patagonia and south Chile, we personally know the operators and is our priority to be with you from your very first visit to our site till you are back at home after your trip helping us to do our job better giving us your feedback on our post – trip survey, this will help the operators and the hole management of the ecosystem to grow better and with more sustainable tactics. </br></br>If you are thinking to travel on 2022, is not to early to start planning. In fact, it is more important than ever because of all the people rescheduling their trips and the limited spaces that some of our programs have.",
    "read_about": "Read about our response to <b>COVID-19</b>",
    "sub_text": "We know there´s a lot of people like us willing to come back to nature whenever its possible. The lock down and been limited from what we enjoy the most makes you dream of your next adventure. We are here to tell you that in Outdoor Index we are ready for you. We have managed and implemented our own protocols and verified the actions of each of our operators to keep all the stake holders involved; travelers, guides, local communities, every one safe. Our protocols are available and we are going to inform you directly on the moment of your trip. Constant updates will be  given whenever is pertinent. All´s going to be ready settled up for the right moment to give you the best welcome on your next adventure.",
    "title": "Info Covid",
    "to_be_calm": "To give you peace of mind during these difficult times, we have aligned our cancellation policies with those of our suppliers. To cancel your trip and receive 100% of your money back, you will have to make the cancellation 45 days before the start of the tour (previous medical certificate or proof that proves the cancellation situation due to Covid-19). Otherwise, Outdoor Index can offer the following alternatives: - Reschedule the date of the reservation for a stay until April 2024. - Transfer the reservation to another name.",
    "vehicles": "VEHICLES",
    "we_sure_do": "WE SURE DO. Check here our excursions or click any of our recommended options. If you want ideas check our <strong><a href=\"https://www.outdoorindex.cl/blog\">blog</a></strong> for some inspirations. Contact us for a local expert to  help you on your preparation, you can write to us to <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>",
    "what_happens_if": "WHAT HAPPENS IF I WANT TO CANCEL MY TRIP?",
    "what_if": "WHAT HAPPENS IF MY TRIP IS RESCHEDULED DUE TO COVID-19 MOBILITY RESTRICTIONS?"
  },
  "create_an_account": "Create your account",
  "currency": {
    "displaying_prices_in": "Currency exchange done!"
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "daterangepicker": "MM/DD/YYYY",
      "default": "%m/%d/%Y",
      "long": "%B %d, %Y",
      "moment": "%Y-%m-%d",
      "short": "%a, %b %e"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "days": "days",
  "delete": "Delete",
  "departure": "Departure",
  "devise": {
    "already_have_an_account?": "You have an account?",
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "didn't_receive_confirmation_instructions?": "Didn't receive confirmation instructions?",
    "didn't_receive_unlock_instructions?": "Didn't receive unlock instructions?",
    "don't_have_an_account?": "Don't have an account?",
    "enter_email_associated": "Enter the email associated with your account and we'll email you a link to reset your password.",
    "facebook_sign_in": "Connect with Facebook",
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "forgot_your_password?": "Forgot your password?",
    "google_sign_in": "Connect with Google",
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "edit": "Edit",
  "email": "Email",
  "error": {
    "go": "Go to homepage",
    "improvements": "If you see this message, it's because you may have typed the web address incorrectly or because we are updating the site with new features and improvements. Please wait a few minutes and reload the page again. Thank you!",
    "opps": "You may have mistyped the address or the page may have moved.",
    "sorry": "Sorry, this page doesn't exist.",
    "went_wrong": "We're sorry, but something went wrong."
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "in_between": "must be in between %{min} and %{max}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "must_contain_lower": " must contain at least 1 lowercase ",
      "must_contain_number": " must contain at least one number",
      "must_contain_special": " must contain special character",
      "must_contain_upper": " must contain at least 1 uppercase ",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "spoofed_media_type": "has contents that are not what they are reported to be",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    }
  },
  "excursions": {
    "add_extras": "Add extras",
    "add_images_(minimum_3)": "Add images (3 min)",
    "best_excursions_for_the_next_season": "Recommended for this season",
    "body": "Our itinerary",
    "body_old": "Our Old itinerary",
    "category": "Category",
    "clp_price": "Price (in pesos)",
    "create_excursions": "Create excursions",
    "days": {
      "one": "Day",
      "other": "Days",
      "zero": "Day"
    },
    "description": "Overview",
    "details": "Details",
    "disclaimer": "Disclaimer",
    "duration": "Duration",
    "duration_days": "Days",
    "duration_hours": "Hours",
    "edit_excerpt": "All the information requested to create the supplier profile is vital importance to establish the commercial link with Outdoor Index. Please enter the most reliable information possible.",
    "extra_description": "Activity",
    "faq": {
      "body": "Body",
      "sub_title": "Subtitle",
      "title": "Title"
    },
    "faqs": "FAQs",
    "full_day": "Full day",
    "half_day": "Half day",
    "hours": {
      "one": "Hour",
      "other": "Hours",
      "zero": "Hour"
    },
    "images": "Images",
    "included": "Whats included?",
    "itinerary": {
      "body": "Body",
      "sub_title": "Subtitle",
      "title": "Title"
    },
    "less": "Less",
    "location": "Place",
    "locations": "Location",
    "macro_zone": "Macro Zone",
    "meeting_address": "Address",
    "meeting_instruction": "Starting place",
    "meta_description": "Meta description",
    "meta_title": "Meta title",
    "minimum_reservations": "N° guests",
    "mkt_clp_price": "Base price clp",
    "mkt_usd_price": "Base price usd",
    "more": "More",
    "multiple_days": "Multiple days",
    "other_excursions_from_this_tour_company": "Other experiences from this supplier",
    "our_itinerary": "Itinerary",
    "policies": "Policies",
    "price": "Price (in dollars)",
    "price_per_reservations": "Price per reservations",
    "prices": "Prices",
    "profiles": "Trip style",
    "save_excursion": "Save excursion",
    "time": "Time",
    "title": "Title",
    "usd_price": "Price (in dollars)",
    "useful_information": "Useful information",
    "vacancies": "Vacancies",
    "what's_included?": "Included",
    "what's_not_included?": "Not included",
    "what_we'll_do": "Overview",
    "zone": "Zone"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "footer": {
    "about_us": "About us",
    "all_rights_reserved": "All rights reserved. No parts of this site may be reproduced without permission.",
    "clp": "Chilean pesos (CLP)",
    "contact_us": "Contact us",
    "covid": "Covid",
    "edit_endorsement": "Outdoor Index is based in Puerto Natales (Southern Chilean Patagonia) and has the support of more than 40 providers.",
    "endorsement": {
      "by_entering_your": "Before, during, and after your experience to solve your inquiries and help plan your next adventure.",
      "instant_booking": "Certified guides 100% available for your assistance",
      "payment_protection": "Pay only 30% now. The rest later",
      "support_local_comunity": "Support local, encourage sustainability",
      "we_comply_with_all": "Make your planning lighter without excessive payments, pay only the 30% now to confirm your reservation, pay the rest closer to date.",
      "when_you_buy": "When buying at Outdoor Index you encourage 35+ local providers on quality & sustainable management."
    },
    "english": "English",
    "english_corto": "ENG",
    "lang-currency": "Language & Currency",
    "privacy_&_cookies": "Privacy",
    "secure": "All bookings processed through this website are protected by our key partners.",
    "spanish": "Español",
    "spanish_corto": "ESP",
    "statement": "Read our mission statement",
    "survey": "Survey",
    "terms_&_conditions": "Terms",
    "titles": {
      "activities": "Popular Activities",
      "book": "Book with Confidence",
      "destinations": "Popular Destination"
    },
    "usd": "USA Dollars (USD)"
  },
  "free": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "description": "Description",
    "important_information": "Good to know",
    "meeting_address": "Address",
    "meeting_point": "How to arrive",
    "more": "More...",
    "save_activity": "Save activity",
    "thumbnail_description": "Thumbnail description",
    "title": "Title",
    "useful_information": "Useful information",
    "what's_included?": "What's included?",
    "zone": "Zone"
  },
  "from": "From ",
  "go_to_home_page": "Go to homepage",
  "guest": "Guest",
  "guests": "Guests",
  "guidebook": {
    "download_our_guide_book": "Download our guide book and start your trip with Outdoor Index",
    "find_out_everything": "Find out everything about the Southern Chilean Patagonia."
  },
  "header": {
    "agency_panel": "Agency panel",
    "ask_us_a_question": "Contact us",
    "blog": "Blog",
    "config": "Config",
    "help": "FAQ",
    "itinerary": "Itinerary",
    "join_our_newsletter": "Join our newsletter",
    "log_in": "Log in",
    "orders": "Vouchers",
    "partner": "Partner",
    "sign_out": "Log out",
    "sign_up": "Sign up",
    "supplier_panel": "Supplier panel"
  },
  "header_cat": {
    "accommodation": "Accommodation",
    "excursions": "Excursions",
    "other": "Other",
    "recommendations": "Suggestions"
  },
  "help": "Frequently asked questions",
  "helpers": {
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}",
          "zero": "No %{entry_name} found"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "home": {
    "adventure_excursions": "Put more of yourself",
    "adventures_by_region": "Adventures by region",
    "download": "Download",
    "excursions": "Excursions",
    "explore_your_interests": "Explore your interests",
    "family": "Groups",
    "hard_adventurous": "Active",
    "lakes_and_rivers": "Lakes & rivers District",
    "luxury": "Sofisticate",
    "luxury_experience": "Live exclusive, just for you",
    "north_patagonia": "North Patagonia",
    "products": "products",
    "promotions": "Discounts & offers",
    "scientific": "Discovery",
    "scientific_excursions": "In deep touch with nature",
    "site_title": "Outdoor Index: Book adventure tours in Patagonia and Chile",
    "soft_adventurous": "Transformative",
    "south_patagonia": "South Patagonia",
    "suggestions": "Suggestions",
    "the_best_excursions": "Best excursions for this season",
    "things_to_do_in": "Suggestions in"
  },
  "home_collections": {
    "carousel_activities": "Adventures by activities",
    "carousel_region": "Adventures by region",
    "carousel_time_preference": "Adventures by time preference",
    "find_your_experience": "Find your experience",
    "home_reviews": "350+ people travel with Outdoor Index every year",
    "sub-landing": "Discover the best tourist options in Chile with Outdoor Index. Compare and explore a wide variety of programs from Atacama to Patagonia. Book today to live unique experiences!"
  },
  "hotels": {
    "double": "Double",
    "double_twins": "Double twin",
    "edit_your_booking": "Edit your booking",
    "room_reserved": "room reserved",
    "twin": "Twin"
  },
  "hours": "hours",
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x000055c87e9d3220@/var/www/html/viewnia/current/vendor/bundle/ruby/2.6.0/gems/rails-i18n-5.1.3/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "image_not_found": "Image not found",
  "in": "in",
  "key": "Ex: Elevation",
  "language": "Language",
  "last_name": "Last name",
  "location": "Location",
  "log_in": "Log In",
  "mail": {
    "leave_your_review": "%{user_name}, leave a review of your experience",
    "voucher": {
      "new_sold": "New sale %{reference_code}",
      "your_order_is_ready": "Thank you for your purchase, your order number is %{reference_code}",
      "your_sold_product": "Hi %{user_provider}, we have sold one of your experience"
    },
    "welcome_user_name": "%{user_name}, Welcome to Outdoor Index"
  },
  "meesage_captcha": "You must check the captcha",
  "meesage_sended": "Meesage Sended",
  "menu_welcome": "Welcome, %{user_name}",
  "modal": {
    "add": "Add",
    "add_reserves": "Number of guests",
    "add_rooms_for": "Add rooms for your",
    "add_to_itinerary": "Select",
    "check_in": "Check in",
    "check_out": "Check out",
    "child_guests": "Childs",
    "child_text": "Up to 12 years old",
    "date": "Date",
    "departure_time": "Departure:",
    "describe_your_experience": "Describe overall experience",
    "doubles": "Doubles",
    "doubles_twin": "2 twins",
    "ends": "Ends",
    "first_section": "Departure",
    "guest": "guests",
    "guest_text": "Ages 13 and older",
    "guests": "Guests",
    "is_not_resident": "Not resident in Chile",
    "is_resident": "Residents in Chile",
    "leave_your_review": "Leave your review",
    "load_earlier_dates": "Load earlier dates ...",
    "load_later_dates": "Load later dates ...",
    "next": "Next",
    "no_daily_quota": "There are not enough seats for this day",
    "per_person": " p/p",
    "second_section": "Passengers",
    "select": "Select",
    "select_date": "Select Date",
    "select_date_text": "Select an available date on the calendar and follow the steps to generate your reservation.",
    "spot_left": "Spot left",
    "start": "Start",
    "twins": "Twin",
    "wrong_name": "Wrong name or password"
  },
  "modal_manage_date": {
    "available_ammount": "Available amount",
    "cancel_date": "Cancel date",
    "date": "Date",
    "discount": "Add a discount",
    "manage_date": "Manage date",
    "month_form": {
      "accept": "Accept",
      "back": "Back",
      "confirm": "Save",
      "continue": "Continue",
      "delete_month": "Delete Month",
      "delete_month_disclaimer": "You are about to delete all departures for the following month, this action is <strong>irreversible</strong>.",
      "disclaimer_1": "You are about to edit <strong>an entire month</strong>, this action is <strong>hard to reverse</strong>.",
      "disclaimer_2": "Please double check your current calendar before you proceed, in order to avoid duplicates or other problems.",
      "edit_month": "Edit month",
      "final_notice_1": "Add a departure date for excursion <strong id='fn_title'></strong>, every <strong id='fn_week_days'></strong>  of <strong id='fn_month'></strong>.",
      "final_notice_2": "Each departure will start at <strong><span id='fn_starting_time_4i'></span>:<span id='fn_starting_time_5i'></span></strong>, have <strong id='fn_vacancies'></strong> and discount <strong id='fn_discount'></strong>.",
      "final_notice_header": "Please read carefully:",
      "month": "Month",
      "populate_month": "Populate Month",
      "vacancies": "vacantes",
      "week_days": "Week days"
    },
    "price": "Price",
    "save": "Save",
    "starting_time": "Starting time",
    "vacancies": "Vacancies"
  },
  "more": {
    "a_selection_of_the_best": "A selection made by Outdoor Index",
    "all_adventure_products": "All active experiences",
    "all_family_products": "All familiars - groups experiences",
    "all_lakes_and_rivers_products": "All Lakes & Rivers District products",
    "all_luxury_products": "All sofisticate experiences",
    "all_north_patagonia_products": "All North Patagonia products",
    "all_ota_products": "All OTA experiences",
    "all_promotions_products": "All discounts & offers experiences",
    "all_recommendations_in_puerto_natales": "All experiences in Puerto Natales",
    "all_recommendations_in_punta_arenas": "All experiences in Punta Arenas",
    "all_recommendations_in_zone": "All suggestions in %{zone_name}",
    "all_scientific_products": "All discovery experiences",
    "all_south_patagonia_products": "All South Patagonia products",
    "all_standard_products": "All transformative experiences",
    "other_activities_that": "Other activities that complement your experience",
    "the_best_products": "The best experiences in Outdoor Index"
  },
  "name": "Name",
  "newsletter": {
    "email_address": "Email Address",
    "sign_up_at": "Patagonia´s marketplace for adventure travel",
    "sign_up_now": "Sign up",
    "subscribe": "Subscribe",
    "subscribe_to_our": "Subscribe to our Newsletter",
    "view_preferences": "Preferences",
    "we_are_travel_and_growth": "We are nature based travel & growth, connecting you with local operators, informing and protecting our nature.",
    "we_value_your_privacy": "Sustainable, personalized and full of adventures for you to enjoy"
  },
  "notice": {
    "account_updated": "Account updated!",
    "availability_updated": "Availability updated",
    "bookings_made_for": "Your reservation was made successfully!",
    "changes_submitted": "Changes submitted!",
    "date_no_longer": "Date no longer available",
    "diabled": "disabled",
    "enabled": "enabled",
    "have_excursions": "The agency has excursions, assign them to another before deleting it",
    "invalid_request": "Invalid request. Please try again or contact us.",
    "language_updated": "Idioma actualizado!",
    "new_product_created": "New product created, waiting for approval",
    "product_was_disabled": "Product was disabled",
    "product_was_enabled": "Product was enabled",
    "profile_picture_updated": "Profile picture updated!",
    "reserves_added_for": " reserves added for ",
    "review_sent": "Review Sent!",
    "session_expired": "Session expired, please log in.",
    "settings_updated": "Settings updated!",
    "tourist_information_saved": "Your information has been saved",
    "updated_information": "Updated information",
    "was": "was",
    "your_account_has": "Your account has no agencies, please contact us"
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ondemand": {
    "explanation": "Exclusive tour! Contact our field specialist.",
    "label": "Private"
  },
  "optional": "Optional",
  "or": "or",
  "order": {
    "already_paid": "Order already paid",
    "cancel_order": "Cancel",
    "confirm_payment": "Confirm",
    "confirmed": "Confirmed",
    "edit": "Edit",
    "email_footer": "here is a reminder of your excursion. If you have any questions, feel free to contact us at info@outdoorindex.cl or +56991337099.",
    "email_title": "Excursion Reminder",
    "mail__hi": "Hi!",
    "mail_subject": "Outdoor Index Order N#",
    "mail_thank": "thanks for your purchase in Outdoor Index",
    "order_t": "Order",
    "promo": "Visit www.outdoorindex.cl for more excursions and programs.",
    "recover_order": "Recover",
    "see_purchase": "Review your purchases!",
    "send_reminder": "Reminder"
  },
  "partners": {
    "add_your_bank": "Add your bank information to establish the commercial link with Outdoor Index.",
    "banners": "Banners",
    "commercial_information": "Commercial information",
    "communicate_with_viewnia": "Communicate with Us",
    "copy_code": "Copy Code",
    "copy_the_link_you": "Copy the link you want to use from the Outdoor Index website and paste it into the box below. Your custom affiliate link will be generated ready for you to add to your website, email or social pages.",
    "copy_url": "Copy URL",
    "embed_this_code": "Embed this code on your website:",
    "generate": "Generate URL",
    "here_your_custom": "2) Here's your custom link.",
    "horizon": "2) Horizontal 90 x 728 px",
    "insert_link": "Insert link...",
    "numbers": "Numbers",
    "our_tutorial_video": "Our step by step tutorial video will help you to integrate banners or text links into your website or emails. Take a look and find out how.",
    "paste_the_viewnia": "1) Paste the Outdoor Index link here (including https://).",
    "play_video": "Play Video",
    "raised_money": "Raised Money",
    "references": "References",
    "request_money_transfer": "Request money transfer",
    "select_the_banner": "Select the Banner of your preference. The one that fits best with the design of your website.",
    "square": "1) Square 300 x 300 px",
    "text_links": "Text Links",
    "these_are_all": "These are all the people who have entered to Outdoor Index from your Banners or Text Links.",
    "these_are_the_numbers": "These are the numbers generated by your references from your website, social media or emails.",
    "this_is_a_detail": "This is a detail of the money you have earned with your references to Outdoor Index.",
    "vertical": "3) Vertical 300 x 600 px",
    "watch_our_step": "Watch our guide on how to use banners or text links",
    "wellcome_partners": "Wellcome Partner",
    "wellcome_partners_text": "In this section you will find several tools to start promoting outdoor activities in Patagonia. This will allow you to complement your own business offering that extra service that your audience will thank and remember forever.",
    "you_are_not": "You are not a partner"
  },
  "password": "Password (min 8 characters, letter, number)",
  "password_confirmation": "Password confirm",
  "paypal": {
    "description": "Outdoor Index: Paying order %{reference_code}"
  },
  "payu": {
    "description": "Outdoor Index: Paying order %{reference_code}"
  },
  "pdf": {
    "about_your_host": "ABOUT YOUR HOST",
    "according_to_the_reservation": "According to the reservation request by you, we confirm the following information:",
    "activity": "Activity",
    "address": "Address:",
    "allergies": "Allergies:",
    "applied": "APPLIED",
    "at_all_times": "at all times of the trip. Present it when",
    "child_guest": "Childs",
    "child_guest_price": "Child price",
    "childen_pax": "Childs",
    "client": "Client",
    "congratulations_agency_name": "Congratulations %{agency_name}",
    "congratulations_user_name": "Congratulations %{user_name}",
    "contact_us": "Contact us",
    "date_of_birth": "Date of birth:",
    "dear_user_name": "Dear %{user_name}",
    "departure": "Departure",
    "description": "Description",
    "document_number": "Document number:",
    "email": "Email",
    "emergency_contacts": "Emergency contacts",
    "emergency_number": "Emergency number:",
    "excursion": "ACTIVITY %{number}",
    "food_restrictions": "Food restrictions:",
    "from": "Service start date",
    "guest": "Guests",
    "guest_price": "Guest price",
    "guests": "Guests",
    "here_we_attach": "Here we attach all the information of your trip. In this voucher you will see the detail of your products, the contact of the suppliers, the signed waiver, as well as the receipt of your paid product (Paypal or PayU). Feel free to contact us if you have any questions.",
    "in_this_section_appear": "In this section you have the detail of the payment received by Outdoor Index. Remember to print this voucher and take it with you always. Thank you very much for choosing Outdoor Index",
    "initial_payment": "Initial payment",
    "medical_conditions": "Medical conditions:",
    "name": "Person in charge:",
    "next_payment": "NEXT PAYMENT (70%)",
    "no_resident": "No resident",
    "order_date": "Order date",
    "origin": "Origin:",
    "pax": "Pax",
    "payment_complete": "Payment complete",
    "payment_date": "Payment date",
    "payment_details": "PAYMENT DETAILS",
    "payment_method": "Payment method",
    "payment_type": "Payment type",
    "payments_detail": "Payments detail",
    "phone": "Phone",
    "print_this_voucher": "Print this voucher and take it with you",
    "problem_with_order": "Problems with your order?",
    "product_detail": "Product detail",
    "purchase_date": "Purchase date",
    "reference_code": "Reference code",
    "reservation_number": "Reservation number:",
    "resident": "Resident",
    "service_confirmation": "Service Confirmation",
    "starting_date": "Starting date",
    "starting_point": "Place",
    "starting_time": "Starting time",
    "tax": "Tax",
    "to": "Service end date",
    "total_order": "Order total:",
    "total_paid": "Total paid",
    "tourists": "Tourists",
    "unit_price": "Unit price",
    "unit_price_child": "Child unit price",
    "we_have_received": "We have received a new reservation request for your products. Below is the detail of this and the information of your passengers. From this moment you can contact your passengers directly or if you prefer, instruct Outdoor Index to do so. It is always advisable to send an email informing that the reservation was made successfully. You will stand out as a good provider. Remember to update the reserve quotas in your own systems so there are no problems of overcrowding. Good job!",
    "you_check": "you check in.",
    "your_guests": "Your guests",
    "your_suppliers": "Your suppliers"
  },
  "pending": "Pending",
  "phone": "Phone",
  "price": "Price",
  "privacy": {
    "content": {
      "contests": "<strong>VII) CONTESTS, SURVEYS AND OPINIONS:</strong>",
      "cookies": "<strong>V) COOKIES:</strong>",
      "from_time_to_time": "From time to time, Outdoor Index may sponsor or host contests, raffles, promotions and surveys to improve the offering of Outdoor Index’s products and services. If information is collected with the purpose of said commercial practices, and this comes to include contact information of the eventual winners or participants, such will be registered for the purposes indicated in this Privacy Policy and will be treated with the same conditions as informed. Outdoor Index may publish on the website results, testimonies, images, comments and information derived from the answers of surveys, as a method of promoting real testimonies from experiences of Outdoor Index services, identifying you by full name, photograph, comment, and nationality. You accept and agree that all opinions that you send us will be unique and exclusive property of Outdoor Index, and hereby irrevocably concede all the property rights regarding the totality of your opinions and denounce any moral right that you could have about said opinions.",
      "google_analytics": "<strong>VI) GOOGLE ANALYTICS:</strong>",
      "if_you_have_questions": "If you have questions about this Privacy Policy, you may contact us in the “Contact Us” section or at the following email address: <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "links": "<strong>VIII) LINKS TO WEB PAGES:</strong>",
      "means_of_obtainment": "<strong>IV) MEANS OF OBTAINMENT:</strong>",
      "password": "<strong>II) PASSWORD, USER PROFILE AND SOCIAL MEDIA:</strong>",
      "possibility": "<strong>I) POSSIBILITY OF CONTACTING:</strong>",
      "purpose": "<strong>III) PURPOSE OF DATA AND SECURITY:</strong>",
      "the_personal_data": "The personal data that we collect from you, with the purposes written above, is compiled in a personal way when you provide it to us directly via our User Profile on our web platform (<a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a>), when you provide the data to participate in promotions or offers, or when you hire a product or service through us. Moreover, we make it known that when you access our web Platform, we receive relevant information to your cookies that gives us access to obtain information such as your type of browser and operating system, the internet pages that you have visited recently, the links you have recently followed, the IP address of your computer, and the website that closed before entering our internet portal.",
      "this_page_contains": "This page contains links with other pages that have their own privacy and confidentiality policies. Because of this, we recommend that you visit these other web pages and carefully revise their practices and confidentiality policies.",
      "viewnia_com_asks_you": "Outdoor Index asks you to choose a user identification and a secure password. Remember that the password chosen is of personal use and must be safely guarded. Do not disclose or give use of your password to a third party because you may put the control of your personal information at risk and it presents the opportunity for transactions to be finalized in your name without your authorization. However, if for some reason your password should be compromised, you must change it immediately and inform us of the occurrence. Moreover, remember that you may access, update and correct your User Profile information at any time. On the same note, as part of the functionality of the Web Site and to provide a more personalized experience, you can link your social media accounts to fill in the login information from your social media account through the Web Site and/ or Mobile Version. You declare that you have the right to reveal your login information to your Social Media network to Outdoor Index and/ or grant access to Outdoor Index to your account, without this implying a breach by Outdoor Index of any of the terms and conditions that govern the use of the social network and without Outdoor Index being obliged to pay any fee or to respect any limitation of use imposed by the external service providers of the Social Network. By the act of granting access to Outdoor Index to any Social Network, you understand that Outdoor Index will access, make available and store all content that you have provided and stored on your Social Network so that it be available on the Web Site and/ or the Application.  In this way, it is easier to personalize our web page according to your preferences, connect you with your friends to discuss destinations and analyze and upgrade our outdoor excursion and activity services.",
      "we_utilize_cookies": "We utilize cookies to make our website as attractive as possible and to permit the use of certain functions. Cookies are small text archives that are stored on your computer.  The majority of the cookies that we utilize apply only for one session and are eliminated from the browser of your hard drive after logging out.  However, other cookies remain permanently on the computer to allow us to recognize when you return to the site. You have the possibility to impede the storage of cookies through the appropriate selection of setting on your browser. Nonetheless, during your visit to Outdoor Index, it is possible that some sections of the platform do not function as anticipated or that they do not function at all.",
      "we_utilize_the_web_analysis": "We utilize the web analysis service (“Google Analytics”) of the company Google Inc. (“Google”). The obtained information in this case is transferred to a Google server in EE.UU., where it is stored. Google will use this information to evaluate the client’s use of the webpage and to create reports about the web activities of the Outdoor Index website. Given the case, this data will be transferred by Google to third parties, as required by law. The same is applied in the case of the disclosure to third parties as a result of the commissioning of the same Google. Unless the installation of cookies is prevented by modifying the browser settings, limitations of use on the website will be faced. Through the use of our website you agree, in principle, to the use of your information by Google in the way previously described and for the purposes mentioned above.",
      "when_you_accept_this": "When you accept this Privacy Policy, you expressively authorize Outdoor Index to process your information, that is, register it; transmit your personal history to the final service(s) providers to process your reservations and/ or purchase applications; to send them publicity communications, promotions or offers and to collect statistics. In its user quality, you will have the opportunity to provide Outdoor Index related information of your travel habits, email address for the delivery of voucher, credit card billing information, and other personal information of interest. The data that you provide to us helps us to serve your planning needs and travel safety. The “extreme” nature of some of the excursions and activities that are advertised on our web platform Outdoor Index, requires us to possess this data. We will contact you at your email address to send you related messages about your purchase orders and trip preparation."
    },
    "foot": {
      "elimination": "<strong>X) ELIMINATION AND REVOCAL OF CONSENT:</strong>",
      "if_you_request": "If you request, we will give you at any time and free of charge, information about which of your information is stored on Outdoor Index. You will be able to, whenever you wish, from your user profile, eliminate or correct your data. You may also retract your consent for the collection, use and provision of your data. For any questions or comments, you may contact us at <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "newsletter": "<strong>XI) Newsletter</strong>",
      "viewnia_com_has_decided": "Outdoor Index has decided to register by default to the newsletter, to all the users at the time of sign up. This in order to keep users informed of the offers, promotions and recommended products that this platform offers. In the event that a user would like to unsubscribe, they can modify their preferences in the user panel, within their session or directly in the unsubscribe option located at the bottom of the emails."
    },
    "head": {
      "cookie_preferences": "<strong>Update cookies preferences</strong>",
      "responsible_for_the_protection": "<strong>Responsible for the protection of personal data:</strong> Garals Turismo Austral Limitada located at Blanco Encalada 722, Puerto Natales, Region of Magallanes, Chile.",
      "title": "Privacy Statement",
      "viewnia_com_values_its": "Outdoor Index values its users and providers and is hereby committed to protect your confidentiality. In performance of said commitment, Outdoor Index has developed this privacy policy that describes the policies and practices of Outdoor Index in what is referred to as the collection and disclosure of personal information on its web platform."
    },
    "last": {
      "keep_in_mind_that": "Keep in mind that when you reveal information in a public area of this page (for example: comments about excursions, electronic photographs with hashtags, etc.) you are making this information available to other members, users of the page and to the general public; which remains outside of the range of control of Outdoor Index. Please, remember the previous and be cautious with the information that you decide to disclose.",
      "public_information": "<strong>IX) PUBLIC INFORMATION:</strong>"
    }
  },
  "products": "Products",
  "products_search": {
    "activities": "Activities",
    "activities_sub_label": "Choose your favorite activities",
    "all_adventures": "All Adventures",
    "clear": "Clear Selection",
    "date": "Datex",
    "date_sub_label": "When do you want to go?",
    "in": "in",
    "regions": "Regions",
    "regions_sub_label": "What region you want to visit?",
    "search": "Search",
    "subtext": "Browse through all the available tours and excursions. Craft your itinerary, make reservations, and savor the wonders of our nature.",
    "time": "Time",
    "time_sub_label": "Select your time preference!",
    "tours": "tours"
  },
  "products_show_photos": "Show (%{n}) photos",
  "profiles": "Profiles",
  "promotions": {
    "coupon_apply": "Apply",
    "coupon_code_already": "Coupon code has already been applied",
    "coupon_code_destroy_confirm": "Are you sure you want to delete it?",
    "coupon_code_destroy_error": "Could not remove code",
    "coupon_code_destroy_ok": "Code removed successfully",
    "coupon_code_error": "Coupon code does not exist",
    "coupon_code_max": "Maximum one coupon per order",
    "coupon_code_ok": "Coupon code applied success",
    "coupon_form_palceholder": "Discount code",
    "coupon_have_a_coupon": "Do you have a discount coupon?"
  },
  "puerto_natales": "Puerto Natales",
  "puerto_williams": "Puerto Williams",
  "punta_arenas": "Punta Arenas",
  "ransack": {
    "all": "all",
    "and": "and",
    "any": "any",
    "asc": "ascending",
    "attribute": "attribute",
    "combinator": "combinator",
    "condition": "condition",
    "desc": "descending",
    "or": "or",
    "predicate": "predicate",
    "predicates": {
      "blank": "is blank",
      "cont": "contains",
      "cont_all": "contains all",
      "cont_any": "contains any",
      "does_not_match": "doesn't match",
      "does_not_match_all": "doesn't match all",
      "does_not_match_any": "doesn't match any",
      "end": "ends with",
      "end_all": "ends with all",
      "end_any": "ends with any",
      "eq": "equals",
      "eq_all": "equals all",
      "eq_any": "equals any",
      "false": "is false",
      "gt": "greater than",
      "gt_all": "greater than all",
      "gt_any": "greater than any",
      "gteq": "greater than or equal to",
      "gteq_all": "greater than or equal to all",
      "gteq_any": "greater than or equal to any",
      "in": "in",
      "in_all": "in all",
      "in_any": "in any",
      "lt": "less than",
      "lt_all": "less than all",
      "lt_any": "less than any",
      "lteq": "less than or equal to",
      "lteq_all": "less than or equal to all",
      "lteq_any": "less than or equal to any",
      "matches": "matches",
      "matches_all": "matches all",
      "matches_any": "matches any",
      "not_cont": "doesn't contain",
      "not_cont_all": "doesn't contain all",
      "not_cont_any": "doesn't contain any",
      "not_end": "doesn't end with",
      "not_end_all": "doesn't end with all",
      "not_end_any": "doesn't end with any",
      "not_eq": "not equal to",
      "not_eq_all": "not equal to all",
      "not_eq_any": "not equal to any",
      "not_in": "not in",
      "not_in_all": "not in all",
      "not_in_any": "not in any",
      "not_null": "is not null",
      "not_start": "doesn't start with",
      "not_start_all": "doesn't start with all",
      "not_start_any": "doesn't start with any",
      "null": "is null",
      "present": "is present",
      "start": "starts with",
      "start_all": "starts with all",
      "start_any": "starts with any",
      "true": "is true"
    },
    "search": "search",
    "sort": "sort",
    "value": "value"
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "region": {
    "lakes_and_rivers_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "north_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "south_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc."
  },
  "registration": {
    "all_vouchers": "All vouchers",
    "change_my_password": "Change my password",
    "change_your_password": "Change your password",
    "confirm_new_password": "Confirm new password",
    "confirm_password": "Confirm password",
    "current_password": "Current password",
    "edit_user": "Edit user",
    "email": "Email",
    "interests": "Interests (Choose one or more)",
    "lastname": "Last name",
    "name": "Name",
    "new_password": "New password (min 8 characters, letter, number)",
    "notification": "Notification Settings",
    "profile_picture": "Profile picture",
    "profiles": "Profiles (Choose one or more)",
    "registration_excerpt": "Manage your profile the way you want. Enable or disable your preferences and define your interests. This way you will be able to customize the contents you see on Outdoor Index.",
    "save_settings": "Save",
    "update_account": "Update account",
    "vouchers": "Vouchers"
  },
  "remember_me": "Remember me",
  "rental": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "category": "Category",
    "description": "About the product",
    "meeting_address": "Address",
    "policies": "Policies",
    "price": "Price",
    "save_product": "Save product",
    "title": "Title",
    "vacancies": "Vacancies",
    "zone": "Zone"
  },
  "request": {
    "applicants": "Applicants",
    "comments": "Comments",
    "comments_placeholder": "Leave us your questions, doubts or anything you want to tell us before we contact you. We’ll reach you as soon as possible!",
    "contact_email": "Contact email",
    "contact_name": "Contact name",
    "contact_phone": "Contact phone",
    "email_footer": "This form has been sent to our executives. You will be contacted through the information you provide in the contact form. If there are any errors or observations in the form, please, send an email to info@outdoorindex.cl regarding the issue.",
    "email_title": "Excursion coordination application",
    "excursion": "Excursion",
    "from": "From",
    "greeting": "Hi! This information has been sent to your registered email with a copy to one of our agents. Someone will be contacting you soon.",
    "must_log_in": "You must log in before you can pay",
    "order_mail": {
      "disclaimer": "You'll be redirected to a payment window in Outdoor Index via secure connection.",
      "excursion": {
        "agency": "Local Operator",
        "title": "Excursion"
      },
      "footer": "Now you can go to the payments section through the button link.",
      "header": {
        "hi": "Hi! We have news about your excursion application!",
        "ready": "is available for payment.",
        "title": "Your application has been accepted by the Local operator. Please read the information bellow, and make sure everything is allright.",
        "your_order": "Your order N°"
      },
      "manual_order": {
        "observations": "Read the comments bellow from Outdoor Index agent!",
        "price": "Price",
        "price_clp": "Price CLP",
        "price_usd": "Price USD"
      },
      "pay": "Pay now",
      "request_details": "Your application details"
    },
    "phone": "Contact  phone",
    "product_required": "To request an order, you must login previously",
    "requester": "Requester",
    "singular": "Request",
    "submit": "Send application",
    "to": "To"
  },
  "request_form": {
    "contact_viewnia_subtitle": "Fill the form below to begin the process that will allow us to coordinate your excursion with our field specialist.",
    "title": "Request Excursion"
  },
  "reservations": {
    "(me)": "(me)",
    "a_list_of_all_your_reservations": "All your reservations",
    "actions": "Actions",
    "add_guest_details": "Add info pax",
    "assign_excerpt": "In this section you indicate which traveler is going to which excursion. That way we will have all the necessary information to provide you the best possible experience.",
    "assign_tourists": "3) Assign tourists",
    "assing_tourists": "Assign tourists",
    "cancel_date": "Cancel date",
    "cart_empty": "Your backpack is empty",
    "cart_subtitle": "List of all the product in your backpack",
    "cart_title": "Information of your trip",
    "confirmed_excerpt": "Thank You for Traveling with us!",
    "confirmed_excerpt_litle": "Check the voucher to see the details of your purchase.",
    "create_your_travel_group": "2) Travel group >",
    "departure_date": "Departure date",
    "details_of_purchase": "Detail of your purchase",
    "edit_your_reservation": "Edit your reservation",
    "go_to_checkout": "Continue",
    "itinerary_excerpt": "Here you can find a list of all your products. Make sure the information is correct before continuing to the next steps. Important: All times and dates are displayed in local time.",
    "list_of_products": "List of products",
    "many_few_tourists": "The tourists assigned does not match the number of reservations",
    "my_group": "My group",
    "new_checkout_excerpt": "Here you can find a listg of your products. Don’t forget to add the information of the guests in the Excursion in wich they participate. Before buying, check the number of reservations and their costs. Remember to read our Cancellation policy",
    "not_enough": "has not enough",
    "organize_your_itinerary": "1) Itinerary >",
    "pay_with": "Pay with",
    "people": "Pax:",
    "people_(including_you)": "People (Including you)",
    "price_chilean": "Total value residents in Chile:",
    "price_no_chilean": "Total value not residents in Chile:",
    "product_for": "Product for",
    "reservations_made": "#",
    "reserves": "Reserves",
    "see_details": "See details",
    "select_your_extras": "Select your extras:",
    "single_supplement": "Single supplement:",
    "team_member": "Team member",
    "too_many": "has too many",
    "total_price": "Total price",
    "tourists_assigned": "tourists assigned",
    "tourists_group": "In this section you have to add the information of all the people who will accompany you. It is very important that the attached information is as reliable as possible.",
    "update": "Update",
    "you_have_no_reservations": "You have no reservations"
  },
  "reset_password": "Restore password",
  "responsive": {
    "more": "More..."
  },
  "reviews": {
    "footer": "See on Trustpilot",
    "more": "More",
    "review": "Review",
    "reviewing_excursion": "Reviewing product",
    "thanks_for_submitting": "Thanks for submitting a review",
    "title": "Reviews"
  },
  "search": "Search",
  "searches": {
    "all_category_products": "All \"%{category_name}\" experiences",
    "all_category_products_no_result": "Sorry, no \"%{category_name}\" experiences for now",
    "all_products_in_zone": "All experiences in \"%{zone_name}\"",
    "all_products_in_zone_no_result": "Sorry, no experiences in \"%{zone_name}\"",
    "category_products_in_zone": "\"%{category_name}\" experiences in \"%{zone_name}\"",
    "category_products_in_zone_no_result": "Sorry, no \"%{category_name}\" experiences in \"%{zone_name}\"",
    "no_result_to": "Sorry, no result to \"%{category_name}\" in \"%{zone_name}\"",
    "please_select_category_or_zone": "Please select category or zone",
    "recommended_for_you": "Experiences to do in \"%{zone_name}\""
  },
  "select_multiple": "Select multiple",
  "select_one": "Select One",
  "select_two": "2 max",
  "send": "Send",
  "sidebar_single": {
    "contact_viewnia": "Contact Us",
    "disclaimer": "Secure your spot now by paying only <strong>30% of the total booking amount</strong>.",
    "from": "from",
    "if_you_are": "if you are new.",
    "log_in": "Log in",
    "no_dates_available": "No Dates Available",
    "or": "or",
    "price_for": "Per person",
    "select_dates": "Select date",
    "share": "Have questions about the excursion? Contact us here:",
    "sign_up": "Sign up",
    "to_make_a": "To make a reservation please "
  },
  "sign_up": "Sign Up",
  "signature": {
    "content": {
      "by_applying_my_electronic": "By applying my electronic signature to this agreement, I agree that my electronic signature is the legally binding equivalent of my handwritten signature on paper. I will not, at any future time, claim that my electronic signature is not legally binding or enforceable. By electronically signing and submitting this agreement, I 1) acknowledge that I have read and fully understand the terms of the agreement; 2) voluntarily agree to be bound by this agreement; and 3) certify that I am 18 years of age or older. My signature applies to all pages of this document."
    },
    "foot": {
      "apply_signature": "Apply now",
      "i_understand_that_i_will": "I understand that I will receive a Portable Document Format (PDF) version of this agreement after it is signed at the email address I have provided. To view the PDF document, I understand that I will need software that enables me to receive and access PDF files such as Adobe Reader software or other software capable of reading a PDF file. In order to print and retain a hard copy of this agreement, I understand that I will also need a printer connected to my computer. I understand that if I wish to sign a hard copy of this agreement instead of an electronic version, I must contact the party that requires my signature on this agreement directly.",
      "save": "Save",
      "yes_i_agree": "Yes, I agree."
    },
    "title": {
      "electronic_signature_authorization": "Electronic Signature Authorization:"
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "social_share_button": {
    "delicious": "Delicious",
    "douban": "Douban",
    "email": "Email",
    "facebook": "Facebook",
    "google_bookmark": "Google Bookmark",
    "google_plus": "Google+",
    "hacker_news": "Hacker News",
    "linkedin": "Linkedin",
    "odnoklassniki": "Odnoklassniki",
    "pinterest": "Pinterest",
    "qq": "Qzone",
    "reddit": "Reddit",
    "share_to": "Share to %{name}",
    "telegram": "Telegram",
    "tumblr": "Tumblr",
    "twitter": "Twitter",
    "vkontakte": "Vkontakte",
    "wechat": "WeChat",
    "wechat_footer": "Open your WeChat, click \"Discover\" button then click the \"Scan QR Code\" menu.",
    "weibo": "Sina Weibo",
    "whatsapp_app": "WhatsApp",
    "whatsapp_web": "WhatsApp",
    "xing": "Xing"
  },
  "soon": "Soon",
  "subtotal": "Subtotal:",
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "survey_page": {
    "description": "We would like to know your opinion about the service you received. Your feedback is very important to us.",
    "tilte": "Outdoor Index - After sales survey"
  },
  "terms": {
    "content": {
      "any_purchase_made": "90 days or more before the program's start date, a 100% refund will be issued. Between 89 and 70 days before the program's start date, only 50% of the amount paid will be refunded. Between 69 and 45 days before the program's start date, only 20% of the amount paid will be refunded. 44 days before the program's start date or less, no refunds will be given. In case of a no-show, no refunds will be issued.",
      "cancellation": "<strong>5) ANNULMENT OR CANCELLATION:</strong>",
      "example": "In case of cancellation:",
      "general_info": "<strong>1) GENERAL INFORMATION:</strong>",
      "in_the_case_of_annulment": "Reservations are non-transferable and nominative.",
      "methods": "<strong>3) PAYMENT METHODS:</strong>",
      "of_the_100%_paid": "Regarding the entry fees to Torres del Paine National Park, as they are purchased from an external entity that does not allow date or name modifications or cancellations, their cost will not be refunded under any circumstances.",
      "our_cancellation_system": "Our cancellation system is formed based on percentages between the day of purchase and the initial day of the reservation.",
      "payment": "<strong>2) PAYMENT & RESERVATION:</strong>",
      "purchase": "<strong>4) PURCHASE / RESERVATION PROCESS:</strong>",
      "purchase-one": "<strong>4.1) PAYMENT:</strong>",
      "the_payment_methods": "Outdoor Index uses two payment options validated internationally PayPal and WebPay. These will appear before your purchase request. When the consumer fills in data for their account or card and accepts the operation, then he/she authorizes the charge in the amount of the price and/or fee, the payment of the charge for our service and the charge for the use of the credit or debit card. If you make a payment in quotes, keep in mind: the charge for our service and the related tax on the use of the credit card is applied in the first quote; the interests, taxes, commissions or other charges that may generate purchases in quotes through credit cards will depend on the conditions that the consumer has agreed upon with each bank or credit broker.  We recommend that the consumer becomes informed of the conditions of use and of the interest associated with his/her credit card before making a payment in quotes.",
      "the_process_begins": "The process begins with the purchase generated by the user from our website. Validated the purchase (in our payment systems) the user will receive in his email the confirmation of the purchase and a pdf file with his Voucher. In this will be detailed all the information about te product and the data of the local tourism agency will be attached. In case of any inconvenience our customer service team will contact the tourist.",
      "the_quotes_related": "The quotes related to the use of the credit card will vary according to the type: If you use WebPay will be charged, 2,95% (chilean pesos) for the transaction, and 5.4% + $0.30USD if you purchase via PayPal.",
      "the_reservations": "Reservations made less than 70 days before the program's start date must be paid in full (100%) within the next 48 hours after confirmation by the executive. Once payment is received, no changes or cancellations can be made.",
      "the_total_cost": "The 30% of the total program cost will be requested to start the reservation process. The remaining 70% must be paid no later than 70 days before the program's start date, which is also the deadline for making changes to the reservation.",
      "viewnia_com_is_an_online": "Outdoor Index is an online excursion company that is contracted for the service of intermediation between local tour agencies and tourists who make use of the Outdoor Index web platform. We inform about the products that the tour providers offer, specifying their characteristics or conditions, carry out reservation and purchase requests, collect the values corresponding to the prices and we mediate in the case of doubts or in the search for solutions when a concern or inconvenience is presented. The tour providers (Tour Agencies) are who finally carry out the purchased product or service for the consumer on the <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong> platform.",
      "when_you_contract": "When you contract service through our agency, Outdoor Index, you must pay the equivalent of the sum of the price or fee of the tour service, plus relevant taxes on the use of a credit card (as applied). The payment can be made in two ways on our platform: paying 100% of the product´s value or paying 30% of the product to generate the reservation. We will request the remaining 70% of the payment within a variable timeframe, depending on the proximity of the requested date. It can range from 48 hours after confirmation of availability to 70 days in advance. It is important that prior to accepting the transaction, you pay close attention and carefully read the information regarding the valid pricing and cancellation or change policies described in each excursion or tour service offered on Outdoor Index, prior to instructing us to proceed with your purchase."
    },
    "foot": {
      "all_images_and_texts": "All texts and images loaded onto the website will be carefully reviewed by Outdoor Index staff to ensure they complete our standards. Photos with poor resolution, collages, watermarks, filters, texts, or that are dark, blurry, black and white, with people posing etc will not be permitted. It is prohibited to have texts with direct links or appear to have direct links to company websites (names, websites, emails etc) that bypass the commercial structure put forth by Outdoor Index. Outdoor Index reserves the right to modify or eliminate information loaded onto the platform that does not meet the requirements set forth.",
      "information_on_the": "<strong>8) INFORMATION ON THE WEBSITE:</strong>"
    },
    "head": {
      "conditions": "Terms of Service",
      "you_as_a_consumer": "You, as a consumer, have the right to be informed about the documentation that could be needed to carry out your trip according to plan.  Verify ahead of time if a passport, visa, vaccines, permission for minors or other documentation is required, whether it be to enter Chile or any other country you pass through in your travels.  If one of these is required and you do not possess it, complete the necessary steps to obtain it with anticipation."
    },
    "last": {
      "all_cancellation_requests": "All cancellation requests and eventual refund requests must be directed in writing to the email address <a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a>. Outdoor Index will mediate between the consumer and the service provider with the objective of evaluating the governing terms in the cases of annulments or cancellations.",
      "card": "<strong>7) MIGRATORY CARD:</strong>",
      "changes_covid": "<strong>5.2) BOOKING CHANGES:</strong>",
      "changes_covid_text": "It can be rescheduled at no cost with the option to change the date of stay until April of the same season or cancel a reservation up to 45 days before the check-in date, Individuals and Groups. All changes to reservations, outside the cancellation and payment deadlines, that generate a value lower than the original price, will not be refundable. If the reservation change was a higher value than the original price, this price difference must be paid.",
      "claims": "<strong>6) CLAIMS:</strong>",
      "covid": "<strong>5.1) CANCELLATIONS BY COVID-19:</strong>",
      "covid_text": "In the event of a resurgence due to COVID-19, which implies the closure of borders; closure of the Torres del Paine National Park, national quarantine or the client is infected with COVID-19 (presenting the proper medical certificate that accredits it), outside the established cancellation deadlines, Outdoor Index offers to reschedule the date of the reservation at no cost to a stay until April of the same season.",
      "migratory_card": "Foreign passengers: Remember that if you do not present your passport and migratory card (white paper issued by the police upon entering Chile), you will have to pay taxes.",
      "no_show": "<strong>5.3) NO SHOW:</strong>",
      "no_show_text": "Check-in is at 3:00 p.m. and check-out at 9:30 a.m. Guests must present themselves at the accommodations before 9:00 p.m. on the date indicated in the reservation. If you do not show up at the indicated time, Outdoor Index reserves the right to charge 100% of the value of the reservation, due to No Show, in addition, the reservation can be made available for sale again.",
      "the_consumer_will_be": "The consumer will be able to send a claim to the email <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>. It is recommended to attach immediately a copy of supporting documentation, explaining what occurred and formulating in clear and simple terms the desired solution.  In this way, we will be able to revise your case in a fast and efficient way. Outdoor Index will analyze the claim and give a response to the same email address of the consumer within a time frame of 7 working days to confirm the receipt of the claim and documentation. In the case of requiring a longer time frame to compile supporting documents or more information, the consumer will be informed, specifying the additional time needed.  The purpose of Outdoor Index is to exercise the service of intermediary between the parties with the objective of resolving as quickly as possible any problem or general concern regarding any of the products or services contracted through the <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong> web platform.",
      "the_previous_statement": "* In the event of cancellation or annulment of confirmed reservations, the service fee for intermediation and the tax paid for the bank transfer are not subject to reimbursement, as they correspond to a service effectively provided by Outdoor Index. The previous statement applies to all products and activities as long as the contrary is not stated in the description of the products in the “policy” item."
    }
  },
  "tierra_del_fuego": "Tierra del Fuego",
  "time": {
    "am": "am",
    "formats": {
      "daterangepicker": "MM/DD/YYYY",
      "default": "%m/%d/%Y",
      "full": "%a, %B %d / %Y",
      "long": "%B %d, %Y",
      "short": "%a, %b %e"
    },
    "pm": "pm"
  },
  "total": "Total to paid:",
  "total_final": "Final total",
  "total_only": "Total",
  "total_paid": "Total paid",
  "tourist": {
    "allergies": "Allergies",
    "date_of_birth": "Date of birth",
    "email": "Email",
    "emergency_contact": "Emergency contact",
    "food_restrictions": "Food restrictions",
    "hometown": "Hometown",
    "last_name": "Last name",
    "medical_conditions": "Medical conditions",
    "name": "Name",
    "nationality": "Nationality",
    "new_tourist_excerpt": "In this section you can add the information profile of all the people who will accompany you. It is very important that the attached information is as reliable as possible.",
    "no_reservations_to_assign": "No reservations to assign",
    "passport": "Passport",
    "pending": "Pending",
    "personal_info": "Personal info",
    "phone": "Phone",
    "ready": "Ready",
    "you_have_no_vouchers": "You have no active vouchers"
  },
  "tourists": {
    "first_create_group": "Ooops! Don`t forget to fill in the information of the passengers."
  },
  "uploader": {
    "drop_files_here": "Drop files here",
    "processing_dropped_files": "Processing dropped files...",
    "upload_a_file": "Upload a file"
  },
  "usd": "USD",
  "vacancies": "Vacancies",
  "value": "1300 mt",
  "value_included": "Ex: Bilingual guide",
  "view_more": "View more...",
  "view_more_excursions": "View more excursions...",
  "view_more_suggestions": "View more suggestions...",
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "next": "Next &rsaquo;",
      "previous": "&lsaquo; Prev",
      "truncate": "&hellip;"
    }
  },
  "vouchers": {
    "active_excerpt": "Here are the details of your vouchers. Please review and print them. It will be necessary to provide the vouchers, with the order number of the reservations.",
    "active_vouchers": "Active Vouchers",
    "activities": "Activities",
    "all_of_your_vouchers": "All of your vouchers",
    "complete": "Complete",
    "confirmed_excerpt": "Thank you for your purchase. We hope you have a beautiful trip. Do not forget to print your voucher and always take it with you. If you have any question or unexpected situation, do not hesitate to contact us.",
    "disclaimer": "In this PDF you have all the information about your product. Please check the document and if you have any questions, contact us. Enjoy your trip.",
    "error": "Something went wrong with your payment process, please try again later or contact us for other payment options.",
    "get_your_voucher": "Get your voucher",
    "home": "Home",
    "incomplete": "Incomplete",
    "order_number": "Order No:",
    "payment_error": "Payment error.",
    "payment_rejected": "Payment rejected",
    "print": "Print",
    "rejected": "PayU/Paypal has indicated that your payment was rejected, please check your details and try again, or contact us for other payment options.",
    "restart_payment": "Restart payment",
    "view": "View",
    "voucher": "Voucher",
    "was_partial_payment": "(2)"
  },
  "waiver": {
    "content": {
      "by_submitting_this_release": "By submitting this release form, I agree to release and hold harmless, Outdoor Index, its guides, suppliers, consultants, agents, and directors, for any damage or injuries, physical or mental, which might result from my participation in the Outdoor Index excursion.",
      "i_assume_full_responsibility": "I assume full responsibility for myself for bodily injury, death, loss of personal property, and expenses thereof, as a result of my negligence, or other risks, including but not limited to those caused by the trail, the terrain, the weather, my athletic and physical condition, other participants or guides.",
      "i_recognize_that_there": "I recognize that there is a significant risk in any adventure, sport, or activity associated with the outdoors. Knowing the inherent risks, dangers, and rigors involved in the activities, I certify that I am fully capable of participating in the activities. I understand that I will not be forced to participate in any element that I feel uncomfortable with. I further understand that my image, by photograph or video, may be used for promotional purposes.",
      "viewnia_com_excursions_you_are": "Outdoor Index excursions you are signing up for involves physically and emotionally demanding activities in an outdoor setting. It can sometimes include climbing, jumping, and other rigorous activities on natural and man-made structures that are on the ground or at low, medium, or high distances off the ground. You will be accompanied for a Guide and with others in your group. It is possible that you may be injured while participating in the excursions either because of your own conduct, conduct of others in the group, conduct of the guide, or the conditions of the premises. We want to make sure you understand the risks of injury before you decide to participate in the excursions. It is required that you read the following very carefully, make sure you understand it, and sign it before you begin participating in the www.Outdoor Index excursions."
    },
    "foot": {
      "i_acknowledge_that_i": "I ACKNOWLEDGE THAT I HAVE BEEN GIVEN THE OPPORTUNITY TO ASK QUESTIONS REGARDING ANY ASPECT OF THIS RELEASE FORM AND BY SUBMITTING THIS FORM I DO ACKNOWLEDGE THAT I HAVE READ COMPLETELY AND FULLY UNDERSTAND ALL ASPECTS OF THIS RELEASE FORM AND AGREE TO ITS TERMS IN ITS ENTIRETY."
    },
    "last": {
      "by_submitting_this_release": "By submitting this release form, i agree that if I do sustain physical injury or mental damage of any nature as a result of my decision to participate in the Outdoor Index excursions, I voluntarily agree to hold harmless the above-named parties from any liability therefore and that this release is binding on my heirs, personal representatives, and assigns."
    },
    "title": {
      "waiver_of_liability_agreement": "Waiver of liability agreement"
    }
  },
  "webpay": {
    "error_alert": "An error has occurred with Webpay, please check if your order is paid.",
    "error_dec": "There was an error in processing the payment, please try again. If the problem persists, contact us.",
    "error_title": "Transaction rejected"
  },
  "welcome": "Welcome to Outdoor Index!",
  "what": "What",
  "when": "When",
  "where": "Where",
  "zone": "Zone"
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "about_us": {
    "content": {
      "from_the_inside_out": "Several kilometers of long walks and trails can testify to the uncountable conversations about nature, mountains, geography, local communities, and the way of life “at the end of the world”. On each of these chats with people from all over the world, no matter where they were from, I started to notice the same question: ¿is there anything else I can do here? ¿how can I get there? ¿What other activities can you do around? ¿what can I do after these?",
      "in_this_way": "That´s how motivation was raised and with a solid problem to solve, Viewnia.com was born. An agency with a strong focus on connecting travelers with an appropriate nature experience for each of them, this by offering the widest selection of outdoor excursions on every area of Patagonia available on market."
    },
    "foot": {
      "viewnia_com_check_us_out": "<strong>Outdoor Index/ Puerto Natales, Patagonia. Chile.</strong>"
    },
    "head": {
      "about_us": "About Us",
      "creating_a_quality_online": "“On every walk through nature, you receive much more than you are looking for” (John Muir)",
      "creating_a_quality_online_sub": "I was 29 when I quit my graphic designer full-time job and full youth depression to accept a job guiding tours in Patagonia. Had no knowledge of trekking, less of the tourism industry. At that stage, interacting with nature was only about a short walk in the forest or enjoying a horse ride or bike. Although everything was a bit naive, I understood the basic principle of my new job: to connect. Being a facilitator allows & amplifies the connection of the tourist with their surroundings by giving the proper information and support for the experience to be as significant as possible. Sometimes the situation required to share a lot of information with others just to share the silence and let the views and the vast nature of the area do the job."
    },
    "last": {
      "viewnia_com_believes_in_a": "<strong>Outdoor Index is a marketplace specialized in promoting and selling adventure excursions and outdoor activities in Chile, connecting outdoor enthusiasts with meaningful experiences. We focus on offering local and authentic experiences in remote destinations, facilitating the search, information, and booking of activities through a modern and high-quality platform that guarantees safety and ease of use.</strong>",
      "viewnia_com_believes_in_a_10": "An adventure can be a transcendental experience towards personal growth. Best decisions are born from a crisis. For some people that crisis or challenge can be a half-day walk for others can be ice climbing. For some a kayak navigation down the river for others an 8-day trekking expedition. To help you to transform your trip into a life-changing and transcendental experience is one of our priorities.",
      "viewnia_com_believes_in_a_11": "<strong>4.Activation of touristic ecosystems.</strong>",
      "viewnia_com_believes_in_a_12": "When visiting a place as a tourist is very important to know that your visit is not only a money income for the operator that you are adventuring with. With your spending you will have the chance to support the little market of the community, or maybe the gas station, and more. Your visit to the local souvenir store for local handmade products and by that your visit is transformed into a whole activation of the economic ecosystem of the community.",
      "viewnia_com_believes_in_a_13": "We want to be activators of these ecosystems by generating movement around all the touristic chains of every locality that we work on, supporting local littler businesses, generating exhibitions, and promoting a collaborative spirit.",
      "viewnia_com_believes_in_a_2": "<strong>1.Diversify to protect</strong>",
      "viewnia_com_believes_in_a_3": "The wide amount of excursions we offer has always been a big differentiation factor by covering vastly every place on which we work. We focus on little-known places to decongest those who are very well known and had been affected and eroded by their high population demand. Is an indirect way to conserve and very important and very transcendental on the user experience on a different kind of trip.",
      "viewnia_com_believes_in_a_4": "¿Do you help to conserve by choosing differently? Yes! The number of corners to contemplate in Patagonia is infinite, the chances of finding that WOW moment are everywhere, behind every tree. Your decisions and places you visit or stop visiting are an important way of conservation. That´s why we always are going to support new places, new routes, new operators, and new destinies.",
      "viewnia_com_believes_in_a_5": "<strong>2.To be agents for change for conscious tourism.</strong>",
      "viewnia_com_believes_in_a_6": "Tourism has environmental, socio-cultural, and economic effects. It moves and interacts inside of these three factors actively. Our purpose is to be agents for change by educating towards more responsible practices on these subjects.",
      "viewnia_com_believes_in_a_7": "To be an agent for change is an active role, persistent, and focused. The change for conscious tourism is to change toward responsible decisions, for both tourists and operators. A conscious focus allows you to value up a trip from the story, allows you to increase the capacity of wonder, allows your trip to transcend by giving purpose and teachings, and that stays on you for much longer than your trip.",
      "viewnia_com_believes_in_a_8": "<strong>3.Foment adventure as a personal growth experience.</strong>",
      "viewnia_com_believes_in_a_9": "Interaction with nature is an enormous resource of challenge and it´s challenging that pushes us to go further, going out of our comfort zone and testing our capacities, decision-making will, our head, and our mind. To visualize and maybe to integrate a better version of ourselves."
    }
  },
  "accommodation": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "category": "Category",
    "description": "About the product",
    "location": "Location",
    "meeting_address": "Address",
    "our_location": "Our location",
    "policies": "Policies",
    "price": "Price",
    "profiles": "Profiles",
    "save_excursion": "Save product",
    "title": "Title",
    "vacancies": "Vacancies",
    "zone": "Zone"
  },
  "active_admin": {
    "access_denied": {
      "message": "You are not authorized to perform this action."
    },
    "admin_users": "Admin Users",
    "agencies": "Agencies",
    "agency": "Agency",
    "any": "Any",
    "batch_actions": {
      "action_label": "%{title} Selected",
      "button_label": "Batch Actions",
      "default_confirmation": "Are you sure you want to do this?",
      "delete_confirmation": "Are you sure you want to delete these %{plural_model}?",
      "labels": {
        "destroy": "Delete"
      },
      "selection_toggle_explanation": "(Toggle Selection)",
      "succesfully_destroyed": {
        "one": "Successfully deleted 1 %{model}",
        "other": "Successfully deleted %{count} %{plural_model}"
      }
    },
    "blank_slate": {
      "content": "There are no %{resource_name} yet.",
      "link": "Create one"
    },
    "blog": "Blog",
    "cancel": "Cancel",
    "categories": "Categories",
    "comments": {
      "add": "Add Comment",
      "author": "Author",
      "author_missing": "Anonymous",
      "author_type": "Author Type",
      "body": "Body",
      "created_at": "Created",
      "delete": "Delete Comment",
      "delete_confirmation": "Are you sure you want to delete these comments?",
      "errors": {
        "empty_text": "Comment wasn't saved, text was empty."
      },
      "no_comments_yet": "No comments yet.",
      "resource": "Resource",
      "resource_type": "Resource Type",
      "title_content": "Comments (%{count})"
    },
    "comments_tab": "Comments",
    "covers": "Home",
    "create_another": "Create another %{model}",
    "dashboard": "Dashboard",
    "dashboard_welcome": {
      "call_to_action": "To add dashboard sections, checkout 'app/admin/dashboard.rb'",
      "welcome": "Welcome to Active Admin. This is the default dashboard page."
    },
    "delete": "Delete",
    "delete_confirmation": "Are you sure you want to delete this?",
    "delete_model": "Delete %{model}",
    "details": "%{model} Details",
    "devise": {
      "change_password": {
        "submit": "Change my password",
        "title": "Change your password"
      },
      "email": {
        "title": "Email"
      },
      "links": {
        "forgot_your_password": "Forgot your password?",
        "resend_confirmation_instructions": "Resend confirmation instructions",
        "resend_unlock_instructions": "Resend unlock instructions",
        "sign_in": "Sign in",
        "sign_in_with_omniauth_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "login": {
        "remember_me": "Remember me",
        "submit": "Login",
        "title": "Login"
      },
      "password": {
        "title": "Password"
      },
      "password_confirmation": {
        "title": "Confirm Password"
      },
      "resend_confirmation_instructions": {
        "submit": "Resend confirmation instructions",
        "title": "Resend confirmation instructions"
      },
      "reset_password": {
        "submit": "Reset My Password",
        "title": "Forgot your password?"
      },
      "sign_up": {
        "submit": "Sign up",
        "title": "Sign up"
      },
      "subdomain": {
        "title": "Subdomain"
      },
      "unlock": {
        "submit": "Resend unlock instructions",
        "title": "Resend unlock instructions"
      },
      "username": {
        "title": "Username"
      }
    },
    "download": "Download:",
    "dropdown_actions": {
      "button_label": "Actions"
    },
    "edit": "Edit",
    "edit_model": "Edit %{model}",
    "empty": "Empty",
    "excursion_images": "Excursion images",
    "excursions": "Excursions",
    "filters": {
      "buttons": {
        "clear": "Clear Filters",
        "filter": "Filter"
      },
      "predicates": {
        "contains": "Contains",
        "ends_with": "Ends with",
        "equals": "Equals",
        "from": "From",
        "greater_than": "Greater than",
        "gteq_datetime": "Greater or equal to",
        "less_than": "Less than",
        "lteq_datetime": "Lesser or equal to",
        "starts_with": "Starts with",
        "to": "To"
      }
    },
    "has_many_delete": "Delete",
    "has_many_new": "Add New %{model}",
    "has_many_remove": "Remove",
    "home": "Home",
    "home_collections": "Home Collections",
    "index_list": {
      "block": "List",
      "blog": "Blog",
      "grid": "Grid",
      "table": "Table"
    },
    "kpis": {
      "abandoned_cart_subtitle": "Orders left in the backpack",
      "abandoned_cart_title": "Abandoned Backpacks",
      "conversion_chart_subtitle": "Conversion rate from the last year",
      "conversion_chart_title": "Conversion Chart",
      "conversion_last_year": "Conversion rate last year",
      "conversion_subtitle": "Updated to date",
      "conversion_title": "Conversion rate",
      "countries": "Countries",
      "countries_subtitle": "Those who visit us the most",
      "country_anon": "Anonymous",
      "goals": "Goal",
      "last_day": "Previous day",
      "new_orders_subtitle": "Registered this month",
      "new_orders_title": "New Orders",
      "new_users_subtitle": "Registered last month",
      "new_users_subtitle_box": "Visit flows of the day",
      "new_users_title": "New Users",
      "new_users_title_box": "Today's users",
      "no_sale_goal": "No sale goal defined",
      "observed_dollar": "Observed dollar value",
      "observed_dollar_subtitle": "Dollar value define by the Central Bank",
      "paid_orders_subtitle": "Registered this month",
      "paid_orders_title": "Paid Orders",
      "posts_viewed_subtitle": "Most popular for now",
      "posts_viewed_title": "Most viewed post",
      "products_selled_subtitle": "In the last year",
      "products_selled_title": "Best Selling Products",
      "products_viewed_subtitle": "Most popular for now",
      "products_viewed_title": "Most viewed products",
      "sale_goals_subtitle": "Total yield for this month",
      "sale_goals_title": "Sales target",
      "show_more": "See more",
      "to": "to",
      "today_excursions_subtitle": "Tourists leaving today for their tour.",
      "today_excursions_title": "Excursions today",
      "users": "Users",
      "users_month_subtitle": "Statistical balance of monthly users.",
      "users_month_title": "Monthly statistics",
      "users_number": "Number of users",
      "users_total_subtitle": "Registered from the start",
      "users_total_title": "Total Users",
      "visitors": "Visitors",
      "visits_today_subtitle": "Flows for today",
      "visits_today_title": "Today's visits"
    },
    "landing": {
      "body": "Body",
      "cover": "Cover",
      "excerpt": "Here you can complete all the necessary information to create a Landing. Always make sure that the cover photo you upload have a good resolution and are attractive to the tourist. Remember to give clear and reliable information.",
      "meta_description": "Meta description",
      "meta_title": "Meta title",
      "more_info": "More info",
      "place": "Place",
      "product": "Excursion",
      "review": "Review",
      "save_landing": "Save landing",
      "tilte1": "Create Landing",
      "title": "Title",
      "title2": "Meta",
      "title3": "Cover",
      "title4": "Reviews",
      "title5": "FAQ"
    },
    "landings": "Landings",
    "logout": "Logout",
    "macro_zones": "Macro Zones",
    "mailer": "Mailer",
    "main_content": "Please implement %{model}#main_content to display content.",
    "move": "Move",
    "new_model": "New %{model}",
    "newsletter_covers": "Newsletter covers",
    "next": "Next",
    "notifications": "Notifications",
    "observed_dollar_update": "Update",
    "orders": "Orders",
    "pages": "Pages",
    "pagination": {
      "empty": "No %{model} found",
      "entry": {
        "one": "entry",
        "other": "entries"
      },
      "multiple": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{total}</b> in total",
      "multiple_without_total": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b>",
      "one": "Displaying <b>1</b> %{model}",
      "one_page": "Displaying <b>all %{n}</b> %{model}",
      "per_page": "Per page: "
    },
    "payment_methods": "Payment methods",
    "post": {
      "author_name": "Author name",
      "author_sm": "Author Social Media",
      "content": "Content",
      "cover": "Cover",
      "label": "Label",
      "published": "Published",
      "recommended_posts": "Recommended Posts",
      "subtitle": "Subtitle",
      "tags": "Tag",
      "title": "Title",
      "unpublished": "Unpublished"
    },
    "powered_by": "Powered by %{active_admin} %{version}",
    "previous": "Previous",
    "private": "Private",
    "products": "Products",
    "profiles": "Profiles",
    "promotions": "Promotions",
    "properties": "Properties",
    "question_subjects": "Question subjects",
    "questions": "Questions",
    "requests": "Requests",
    "review": {
      "author": "Name",
      "body": "Review text",
      "country": "Country",
      "cover": "Cover",
      "excerpt": "Here you can complete all the necessary information to create a Review. Always make sure that the cover photo you upload have a good resolution and is correct. Remember to read y check the information.",
      "language": "Language",
      "rating": "Rating",
      "review_date": "Review date",
      "save_review": "Save review",
      "tilte1": "Create Review",
      "title2": "Cover",
      "url": "URL"
    },
    "reviews": "Reviews",
    "sale_goals": "Sale Goals",
    "sales": "Sales",
    "save_home_collections": "Save Collection",
    "scopes": {
      "all": "All"
    },
    "search_status": {
      "current_filters": "Current filters:",
      "current_scope": "Scope:",
      "headline": "Search status:",
      "no_current_filters": "None"
    },
    "season": {
      "child_clp_price": "Childs in CLP",
      "child_usd_price": "Childs in dollars",
      "daily_quota": "Availability",
      "guardar_temporada": "Save season",
      "mkt_clp_price": "CLP price",
      "mkt_price": "Marketing price",
      "mkt_usd_price": "USD price",
      "name": "Name",
      "product": "Excursion",
      "single_supplement_clp_price": "CLP",
      "single_supplement_usd_price": "Dollars",
      "start_time": "Start time",
      "title_ages": "Ages",
      "title_daily_quota": "Daily quota",
      "title_days": "Days",
      "title_prices_adults": "Adults price",
      "title_prices_kids": "Kids price",
      "title_season": "1) Season information",
      "title_single_supplement": "Single Supplement",
      "valid_from": "Valid from",
      "valid_until": "Valid until"
    },
    "seasons": "Seasons",
    "settings": "Settings",
    "sidebars": {
      "filters": "Filters",
      "search_status": "Search Status"
    },
    "status_tag": {
      "no": "No",
      "unset": "No",
      "yes": "Yes"
    },
    "suppliers": "Suppliers",
    "tags": "Tags",
    "tourists": "Tourists",
    "unsupported_browser": {
      "headline": "Please note that ActiveAdmin no longer supports Internet Explorer versions 8 or less.",
      "recommendation": "We recommend that you <a href=\"http://browsehappy.com/\">upgrade your browser</a> to improve your experience.",
      "turn_off_compatibility_view": "If you are using IE 9 or later, make sure you <a href=\"https://support.microsoft.com/en-us/help/17471\">turn off \"Compatibility View\"</a>."
    },
    "users": "Users",
    "view": "View",
    "visits": "Visits",
    "zone": {
      "country": "Country",
      "description": "Description",
      "name": "Name",
      "sort_order": "Sort order",
      "state": "State"
    },
    "zones": "Zones"
  },
  "activerecord": {
    "attributes": {
      "active_admin/comment": {
        "author_type": "Author type",
        "body": "Body",
        "created_at": "Created",
        "namespace": "Namespace",
        "resource_type": "Resource type",
        "updated_at": "Updated"
      },
      "order": {
        "channel": "Channel:",
        "clp_charge": "CLP charge",
        "confirmed_at": "Purchase:",
        "customer_phone": "Phone:",
        "departure_at": "Departure:",
        "email": "Email:",
        "extra": "Extra:",
        "instructions": "Instructions:",
        "language": "Language:",
        "manual_currency": "Currency:",
        "manual_total": "Paid:",
        "partial_payment": "Payment:",
        "pax_manual": "Pax No:",
        "payment_method": "Method:",
        "product": "Product:",
        "product_name": "Product",
        "usd_charge": "USD charge",
        "username": "Client:"
      },
      "season": {
        "fri_check": "Fri",
        "mon_check": "Mon",
        "name": "Name",
        "product": "Excursion",
        "sat_check": "Sat",
        "start_time": "Start time",
        "sun_check": "Sun",
        "thu_check": "Thu",
        "tue_check": "Tue",
        "valid_from": "Valid from",
        "valid_until": "Valid until",
        "wed_check": "Wed"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "active_admin/comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "comment": {
        "one": "Comment",
        "other": "Comments"
      },
      "season": "Season",
      "seasons": "Seasons",
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "activities": {
    "bikes": "Bikes",
    "birdwatching": "Birdwatching",
    "cruises": "Cruises",
    "flyfishing": "Fly flyfishing",
    "helicopter": "Helicopter",
    "horseback": "Horseback",
    "icehike": "Ice Hike",
    "kayak": "Kayak",
    "mountaineering": "Mountaineering",
    "multiactivity": "Multi Activity",
    "navigations": "Navigations",
    "photosafari": "Photo Safari",
    "rafting": "Rafting",
    "ski": "Ski",
    "snowshoes": "Snowshoes",
    "trekking": "Trekking"
  },
  "add_row": "Add",
  "address": "Address",
  "agencies": {
    "account_number": "Account number",
    "account_type": "Account type",
    "actions": "Actions",
    "active": "Approved",
    "address": "Address",
    "agency": "Agency",
    "agency_bank": "Add bank account",
    "agency_info": "Information",
    "agency_information": "Agency information",
    "agency_type": "Agency Type",
    "apply_changes": "Apply changes",
    "bank_name": "Bank name",
    "change_availability": "Manage your dates",
    "commercial_information": {
      "account_type": "Account type",
      "bank_account": "Bank account",
      "bank_name": "Bank name",
      "commercial_name": "Commercial name",
      "id_rut": "ID / Rut",
      "name": "Name",
      "self": "Commercial information"
    },
    "country": "Country",
    "create_activity": "Create activity",
    "create_agency": "Create new agency",
    "create_excursion": "Create Excursion",
    "create_product": "Create product",
    "created_at": "Created at",
    "date_range": "Modify according to date range",
    "duration": "Duration",
    "edit_excerpt": "All the information requested to create the supplier profile is of vital importance to establish the commercial link with Outdoor Index. Please enter up to date information.",
    "english": "Traducir al ingles →",
    "excursions": "Excursions",
    "for_booking": "for booking",
    "holder_email": "Holder Email",
    "holder_id": "Holder ID",
    "holder_name": "Holder Name",
    "index_excerpt": "Here is the list of your products. You can create, edit and manage the dates. Each product created will remain in the \"Pending\" state until it is reviewed by the Outdoor Index team.",
    "list_title": "A list of all your dates",
    "manage_dates": "Dates",
    "manage_dates_excerpt": "Here you have a calendar to add all your products. You can create, edit and manage the dates. Each product created will remain in the \"Pending\" state until it is reviewed by the Outdoor Index team.",
    "max_words": "150 words",
    "name": "Name",
    "new_excursion_excerpt": "Here you can complete all the necessary information to create a product. Always make sure that the photos you upload have a good resolution and are attractive to the tourist. Remember to give clear and reliable information.",
    "no_dates": "No dates yet, please click on the calendar to create one.",
    "numbers": "Numbers",
    "owner": "Owner",
    "pending_changes": "Pending",
    "person_in_charge": "person in charge",
    "products": "Products",
    "products_to_add": "Add products",
    "products_to_remove": "Remove products",
    "review": "Review",
    "rooms_to_add": "Rooms to add",
    "rooms_to_remove": "Rooms to remove",
    "set_price": "Set price",
    "spanish": "Translate to spanish →",
    "state": "State",
    "supplier_info": "Information",
    "supplier_information": "Supplier information",
    "supplier_review": "Supplier review ",
    "update_info": "Save profile",
    "website_url": "Website URL",
    "zone": "Zone"
  },
  "alert": {
    "confirm_to": "You must confirm that you are not a robot.",
    "couldn't_save": "Couldn't save, please try again later.",
    "couldn't_send_review": "Couldn't send review",
    "error": "The password must have at least 8 characters, one letter, and one number.",
    "invalid_action": "Invalid action: amount can't be lower than 0",
    "invalid_changes": "Invalid changes",
    "invalid_language": "Invalid language",
    "no_products_in_your_order": "No products in your order",
    "not_enough_availability": "Not enough availability for that date range. Check other accommodations or book fewer units.",
    "please_sign_the_waiver": "Please sign the waiver with your name.",
    "review_already_submitted": "Review already submitted",
    "signature_and_tourist": "Signature and tourist name must be identical.",
    "something_went_wrong": "Something went wrong. Please try again or contact us.",
    "you_must_agree_to": "You must agree to our terms and conditions"
  },
  "applicants": {
    "additional_info": "4) Additional information",
    "applicants_number": "2) How many people will participate in the Excursion?",
    "applicants_phone": "1) Leave us your contact information",
    "dates_sub": "3) In which days do you wanna make this Excursion?",
    "email": "Email",
    "lastname": "Lastname",
    "name": "Name",
    "phone_placeholder": "Phone"
  },
  "back": "Back",
  "back_to": "Back to",
  "banner": {
    "do_you_need_travel_insurance": "Do you need travel insurance?",
    "get_a_quote": "Get a Quote",
    "in_partnership_with_world": "Outdoor Index in partnership with World Nomads give you the best insurance available for Chilean Patagonia."
  },
  "blog": {
    "travel_nature_growth": "Travel, nature & growth",
    "we_connect_you_with": "We connect you with leading content creators, from the world and multiple disciplines, to inform and nurture you about nature, travel and growth, we read your comments in rrss!"
  },
  "categories": {
    "general": "general",
    "general_category_search": "General %{category_name} search",
    "general_zone_search": "Search suggestions in %{zone_name}",
    "other_excursions_to_do": "Other experiences to do in %{zone_name}",
    "recommended_for_you": "Experiences to do in %{zone_name}",
    "search": "search"
  },
  "category": "Category",
  "charges": "Charges:",
  "chat": {
    "begin": "Begin",
    "create_message": "Send message",
    "enter_email": "Enter your email...",
    "enter_message": "Enter a message...",
    "enter_your_name": "Enter your name...",
    "hi": "Hello",
    "messages": "Messages",
    "new-message": "New message",
    "our_chat_service": "Our chat service is available Monday through Friday from 10 am to 6 pm (UTC / GMT -3). If you write to us within these hours, we will respond you in a few minutes.",
    "user": "User"
  },
  "chat_body": "Body",
  "chat_btn": "Send Message",
  "chat_email": "Email",
  "chat_name": "Name",
  "chat_phone": "Phone",
  "chat_subject": "Subject",
  "chat_subtitle": "We usually respond in a few hours",
  "chat_title": "How can we help?",
  "checkout": {
    "100_p": "100%",
    "100_per": "Paid 100%",
    "100_pers": "Paid 100%",
    "30_p": "30%",
    "30_per": "Paid 30%",
    "30_pers": "Paid 30%",
    "accept": "Yes, accept",
    "after_this_step": "After this step, Outdoor Index will contact all providers.",
    "all_transactions_have_an_extra": "You can pay using your debit or credit card in the installments that you agree with your bank. Outdoor Index will receive the full amount of the sale.",
    "cancel": "Cancel",
    "choose_payment": "Choose the payment you desire",
    "complete_payment": "Pay in full",
    "confirm_checkout_excerpt": "Before proceeding please check everything is correct, once you accept this order you will not be able to change it.",
    "confirm_checkout_excerpt_2": "(You can still create a new order.)",
    "continue_shopping": "Continue Shopping",
    "disclaimer": "About your reservation",
    "do_checkout": "Checkout",
    "everything_looks_fine": "Everything looks fine",
    "get_a_refund_if": "Get a refund if you cancel within 24 hours of purchase.",
    "i_hereby_accept": "I accept the",
    "i_need_to_change_something": "I need to change something",
    "important!": "Important",
    "next_departure": "Next departure: Oct/26/2018",
    "not_available": "Not available",
    "only_for_reservation": "Reserve",
    "pay_now": "Pay now",
    "payment_excerpt": "Before buying, make sure that all the details of your products are correct. Check the number of reservations and their costs. Remember to read our cancellation policy.",
    "please_confirm_your_details": "Please confirm your details",
    "providers_confirm_availability": "When all providers confirm your order, you will be contacted.",
    "ready": "Ready",
    "review": "Review",
    "some_products_deleted": "Some products are no longer available",
    "your_details_confirmation_and_payment": "Detail of your products and payment",
    "your_itinerary": "Your itinerary in detail"
  },
  "checkout_bar": {
    "assign_tourists": "Assign",
    "checkout": "Payment",
    "itinerary": "Itinerary",
    "tourists": "Group",
    "voucher": "Voucher"
  },
  "child_guest": "Child",
  "child_guests": "Childs",
  "clp": "CLP",
  "comments": {
    "body": "Write your comment here...",
    "comments": "Comments",
    "post_a_comment": "Post a comment!",
    "replies": "Replies",
    "reply": "Reply",
    "submit": "Submit"
  },
  "continue": "Continue",
  "coupon": "Coupon",
  "covid": {
    "are_we": "¿ARE WE GETTING RESERVATIONS?",
    "flights": "FLIGHTS",
    "for_more": "FOR MORE EXPERT INFORMATION",
    "for_more_expert": "For more expert info about COVID-19 and local protocols for security on your next trip, we recommend to visit the resources given by the Chilean government on the next links: <strong><a href=\"https://www.gob.cl/coronavirus/\">www.gob.cl/coronavirus</a></strong> - <strong><a href=\"https://www.sernatur.cl\">www.sernatur.cl</a></strong>",
    "for_the_latest": "For the latest info regarding your flights, we recommend to contact your airline as soon as possible. Some companies have canceled and/or established restrictions, protocols and process that you should be knowing of.",
    "hotels": "HOTELS",
    "if_for_some": "If for some COVID-19 related reason it is necessary to reschedule your trip, you will be able to have a 100% credit of the money already paid for a trip until April 2024, less any hotel fee or commission from some operator impossible to recover. We going to be persistent on trying to recover as much money as possible having in count the conditions.</br></br>For more details related to Outdoor Index reservation policies during times of COVI-19 and for the future, you can continue reading our <strong><a href=\"https://www.outdoorindex.cl/terms\">“Terms & Conditions section”</a></strong> on our site.",
    "in_case_of_a": "In case of a muli-day experience or a program, we want to tell you that every lodging has rigorously sanitized and it follows with protocols actively. Governmental organisms have also collaborated with trainings and inspections to assure an appropriate management.",
    "in_case_of_your": "In case of your product counts with transport, as in the case of lodging, we have follow the protocols required by the government specialized agencies, including the amount of people bey vehicle. Gel sanitizer will be on reach, and masks will be used during traveling.",
    "keep_your": "KEEP YOUR SELF INFORMED",
    "make_your_reservation": "MAKE YOUR RESERVATION WITHOUT UNCERTAINTIES",
    "on_destiny": "ON DESTINY",
    "on_reaching_destiny": "On reaching destiny and always will be a straight local phone with someone in charge to contact Outdoor Index to let us help you on any issue. The referred of the company that receives you will follow every local protocol signed by Outdoor Index.",
    "our_here_described": "Our here described policies regarding COVID-19 are subject to changed according recommendations and requirements of the local government, the Health  ministry, and any government entity managing tourism related subjects. We do try to be leaders on this matter, on the local ecosystem we are constantly monitoring the situations and any changes on the areas our operators work.",
    "our_main_policy": "Our main policy on these days is flexibility, so we want you to be able to cancel or re-schedule your trip close to the date it necessary. We want you to be relaxed regarding this subject. We´ve been working on tourism for several years always producing experiences in Patagonia and south Chile, we personally know the operators and is our priority to be with you from your very first visit to our site till you are back at home after your trip helping us to do our job better giving us your feedback on our post – trip survey, this will help the operators and the hole management of the ecosystem to grow better and with more sustainable tactics. </br></br>If you are thinking to travel on 2022, is not to early to start planning. In fact, it is more important than ever because of all the people rescheduling their trips and the limited spaces that some of our programs have.",
    "read_about": "Read about our response to <b>COVID-19</b>",
    "sub_text": "We know there´s a lot of people like us willing to come back to nature whenever its possible. The lock down and been limited from what we enjoy the most makes you dream of your next adventure. We are here to tell you that in Outdoor Index we are ready for you. We have managed and implemented our own protocols and verified the actions of each of our operators to keep all the stake holders involved; travelers, guides, local communities, every one safe. Our protocols are available and we are going to inform you directly on the moment of your trip. Constant updates will be  given whenever is pertinent. All´s going to be ready settled up for the right moment to give you the best welcome on your next adventure.",
    "title": "Info Covid",
    "to_be_calm": "To give you peace of mind during these difficult times, we have aligned our cancellation policies with those of our suppliers. To cancel your trip and receive 100% of your money back, you will have to make the cancellation 45 days before the start of the tour (previous medical certificate or proof that proves the cancellation situation due to Covid-19). Otherwise, Outdoor Index can offer the following alternatives: - Reschedule the date of the reservation for a stay until April 2024. - Transfer the reservation to another name.",
    "vehicles": "VEHICLES",
    "we_sure_do": "WE SURE DO. Check here our excursions or click any of our recommended options. If you want ideas check our <strong><a href=\"https://www.outdoorindex.cl/blog\">blog</a></strong> for some inspirations. Contact us for a local expert to  help you on your preparation, you can write to us to <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>",
    "what_happens_if": "WHAT HAPPENS IF I WANT TO CANCEL MY TRIP?",
    "what_if": "WHAT HAPPENS IF MY TRIP IS RESCHEDULED DUE TO COVID-19 MOBILITY RESTRICTIONS?"
  },
  "create_an_account": "Create your account",
  "currency": {
    "displaying_prices_in": "Currency exchange done!"
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "daterangepicker": "MM/DD/YYYY",
      "default": "%m/%d/%Y",
      "long": "%B %d, %Y",
      "moment": "%Y-%m-%d",
      "short": "%a, %b %e"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "days": "days",
  "delete": "Delete",
  "departure": "Departure",
  "devise": {
    "already_have_an_account?": "You have an account?",
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "didn't_receive_confirmation_instructions?": "Didn't receive confirmation instructions?",
    "didn't_receive_unlock_instructions?": "Didn't receive unlock instructions?",
    "don't_have_an_account?": "Don't have an account?",
    "enter_email_associated": "Enter the email associated with your account and we'll email you a link to reset your password.",
    "facebook_sign_in": "Connect with Facebook",
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "forgot_your_password?": "Forgot your password?",
    "google_sign_in": "Connect with Google",
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "edit": "Edit",
  "email": "Email",
  "error": {
    "go": "Go to homepage",
    "improvements": "If you see this message, it's because you may have typed the web address incorrectly or because we are updating the site with new features and improvements. Please wait a few minutes and reload the page again. Thank you!",
    "opps": "You may have mistyped the address or the page may have moved.",
    "sorry": "Sorry, this page doesn't exist.",
    "went_wrong": "We're sorry, but something went wrong."
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "in_between": "must be in between %{min} and %{max}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "must_contain_lower": " must contain at least 1 lowercase ",
      "must_contain_number": " must contain at least one number",
      "must_contain_special": " must contain special character",
      "must_contain_upper": " must contain at least 1 uppercase ",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "spoofed_media_type": "has contents that are not what they are reported to be",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    }
  },
  "excursions": {
    "add_extras": "Add extras",
    "add_images_(minimum_3)": "Add images (3 min)",
    "best_excursions_for_the_next_season": "Recommended for this season",
    "body": "Our itinerary",
    "body_old": "Our Old itinerary",
    "category": "Category",
    "clp_price": "Price (in pesos)",
    "create_excursions": "Create excursions",
    "days": {
      "one": "Day",
      "other": "Days",
      "zero": "Day"
    },
    "description": "Overview",
    "details": "Details",
    "disclaimer": "Disclaimer",
    "duration": "Duration",
    "duration_days": "Days",
    "duration_hours": "Hours",
    "edit_excerpt": "All the information requested to create the supplier profile is vital importance to establish the commercial link with Outdoor Index. Please enter the most reliable information possible.",
    "extra_description": "Activity",
    "faq": {
      "body": "Body",
      "sub_title": "Subtitle",
      "title": "Title"
    },
    "faqs": "FAQs",
    "full_day": "Full day",
    "half_day": "Half day",
    "hours": {
      "one": "Hour",
      "other": "Hours",
      "zero": "Hour"
    },
    "images": "Images",
    "included": "Whats included?",
    "itinerary": {
      "body": "Body",
      "sub_title": "Subtitle",
      "title": "Title"
    },
    "less": "Less",
    "location": "Place",
    "locations": "Location",
    "macro_zone": "Macro Zone",
    "meeting_address": "Address",
    "meeting_instruction": "Starting place",
    "meta_description": "Meta description",
    "meta_title": "Meta title",
    "minimum_reservations": "N° guests",
    "mkt_clp_price": "Base price clp",
    "mkt_usd_price": "Base price usd",
    "more": "More",
    "multiple_days": "Multiple days",
    "other_excursions_from_this_tour_company": "Other experiences from this supplier",
    "our_itinerary": "Itinerary",
    "policies": "Policies",
    "price": "Price (in dollars)",
    "price_per_reservations": "Price per reservations",
    "prices": "Prices",
    "profiles": "Trip style",
    "save_excursion": "Save excursion",
    "time": "Time",
    "title": "Title",
    "usd_price": "Price (in dollars)",
    "useful_information": "Useful information",
    "vacancies": "Vacancies",
    "what's_included?": "Included",
    "what's_not_included?": "Not included",
    "what_we'll_do": "Overview",
    "zone": "Zone"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "footer": {
    "about_us": "About us",
    "all_rights_reserved": "All rights reserved. No parts of this site may be reproduced without permission.",
    "clp": "Chilean pesos (CLP)",
    "contact_us": "Contact us",
    "covid": "Covid",
    "edit_endorsement": "Outdoor Index is based in Puerto Natales (Southern Chilean Patagonia) and has the support of more than 40 providers.",
    "endorsement": {
      "by_entering_your": "Before, during, and after your experience to solve your inquiries and help plan your next adventure.",
      "instant_booking": "Certified guides 100% available for your assistance",
      "payment_protection": "Pay only 30% now. The rest later",
      "support_local_comunity": "Support local, encourage sustainability",
      "we_comply_with_all": "Make your planning lighter without excessive payments, pay only the 30% now to confirm your reservation, pay the rest closer to date.",
      "when_you_buy": "When buying at Outdoor Index you encourage 35+ local providers on quality & sustainable management."
    },
    "english": "English",
    "english_corto": "ENG",
    "lang-currency": "Language & Currency",
    "privacy_&_cookies": "Privacy",
    "secure": "All bookings processed through this website are protected by our key partners.",
    "spanish": "Español",
    "spanish_corto": "ESP",
    "statement": "Read our mission statement",
    "survey": "Survey",
    "terms_&_conditions": "Terms",
    "titles": {
      "activities": "Popular Activities",
      "book": "Book with Confidence",
      "destinations": "Popular Destination"
    },
    "usd": "USA Dollars (USD)"
  },
  "free": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "description": "Description",
    "important_information": "Good to know",
    "meeting_address": "Address",
    "meeting_point": "How to arrive",
    "more": "More...",
    "save_activity": "Save activity",
    "thumbnail_description": "Thumbnail description",
    "title": "Title",
    "useful_information": "Useful information",
    "what's_included?": "What's included?",
    "zone": "Zone"
  },
  "from": "From ",
  "go_to_home_page": "Go to homepage",
  "guest": "Guest",
  "guests": "Guests",
  "guidebook": {
    "download_our_guide_book": "Download our guide book and start your trip with Outdoor Index",
    "find_out_everything": "Find out everything about the Southern Chilean Patagonia."
  },
  "header": {
    "agency_panel": "Agency panel",
    "ask_us_a_question": "Contact us",
    "blog": "Blog",
    "config": "Config",
    "help": "FAQ",
    "itinerary": "Itinerary",
    "join_our_newsletter": "Join our newsletter",
    "log_in": "Log in",
    "orders": "Vouchers",
    "partner": "Partner",
    "sign_out": "Log out",
    "sign_up": "Sign up",
    "supplier_panel": "Supplier panel"
  },
  "header_cat": {
    "accommodation": "Accommodation",
    "excursions": "Excursions",
    "other": "Other",
    "recommendations": "Suggestions"
  },
  "help": "Frequently asked questions",
  "helpers": {
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}",
          "zero": "No %{entry_name} found"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "home": {
    "adventure_excursions": "Put more of yourself",
    "adventures_by_region": "Adventures by region",
    "download": "Download",
    "excursions": "Excursions",
    "explore_your_interests": "Explore your interests",
    "family": "Groups",
    "hard_adventurous": "Active",
    "lakes_and_rivers": "Lakes & rivers District",
    "luxury": "Sofisticate",
    "luxury_experience": "Live exclusive, just for you",
    "north_patagonia": "North Patagonia",
    "products": "products",
    "promotions": "Discounts & offers",
    "scientific": "Discovery",
    "scientific_excursions": "In deep touch with nature",
    "site_title": "Outdoor Index: Book adventure tours in Patagonia and Chile",
    "soft_adventurous": "Transformative",
    "south_patagonia": "South Patagonia",
    "suggestions": "Suggestions",
    "the_best_excursions": "Best excursions for this season",
    "things_to_do_in": "Suggestions in"
  },
  "home_collections": {
    "carousel_activities": "Adventures by activities",
    "carousel_region": "Adventures by region",
    "carousel_time_preference": "Adventures by time preference",
    "find_your_experience": "Find your experience",
    "home_reviews": "350+ people travel with Outdoor Index every year",
    "sub-landing": "Discover the best tourist options in Chile with Outdoor Index. Compare and explore a wide variety of programs from Atacama to Patagonia. Book today to live unique experiences!"
  },
  "hotels": {
    "double": "Double",
    "double_twins": "Double twin",
    "edit_your_booking": "Edit your booking",
    "room_reserved": "room reserved",
    "twin": "Twin"
  },
  "hours": "hours",
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x000055c87e9d3220@/var/www/html/viewnia/current/vendor/bundle/ruby/2.6.0/gems/rails-i18n-5.1.3/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "image_not_found": "Image not found",
  "in": "in",
  "key": "Ex: Elevation",
  "language": "Language",
  "last_name": "Last name",
  "location": "Location",
  "log_in": "Log In",
  "mail": {
    "leave_your_review": "%{user_name}, leave a review of your experience",
    "voucher": {
      "new_sold": "New sale %{reference_code}",
      "your_order_is_ready": "Thank you for your purchase, your order number is %{reference_code}",
      "your_sold_product": "Hi %{user_provider}, we have sold one of your experience"
    },
    "welcome_user_name": "%{user_name}, Welcome to Outdoor Index"
  },
  "meesage_captcha": "You must check the captcha",
  "meesage_sended": "Meesage Sended",
  "menu_welcome": "Welcome, %{user_name}",
  "modal": {
    "add": "Add",
    "add_reserves": "Number of guests",
    "add_rooms_for": "Add rooms for your",
    "add_to_itinerary": "Select",
    "check_in": "Check in",
    "check_out": "Check out",
    "child_guests": "Childs",
    "child_text": "Up to 12 years old",
    "date": "Date",
    "departure_time": "Departure:",
    "describe_your_experience": "Describe overall experience",
    "doubles": "Doubles",
    "doubles_twin": "2 twins",
    "ends": "Ends",
    "first_section": "Departure",
    "guest": "guests",
    "guest_text": "Ages 13 and older",
    "guests": "Guests",
    "is_not_resident": "Not resident in Chile",
    "is_resident": "Residents in Chile",
    "leave_your_review": "Leave your review",
    "load_earlier_dates": "Load earlier dates ...",
    "load_later_dates": "Load later dates ...",
    "next": "Next",
    "no_daily_quota": "There are not enough seats for this day",
    "per_person": " p/p",
    "second_section": "Passengers",
    "select": "Select",
    "select_date": "Select Date",
    "select_date_text": "Select an available date on the calendar and follow the steps to generate your reservation.",
    "spot_left": "Spot left",
    "start": "Start",
    "twins": "Twin",
    "wrong_name": "Wrong name or password"
  },
  "modal_manage_date": {
    "available_ammount": "Available amount",
    "cancel_date": "Cancel date",
    "date": "Date",
    "discount": "Add a discount",
    "manage_date": "Manage date",
    "month_form": {
      "accept": "Accept",
      "back": "Back",
      "confirm": "Save",
      "continue": "Continue",
      "delete_month": "Delete Month",
      "delete_month_disclaimer": "You are about to delete all departures for the following month, this action is <strong>irreversible</strong>.",
      "disclaimer_1": "You are about to edit <strong>an entire month</strong>, this action is <strong>hard to reverse</strong>.",
      "disclaimer_2": "Please double check your current calendar before you proceed, in order to avoid duplicates or other problems.",
      "edit_month": "Edit month",
      "final_notice_1": "Add a departure date for excursion <strong id='fn_title'></strong>, every <strong id='fn_week_days'></strong>  of <strong id='fn_month'></strong>.",
      "final_notice_2": "Each departure will start at <strong><span id='fn_starting_time_4i'></span>:<span id='fn_starting_time_5i'></span></strong>, have <strong id='fn_vacancies'></strong> and discount <strong id='fn_discount'></strong>.",
      "final_notice_header": "Please read carefully:",
      "month": "Month",
      "populate_month": "Populate Month",
      "vacancies": "vacantes",
      "week_days": "Week days"
    },
    "price": "Price",
    "save": "Save",
    "starting_time": "Starting time",
    "vacancies": "Vacancies"
  },
  "more": {
    "a_selection_of_the_best": "A selection made by Outdoor Index",
    "all_adventure_products": "All active experiences",
    "all_family_products": "All familiars - groups experiences",
    "all_lakes_and_rivers_products": "All Lakes & Rivers District products",
    "all_luxury_products": "All sofisticate experiences",
    "all_north_patagonia_products": "All North Patagonia products",
    "all_ota_products": "All OTA experiences",
    "all_promotions_products": "All discounts & offers experiences",
    "all_recommendations_in_puerto_natales": "All experiences in Puerto Natales",
    "all_recommendations_in_punta_arenas": "All experiences in Punta Arenas",
    "all_recommendations_in_zone": "All suggestions in %{zone_name}",
    "all_scientific_products": "All discovery experiences",
    "all_south_patagonia_products": "All South Patagonia products",
    "all_standard_products": "All transformative experiences",
    "other_activities_that": "Other activities that complement your experience",
    "the_best_products": "The best experiences in Outdoor Index"
  },
  "name": "Name",
  "newsletter": {
    "email_address": "Email Address",
    "sign_up_at": "Patagonia´s marketplace for adventure travel",
    "sign_up_now": "Sign up",
    "subscribe": "Subscribe",
    "subscribe_to_our": "Subscribe to our Newsletter",
    "view_preferences": "Preferences",
    "we_are_travel_and_growth": "We are nature based travel & growth, connecting you with local operators, informing and protecting our nature.",
    "we_value_your_privacy": "Sustainable, personalized and full of adventures for you to enjoy"
  },
  "notice": {
    "account_updated": "Account updated!",
    "availability_updated": "Availability updated",
    "bookings_made_for": "Your reservation was made successfully!",
    "changes_submitted": "Changes submitted!",
    "date_no_longer": "Date no longer available",
    "diabled": "disabled",
    "enabled": "enabled",
    "have_excursions": "The agency has excursions, assign them to another before deleting it",
    "invalid_request": "Invalid request. Please try again or contact us.",
    "language_updated": "Idioma actualizado!",
    "new_product_created": "New product created, waiting for approval",
    "product_was_disabled": "Product was disabled",
    "product_was_enabled": "Product was enabled",
    "profile_picture_updated": "Profile picture updated!",
    "reserves_added_for": " reserves added for ",
    "review_sent": "Review Sent!",
    "session_expired": "Session expired, please log in.",
    "settings_updated": "Settings updated!",
    "tourist_information_saved": "Your information has been saved",
    "updated_information": "Updated information",
    "was": "was",
    "your_account_has": "Your account has no agencies, please contact us"
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ondemand": {
    "explanation": "Exclusive tour! Contact our field specialist.",
    "label": "Private"
  },
  "optional": "Optional",
  "or": "or",
  "order": {
    "already_paid": "Order already paid",
    "cancel_order": "Cancel",
    "confirm_payment": "Confirm",
    "confirmed": "Confirmed",
    "edit": "Edit",
    "email_footer": "here is a reminder of your excursion. If you have any questions, feel free to contact us at info@outdoorindex.cl or +56991337099.",
    "email_title": "Excursion Reminder",
    "mail__hi": "Hi!",
    "mail_subject": "Outdoor Index Order N#",
    "mail_thank": "thanks for your purchase in Outdoor Index",
    "order_t": "Order",
    "promo": "Visit www.outdoorindex.cl for more excursions and programs.",
    "recover_order": "Recover",
    "see_purchase": "Review your purchases!",
    "send_reminder": "Reminder"
  },
  "partners": {
    "add_your_bank": "Add your bank information to establish the commercial link with Outdoor Index.",
    "banners": "Banners",
    "commercial_information": "Commercial information",
    "communicate_with_viewnia": "Communicate with Us",
    "copy_code": "Copy Code",
    "copy_the_link_you": "Copy the link you want to use from the Outdoor Index website and paste it into the box below. Your custom affiliate link will be generated ready for you to add to your website, email or social pages.",
    "copy_url": "Copy URL",
    "embed_this_code": "Embed this code on your website:",
    "generate": "Generate URL",
    "here_your_custom": "2) Here's your custom link.",
    "horizon": "2) Horizontal 90 x 728 px",
    "insert_link": "Insert link...",
    "numbers": "Numbers",
    "our_tutorial_video": "Our step by step tutorial video will help you to integrate banners or text links into your website or emails. Take a look and find out how.",
    "paste_the_viewnia": "1) Paste the Outdoor Index link here (including https://).",
    "play_video": "Play Video",
    "raised_money": "Raised Money",
    "references": "References",
    "request_money_transfer": "Request money transfer",
    "select_the_banner": "Select the Banner of your preference. The one that fits best with the design of your website.",
    "square": "1) Square 300 x 300 px",
    "text_links": "Text Links",
    "these_are_all": "These are all the people who have entered to Outdoor Index from your Banners or Text Links.",
    "these_are_the_numbers": "These are the numbers generated by your references from your website, social media or emails.",
    "this_is_a_detail": "This is a detail of the money you have earned with your references to Outdoor Index.",
    "vertical": "3) Vertical 300 x 600 px",
    "watch_our_step": "Watch our guide on how to use banners or text links",
    "wellcome_partners": "Wellcome Partner",
    "wellcome_partners_text": "In this section you will find several tools to start promoting outdoor activities in Patagonia. This will allow you to complement your own business offering that extra service that your audience will thank and remember forever.",
    "you_are_not": "You are not a partner"
  },
  "password": "Password (min 8 characters, letter, number)",
  "password_confirmation": "Password confirm",
  "paypal": {
    "description": "Outdoor Index: Paying order %{reference_code}"
  },
  "payu": {
    "description": "Outdoor Index: Paying order %{reference_code}"
  },
  "pdf": {
    "about_your_host": "ABOUT YOUR HOST",
    "according_to_the_reservation": "According to the reservation request by you, we confirm the following information:",
    "activity": "Activity",
    "address": "Address:",
    "allergies": "Allergies:",
    "applied": "APPLIED",
    "at_all_times": "at all times of the trip. Present it when",
    "child_guest": "Childs",
    "child_guest_price": "Child price",
    "childen_pax": "Childs",
    "client": "Client",
    "congratulations_agency_name": "Congratulations %{agency_name}",
    "congratulations_user_name": "Congratulations %{user_name}",
    "contact_us": "Contact us",
    "date_of_birth": "Date of birth:",
    "dear_user_name": "Dear %{user_name}",
    "departure": "Departure",
    "description": "Description",
    "document_number": "Document number:",
    "email": "Email",
    "emergency_contacts": "Emergency contacts",
    "emergency_number": "Emergency number:",
    "excursion": "ACTIVITY %{number}",
    "food_restrictions": "Food restrictions:",
    "from": "Service start date",
    "guest": "Guests",
    "guest_price": "Guest price",
    "guests": "Guests",
    "here_we_attach": "Here we attach all the information of your trip. In this voucher you will see the detail of your products, the contact of the suppliers, the signed waiver, as well as the receipt of your paid product (Paypal or PayU). Feel free to contact us if you have any questions.",
    "in_this_section_appear": "In this section you have the detail of the payment received by Outdoor Index. Remember to print this voucher and take it with you always. Thank you very much for choosing Outdoor Index",
    "initial_payment": "Initial payment",
    "medical_conditions": "Medical conditions:",
    "name": "Person in charge:",
    "next_payment": "NEXT PAYMENT (70%)",
    "no_resident": "No resident",
    "order_date": "Order date",
    "origin": "Origin:",
    "pax": "Pax",
    "payment_complete": "Payment complete",
    "payment_date": "Payment date",
    "payment_details": "PAYMENT DETAILS",
    "payment_method": "Payment method",
    "payment_type": "Payment type",
    "payments_detail": "Payments detail",
    "phone": "Phone",
    "print_this_voucher": "Print this voucher and take it with you",
    "problem_with_order": "Problems with your order?",
    "product_detail": "Product detail",
    "purchase_date": "Purchase date",
    "reference_code": "Reference code",
    "reservation_number": "Reservation number:",
    "resident": "Resident",
    "service_confirmation": "Service Confirmation",
    "starting_date": "Starting date",
    "starting_point": "Place",
    "starting_time": "Starting time",
    "tax": "Tax",
    "to": "Service end date",
    "total_order": "Order total:",
    "total_paid": "Total paid",
    "tourists": "Tourists",
    "unit_price": "Unit price",
    "unit_price_child": "Child unit price",
    "we_have_received": "We have received a new reservation request for your products. Below is the detail of this and the information of your passengers. From this moment you can contact your passengers directly or if you prefer, instruct Outdoor Index to do so. It is always advisable to send an email informing that the reservation was made successfully. You will stand out as a good provider. Remember to update the reserve quotas in your own systems so there are no problems of overcrowding. Good job!",
    "you_check": "you check in.",
    "your_guests": "Your guests",
    "your_suppliers": "Your suppliers"
  },
  "pending": "Pending",
  "phone": "Phone",
  "price": "Price",
  "privacy": {
    "content": {
      "contests": "<strong>VII) CONTESTS, SURVEYS AND OPINIONS:</strong>",
      "cookies": "<strong>V) COOKIES:</strong>",
      "from_time_to_time": "From time to time, Outdoor Index may sponsor or host contests, raffles, promotions and surveys to improve the offering of Outdoor Index’s products and services. If information is collected with the purpose of said commercial practices, and this comes to include contact information of the eventual winners or participants, such will be registered for the purposes indicated in this Privacy Policy and will be treated with the same conditions as informed. Outdoor Index may publish on the website results, testimonies, images, comments and information derived from the answers of surveys, as a method of promoting real testimonies from experiences of Outdoor Index services, identifying you by full name, photograph, comment, and nationality. You accept and agree that all opinions that you send us will be unique and exclusive property of Outdoor Index, and hereby irrevocably concede all the property rights regarding the totality of your opinions and denounce any moral right that you could have about said opinions.",
      "google_analytics": "<strong>VI) GOOGLE ANALYTICS:</strong>",
      "if_you_have_questions": "If you have questions about this Privacy Policy, you may contact us in the “Contact Us” section or at the following email address: <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "links": "<strong>VIII) LINKS TO WEB PAGES:</strong>",
      "means_of_obtainment": "<strong>IV) MEANS OF OBTAINMENT:</strong>",
      "password": "<strong>II) PASSWORD, USER PROFILE AND SOCIAL MEDIA:</strong>",
      "possibility": "<strong>I) POSSIBILITY OF CONTACTING:</strong>",
      "purpose": "<strong>III) PURPOSE OF DATA AND SECURITY:</strong>",
      "the_personal_data": "The personal data that we collect from you, with the purposes written above, is compiled in a personal way when you provide it to us directly via our User Profile on our web platform (<a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a>), when you provide the data to participate in promotions or offers, or when you hire a product or service through us. Moreover, we make it known that when you access our web Platform, we receive relevant information to your cookies that gives us access to obtain information such as your type of browser and operating system, the internet pages that you have visited recently, the links you have recently followed, the IP address of your computer, and the website that closed before entering our internet portal.",
      "this_page_contains": "This page contains links with other pages that have their own privacy and confidentiality policies. Because of this, we recommend that you visit these other web pages and carefully revise their practices and confidentiality policies.",
      "viewnia_com_asks_you": "Outdoor Index asks you to choose a user identification and a secure password. Remember that the password chosen is of personal use and must be safely guarded. Do not disclose or give use of your password to a third party because you may put the control of your personal information at risk and it presents the opportunity for transactions to be finalized in your name without your authorization. However, if for some reason your password should be compromised, you must change it immediately and inform us of the occurrence. Moreover, remember that you may access, update and correct your User Profile information at any time. On the same note, as part of the functionality of the Web Site and to provide a more personalized experience, you can link your social media accounts to fill in the login information from your social media account through the Web Site and/ or Mobile Version. You declare that you have the right to reveal your login information to your Social Media network to Outdoor Index and/ or grant access to Outdoor Index to your account, without this implying a breach by Outdoor Index of any of the terms and conditions that govern the use of the social network and without Outdoor Index being obliged to pay any fee or to respect any limitation of use imposed by the external service providers of the Social Network. By the act of granting access to Outdoor Index to any Social Network, you understand that Outdoor Index will access, make available and store all content that you have provided and stored on your Social Network so that it be available on the Web Site and/ or the Application.  In this way, it is easier to personalize our web page according to your preferences, connect you with your friends to discuss destinations and analyze and upgrade our outdoor excursion and activity services.",
      "we_utilize_cookies": "We utilize cookies to make our website as attractive as possible and to permit the use of certain functions. Cookies are small text archives that are stored on your computer.  The majority of the cookies that we utilize apply only for one session and are eliminated from the browser of your hard drive after logging out.  However, other cookies remain permanently on the computer to allow us to recognize when you return to the site. You have the possibility to impede the storage of cookies through the appropriate selection of setting on your browser. Nonetheless, during your visit to Outdoor Index, it is possible that some sections of the platform do not function as anticipated or that they do not function at all.",
      "we_utilize_the_web_analysis": "We utilize the web analysis service (“Google Analytics”) of the company Google Inc. (“Google”). The obtained information in this case is transferred to a Google server in EE.UU., where it is stored. Google will use this information to evaluate the client’s use of the webpage and to create reports about the web activities of the Outdoor Index website. Given the case, this data will be transferred by Google to third parties, as required by law. The same is applied in the case of the disclosure to third parties as a result of the commissioning of the same Google. Unless the installation of cookies is prevented by modifying the browser settings, limitations of use on the website will be faced. Through the use of our website you agree, in principle, to the use of your information by Google in the way previously described and for the purposes mentioned above.",
      "when_you_accept_this": "When you accept this Privacy Policy, you expressively authorize Outdoor Index to process your information, that is, register it; transmit your personal history to the final service(s) providers to process your reservations and/ or purchase applications; to send them publicity communications, promotions or offers and to collect statistics. In its user quality, you will have the opportunity to provide Outdoor Index related information of your travel habits, email address for the delivery of voucher, credit card billing information, and other personal information of interest. The data that you provide to us helps us to serve your planning needs and travel safety. The “extreme” nature of some of the excursions and activities that are advertised on our web platform Outdoor Index, requires us to possess this data. We will contact you at your email address to send you related messages about your purchase orders and trip preparation."
    },
    "foot": {
      "elimination": "<strong>X) ELIMINATION AND REVOCAL OF CONSENT:</strong>",
      "if_you_request": "If you request, we will give you at any time and free of charge, information about which of your information is stored on Outdoor Index. You will be able to, whenever you wish, from your user profile, eliminate or correct your data. You may also retract your consent for the collection, use and provision of your data. For any questions or comments, you may contact us at <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "newsletter": "<strong>XI) Newsletter</strong>",
      "viewnia_com_has_decided": "Outdoor Index has decided to register by default to the newsletter, to all the users at the time of sign up. This in order to keep users informed of the offers, promotions and recommended products that this platform offers. In the event that a user would like to unsubscribe, they can modify their preferences in the user panel, within their session or directly in the unsubscribe option located at the bottom of the emails."
    },
    "head": {
      "cookie_preferences": "<strong>Update cookies preferences</strong>",
      "responsible_for_the_protection": "<strong>Responsible for the protection of personal data:</strong> Garals Turismo Austral Limitada located at Blanco Encalada 722, Puerto Natales, Region of Magallanes, Chile.",
      "title": "Privacy Statement",
      "viewnia_com_values_its": "Outdoor Index values its users and providers and is hereby committed to protect your confidentiality. In performance of said commitment, Outdoor Index has developed this privacy policy that describes the policies and practices of Outdoor Index in what is referred to as the collection and disclosure of personal information on its web platform."
    },
    "last": {
      "keep_in_mind_that": "Keep in mind that when you reveal information in a public area of this page (for example: comments about excursions, electronic photographs with hashtags, etc.) you are making this information available to other members, users of the page and to the general public; which remains outside of the range of control of Outdoor Index. Please, remember the previous and be cautious with the information that you decide to disclose.",
      "public_information": "<strong>IX) PUBLIC INFORMATION:</strong>"
    }
  },
  "products": "Products",
  "products_search": {
    "activities": "Activities",
    "activities_sub_label": "Choose your favorite activities",
    "all_adventures": "All Adventures",
    "clear": "Clear Selection",
    "date": "Datex",
    "date_sub_label": "When do you want to go?",
    "in": "in",
    "regions": "Regions",
    "regions_sub_label": "What region you want to visit?",
    "search": "Search",
    "subtext": "Browse through all the available tours and excursions. Craft your itinerary, make reservations, and savor the wonders of our nature.",
    "time": "Time",
    "time_sub_label": "Select your time preference!",
    "tours": "tours"
  },
  "products_show_photos": "Show (%{n}) photos",
  "profiles": "Profiles",
  "promotions": {
    "coupon_apply": "Apply",
    "coupon_code_already": "Coupon code has already been applied",
    "coupon_code_destroy_confirm": "Are you sure you want to delete it?",
    "coupon_code_destroy_error": "Could not remove code",
    "coupon_code_destroy_ok": "Code removed successfully",
    "coupon_code_error": "Coupon code does not exist",
    "coupon_code_max": "Maximum one coupon per order",
    "coupon_code_ok": "Coupon code applied success",
    "coupon_form_palceholder": "Discount code",
    "coupon_have_a_coupon": "Do you have a discount coupon?"
  },
  "puerto_natales": "Puerto Natales",
  "puerto_williams": "Puerto Williams",
  "punta_arenas": "Punta Arenas",
  "ransack": {
    "all": "all",
    "and": "and",
    "any": "any",
    "asc": "ascending",
    "attribute": "attribute",
    "combinator": "combinator",
    "condition": "condition",
    "desc": "descending",
    "or": "or",
    "predicate": "predicate",
    "predicates": {
      "blank": "is blank",
      "cont": "contains",
      "cont_all": "contains all",
      "cont_any": "contains any",
      "does_not_match": "doesn't match",
      "does_not_match_all": "doesn't match all",
      "does_not_match_any": "doesn't match any",
      "end": "ends with",
      "end_all": "ends with all",
      "end_any": "ends with any",
      "eq": "equals",
      "eq_all": "equals all",
      "eq_any": "equals any",
      "false": "is false",
      "gt": "greater than",
      "gt_all": "greater than all",
      "gt_any": "greater than any",
      "gteq": "greater than or equal to",
      "gteq_all": "greater than or equal to all",
      "gteq_any": "greater than or equal to any",
      "in": "in",
      "in_all": "in all",
      "in_any": "in any",
      "lt": "less than",
      "lt_all": "less than all",
      "lt_any": "less than any",
      "lteq": "less than or equal to",
      "lteq_all": "less than or equal to all",
      "lteq_any": "less than or equal to any",
      "matches": "matches",
      "matches_all": "matches all",
      "matches_any": "matches any",
      "not_cont": "doesn't contain",
      "not_cont_all": "doesn't contain all",
      "not_cont_any": "doesn't contain any",
      "not_end": "doesn't end with",
      "not_end_all": "doesn't end with all",
      "not_end_any": "doesn't end with any",
      "not_eq": "not equal to",
      "not_eq_all": "not equal to all",
      "not_eq_any": "not equal to any",
      "not_in": "not in",
      "not_in_all": "not in all",
      "not_in_any": "not in any",
      "not_null": "is not null",
      "not_start": "doesn't start with",
      "not_start_all": "doesn't start with all",
      "not_start_any": "doesn't start with any",
      "null": "is null",
      "present": "is present",
      "start": "starts with",
      "start_all": "starts with all",
      "start_any": "starts with any",
      "true": "is true"
    },
    "search": "search",
    "sort": "sort",
    "value": "value"
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "region": {
    "lakes_and_rivers_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "north_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "south_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc."
  },
  "registration": {
    "all_vouchers": "All vouchers",
    "change_my_password": "Change my password",
    "change_your_password": "Change your password",
    "confirm_new_password": "Confirm new password",
    "confirm_password": "Confirm password",
    "current_password": "Current password",
    "edit_user": "Edit user",
    "email": "Email",
    "interests": "Interests (Choose one or more)",
    "lastname": "Last name",
    "name": "Name",
    "new_password": "New password (min 8 characters, letter, number)",
    "notification": "Notification Settings",
    "profile_picture": "Profile picture",
    "profiles": "Profiles (Choose one or more)",
    "registration_excerpt": "Manage your profile the way you want. Enable or disable your preferences and define your interests. This way you will be able to customize the contents you see on Outdoor Index.",
    "save_settings": "Save",
    "update_account": "Update account",
    "vouchers": "Vouchers"
  },
  "remember_me": "Remember me",
  "rental": {
    "add_images_(minimum_3)": "Add images (3 min)",
    "category": "Category",
    "description": "About the product",
    "meeting_address": "Address",
    "policies": "Policies",
    "price": "Price",
    "save_product": "Save product",
    "title": "Title",
    "vacancies": "Vacancies",
    "zone": "Zone"
  },
  "request": {
    "applicants": "Applicants",
    "comments": "Comments",
    "comments_placeholder": "Leave us your questions, doubts or anything you want to tell us before we contact you. We’ll reach you as soon as possible!",
    "contact_email": "Contact email",
    "contact_name": "Contact name",
    "contact_phone": "Contact phone",
    "email_footer": "This form has been sent to our executives. You will be contacted through the information you provide in the contact form. If there are any errors or observations in the form, please, send an email to info@outdoorindex.cl regarding the issue.",
    "email_title": "Excursion coordination application",
    "excursion": "Excursion",
    "from": "From",
    "greeting": "Hi! This information has been sent to your registered email with a copy to one of our agents. Someone will be contacting you soon.",
    "must_log_in": "You must log in before you can pay",
    "order_mail": {
      "disclaimer": "You'll be redirected to a payment window in Outdoor Index via secure connection.",
      "excursion": {
        "agency": "Local Operator",
        "title": "Excursion"
      },
      "footer": "Now you can go to the payments section through the button link.",
      "header": {
        "hi": "Hi! We have news about your excursion application!",
        "ready": "is available for payment.",
        "title": "Your application has been accepted by the Local operator. Please read the information bellow, and make sure everything is allright.",
        "your_order": "Your order N°"
      },
      "manual_order": {
        "observations": "Read the comments bellow from Outdoor Index agent!",
        "price": "Price",
        "price_clp": "Price CLP",
        "price_usd": "Price USD"
      },
      "pay": "Pay now",
      "request_details": "Your application details"
    },
    "phone": "Contact  phone",
    "product_required": "To request an order, you must login previously",
    "requester": "Requester",
    "singular": "Request",
    "submit": "Send application",
    "to": "To"
  },
  "request_form": {
    "contact_viewnia_subtitle": "Fill the form below to begin the process that will allow us to coordinate your excursion with our field specialist.",
    "title": "Request Excursion"
  },
  "reservations": {
    "(me)": "(me)",
    "a_list_of_all_your_reservations": "All your reservations",
    "actions": "Actions",
    "add_guest_details": "Add info pax",
    "assign_excerpt": "In this section you indicate which traveler is going to which excursion. That way we will have all the necessary information to provide you the best possible experience.",
    "assign_tourists": "3) Assign tourists",
    "assing_tourists": "Assign tourists",
    "cancel_date": "Cancel date",
    "cart_empty": "Your backpack is empty",
    "cart_subtitle": "List of all the product in your backpack",
    "cart_title": "Information of your trip",
    "confirmed_excerpt": "Thank You for Traveling with us!",
    "confirmed_excerpt_litle": "Check the voucher to see the details of your purchase.",
    "create_your_travel_group": "2) Travel group >",
    "departure_date": "Departure date",
    "details_of_purchase": "Detail of your purchase",
    "edit_your_reservation": "Edit your reservation",
    "go_to_checkout": "Continue",
    "itinerary_excerpt": "Here you can find a list of all your products. Make sure the information is correct before continuing to the next steps. Important: All times and dates are displayed in local time.",
    "list_of_products": "List of products",
    "many_few_tourists": "The tourists assigned does not match the number of reservations",
    "my_group": "My group",
    "new_checkout_excerpt": "Here you can find a listg of your products. Don’t forget to add the information of the guests in the Excursion in wich they participate. Before buying, check the number of reservations and their costs. Remember to read our Cancellation policy",
    "not_enough": "has not enough",
    "organize_your_itinerary": "1) Itinerary >",
    "pay_with": "Pay with",
    "people": "Pax:",
    "people_(including_you)": "People (Including you)",
    "price_chilean": "Total value residents in Chile:",
    "price_no_chilean": "Total value not residents in Chile:",
    "product_for": "Product for",
    "reservations_made": "#",
    "reserves": "Reserves",
    "see_details": "See details",
    "select_your_extras": "Select your extras:",
    "single_supplement": "Single supplement:",
    "team_member": "Team member",
    "too_many": "has too many",
    "total_price": "Total price",
    "tourists_assigned": "tourists assigned",
    "tourists_group": "In this section you have to add the information of all the people who will accompany you. It is very important that the attached information is as reliable as possible.",
    "update": "Update",
    "you_have_no_reservations": "You have no reservations"
  },
  "reset_password": "Restore password",
  "responsive": {
    "more": "More..."
  },
  "reviews": {
    "footer": "See on Trustpilot",
    "more": "More",
    "review": "Review",
    "reviewing_excursion": "Reviewing product",
    "thanks_for_submitting": "Thanks for submitting a review",
    "title": "Reviews"
  },
  "search": "Search",
  "searches": {
    "all_category_products": "All \"%{category_name}\" experiences",
    "all_category_products_no_result": "Sorry, no \"%{category_name}\" experiences for now",
    "all_products_in_zone": "All experiences in \"%{zone_name}\"",
    "all_products_in_zone_no_result": "Sorry, no experiences in \"%{zone_name}\"",
    "category_products_in_zone": "\"%{category_name}\" experiences in \"%{zone_name}\"",
    "category_products_in_zone_no_result": "Sorry, no \"%{category_name}\" experiences in \"%{zone_name}\"",
    "no_result_to": "Sorry, no result to \"%{category_name}\" in \"%{zone_name}\"",
    "please_select_category_or_zone": "Please select category or zone",
    "recommended_for_you": "Experiences to do in \"%{zone_name}\""
  },
  "select_multiple": "Select multiple",
  "select_one": "Select One",
  "select_two": "2 max",
  "send": "Send",
  "sidebar_single": {
    "contact_viewnia": "Contact Us",
    "disclaimer": "Secure your spot now by paying only <strong>30% of the total booking amount</strong>.",
    "from": "from",
    "if_you_are": "if you are new.",
    "log_in": "Log in",
    "no_dates_available": "No Dates Available",
    "or": "or",
    "price_for": "Per person",
    "select_dates": "Select date",
    "share": "Have questions about the excursion? Contact us here:",
    "sign_up": "Sign up",
    "to_make_a": "To make a reservation please "
  },
  "sign_up": "Sign Up",
  "signature": {
    "content": {
      "by_applying_my_electronic": "By applying my electronic signature to this agreement, I agree that my electronic signature is the legally binding equivalent of my handwritten signature on paper. I will not, at any future time, claim that my electronic signature is not legally binding or enforceable. By electronically signing and submitting this agreement, I 1) acknowledge that I have read and fully understand the terms of the agreement; 2) voluntarily agree to be bound by this agreement; and 3) certify that I am 18 years of age or older. My signature applies to all pages of this document."
    },
    "foot": {
      "apply_signature": "Apply now",
      "i_understand_that_i_will": "I understand that I will receive a Portable Document Format (PDF) version of this agreement after it is signed at the email address I have provided. To view the PDF document, I understand that I will need software that enables me to receive and access PDF files such as Adobe Reader software or other software capable of reading a PDF file. In order to print and retain a hard copy of this agreement, I understand that I will also need a printer connected to my computer. I understand that if I wish to sign a hard copy of this agreement instead of an electronic version, I must contact the party that requires my signature on this agreement directly.",
      "save": "Save",
      "yes_i_agree": "Yes, I agree."
    },
    "title": {
      "electronic_signature_authorization": "Electronic Signature Authorization:"
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "social_share_button": {
    "delicious": "Delicious",
    "douban": "Douban",
    "email": "Email",
    "facebook": "Facebook",
    "google_bookmark": "Google Bookmark",
    "google_plus": "Google+",
    "hacker_news": "Hacker News",
    "linkedin": "Linkedin",
    "odnoklassniki": "Odnoklassniki",
    "pinterest": "Pinterest",
    "qq": "Qzone",
    "reddit": "Reddit",
    "share_to": "Share to %{name}",
    "telegram": "Telegram",
    "tumblr": "Tumblr",
    "twitter": "Twitter",
    "vkontakte": "Vkontakte",
    "wechat": "WeChat",
    "wechat_footer": "Open your WeChat, click \"Discover\" button then click the \"Scan QR Code\" menu.",
    "weibo": "Sina Weibo",
    "whatsapp_app": "WhatsApp",
    "whatsapp_web": "WhatsApp",
    "xing": "Xing"
  },
  "soon": "Soon",
  "subtotal": "Subtotal:",
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "survey_page": {
    "description": "We would like to know your opinion about the service you received. Your feedback is very important to us.",
    "tilte": "Outdoor Index - After sales survey"
  },
  "terms": {
    "content": {
      "any_purchase_made": "90 days or more before the program's start date, a 100% refund will be issued. Between 89 and 70 days before the program's start date, only 50% of the amount paid will be refunded. Between 69 and 45 days before the program's start date, only 20% of the amount paid will be refunded. 44 days before the program's start date or less, no refunds will be given. In case of a no-show, no refunds will be issued.",
      "cancellation": "<strong>5) ANNULMENT OR CANCELLATION:</strong>",
      "example": "In case of cancellation:",
      "general_info": "<strong>1) GENERAL INFORMATION:</strong>",
      "in_the_case_of_annulment": "Reservations are non-transferable and nominative.",
      "methods": "<strong>3) PAYMENT METHODS:</strong>",
      "of_the_100%_paid": "Regarding the entry fees to Torres del Paine National Park, as they are purchased from an external entity that does not allow date or name modifications or cancellations, their cost will not be refunded under any circumstances.",
      "our_cancellation_system": "Our cancellation system is formed based on percentages between the day of purchase and the initial day of the reservation.",
      "payment": "<strong>2) PAYMENT & RESERVATION:</strong>",
      "purchase": "<strong>4) PURCHASE / RESERVATION PROCESS:</strong>",
      "purchase-one": "<strong>4.1) PAYMENT:</strong>",
      "the_payment_methods": "Outdoor Index uses two payment options validated internationally PayPal and WebPay. These will appear before your purchase request. When the consumer fills in data for their account or card and accepts the operation, then he/she authorizes the charge in the amount of the price and/or fee, the payment of the charge for our service and the charge for the use of the credit or debit card. If you make a payment in quotes, keep in mind: the charge for our service and the related tax on the use of the credit card is applied in the first quote; the interests, taxes, commissions or other charges that may generate purchases in quotes through credit cards will depend on the conditions that the consumer has agreed upon with each bank or credit broker.  We recommend that the consumer becomes informed of the conditions of use and of the interest associated with his/her credit card before making a payment in quotes.",
      "the_process_begins": "The process begins with the purchase generated by the user from our website. Validated the purchase (in our payment systems) the user will receive in his email the confirmation of the purchase and a pdf file with his Voucher. In this will be detailed all the information about te product and the data of the local tourism agency will be attached. In case of any inconvenience our customer service team will contact the tourist.",
      "the_quotes_related": "The quotes related to the use of the credit card will vary according to the type: If you use WebPay will be charged, 2,95% (chilean pesos) for the transaction, and 5.4% + $0.30USD if you purchase via PayPal.",
      "the_reservations": "Reservations made less than 70 days before the program's start date must be paid in full (100%) within the next 48 hours after confirmation by the executive. Once payment is received, no changes or cancellations can be made.",
      "the_total_cost": "The 30% of the total program cost will be requested to start the reservation process. The remaining 70% must be paid no later than 70 days before the program's start date, which is also the deadline for making changes to the reservation.",
      "viewnia_com_is_an_online": "Outdoor Index is an online excursion company that is contracted for the service of intermediation between local tour agencies and tourists who make use of the Outdoor Index web platform. We inform about the products that the tour providers offer, specifying their characteristics or conditions, carry out reservation and purchase requests, collect the values corresponding to the prices and we mediate in the case of doubts or in the search for solutions when a concern or inconvenience is presented. The tour providers (Tour Agencies) are who finally carry out the purchased product or service for the consumer on the <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong> platform.",
      "when_you_contract": "When you contract service through our agency, Outdoor Index, you must pay the equivalent of the sum of the price or fee of the tour service, plus relevant taxes on the use of a credit card (as applied). The payment can be made in two ways on our platform: paying 100% of the product´s value or paying 30% of the product to generate the reservation. We will request the remaining 70% of the payment within a variable timeframe, depending on the proximity of the requested date. It can range from 48 hours after confirmation of availability to 70 days in advance. It is important that prior to accepting the transaction, you pay close attention and carefully read the information regarding the valid pricing and cancellation or change policies described in each excursion or tour service offered on Outdoor Index, prior to instructing us to proceed with your purchase."
    },
    "foot": {
      "all_images_and_texts": "All texts and images loaded onto the website will be carefully reviewed by Outdoor Index staff to ensure they complete our standards. Photos with poor resolution, collages, watermarks, filters, texts, or that are dark, blurry, black and white, with people posing etc will not be permitted. It is prohibited to have texts with direct links or appear to have direct links to company websites (names, websites, emails etc) that bypass the commercial structure put forth by Outdoor Index. Outdoor Index reserves the right to modify or eliminate information loaded onto the platform that does not meet the requirements set forth.",
      "information_on_the": "<strong>8) INFORMATION ON THE WEBSITE:</strong>"
    },
    "head": {
      "conditions": "Terms of Service",
      "you_as_a_consumer": "You, as a consumer, have the right to be informed about the documentation that could be needed to carry out your trip according to plan.  Verify ahead of time if a passport, visa, vaccines, permission for minors or other documentation is required, whether it be to enter Chile or any other country you pass through in your travels.  If one of these is required and you do not possess it, complete the necessary steps to obtain it with anticipation."
    },
    "last": {
      "all_cancellation_requests": "All cancellation requests and eventual refund requests must be directed in writing to the email address <a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a>. Outdoor Index will mediate between the consumer and the service provider with the objective of evaluating the governing terms in the cases of annulments or cancellations.",
      "card": "<strong>7) MIGRATORY CARD:</strong>",
      "changes_covid": "<strong>5.2) BOOKING CHANGES:</strong>",
      "changes_covid_text": "It can be rescheduled at no cost with the option to change the date of stay until April of the same season or cancel a reservation up to 45 days before the check-in date, Individuals and Groups. All changes to reservations, outside the cancellation and payment deadlines, that generate a value lower than the original price, will not be refundable. If the reservation change was a higher value than the original price, this price difference must be paid.",
      "claims": "<strong>6) CLAIMS:</strong>",
      "covid": "<strong>5.1) CANCELLATIONS BY COVID-19:</strong>",
      "covid_text": "In the event of a resurgence due to COVID-19, which implies the closure of borders; closure of the Torres del Paine National Park, national quarantine or the client is infected with COVID-19 (presenting the proper medical certificate that accredits it), outside the established cancellation deadlines, Outdoor Index offers to reschedule the date of the reservation at no cost to a stay until April of the same season.",
      "migratory_card": "Foreign passengers: Remember that if you do not present your passport and migratory card (white paper issued by the police upon entering Chile), you will have to pay taxes.",
      "no_show": "<strong>5.3) NO SHOW:</strong>",
      "no_show_text": "Check-in is at 3:00 p.m. and check-out at 9:30 a.m. Guests must present themselves at the accommodations before 9:00 p.m. on the date indicated in the reservation. If you do not show up at the indicated time, Outdoor Index reserves the right to charge 100% of the value of the reservation, due to No Show, in addition, the reservation can be made available for sale again.",
      "the_consumer_will_be": "The consumer will be able to send a claim to the email <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>. It is recommended to attach immediately a copy of supporting documentation, explaining what occurred and formulating in clear and simple terms the desired solution.  In this way, we will be able to revise your case in a fast and efficient way. Outdoor Index will analyze the claim and give a response to the same email address of the consumer within a time frame of 7 working days to confirm the receipt of the claim and documentation. In the case of requiring a longer time frame to compile supporting documents or more information, the consumer will be informed, specifying the additional time needed.  The purpose of Outdoor Index is to exercise the service of intermediary between the parties with the objective of resolving as quickly as possible any problem or general concern regarding any of the products or services contracted through the <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong> web platform.",
      "the_previous_statement": "* In the event of cancellation or annulment of confirmed reservations, the service fee for intermediation and the tax paid for the bank transfer are not subject to reimbursement, as they correspond to a service effectively provided by Outdoor Index. The previous statement applies to all products and activities as long as the contrary is not stated in the description of the products in the “policy” item."
    }
  },
  "tierra_del_fuego": "Tierra del Fuego",
  "time": {
    "am": "am",
    "formats": {
      "daterangepicker": "MM/DD/YYYY",
      "default": "%m/%d/%Y",
      "full": "%a, %B %d / %Y",
      "long": "%B %d, %Y",
      "short": "%a, %b %e"
    },
    "pm": "pm"
  },
  "total": "Total to paid:",
  "total_final": "Final total",
  "total_only": "Total",
  "total_paid": "Total paid",
  "tourist": {
    "allergies": "Allergies",
    "date_of_birth": "Date of birth",
    "email": "Email",
    "emergency_contact": "Emergency contact",
    "food_restrictions": "Food restrictions",
    "hometown": "Hometown",
    "last_name": "Last name",
    "medical_conditions": "Medical conditions",
    "name": "Name",
    "nationality": "Nationality",
    "new_tourist_excerpt": "In this section you can add the information profile of all the people who will accompany you. It is very important that the attached information is as reliable as possible.",
    "no_reservations_to_assign": "No reservations to assign",
    "passport": "Passport",
    "pending": "Pending",
    "personal_info": "Personal info",
    "phone": "Phone",
    "ready": "Ready",
    "you_have_no_vouchers": "You have no active vouchers"
  },
  "tourists": {
    "first_create_group": "Ooops! Don`t forget to fill in the information of the passengers."
  },
  "uploader": {
    "drop_files_here": "Drop files here",
    "processing_dropped_files": "Processing dropped files...",
    "upload_a_file": "Upload a file"
  },
  "usd": "USD",
  "vacancies": "Vacancies",
  "value": "1300 mt",
  "value_included": "Ex: Bilingual guide",
  "view_more": "View more...",
  "view_more_excursions": "View more excursions...",
  "view_more_suggestions": "View more suggestions...",
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "next": "Next &rsaquo;",
      "previous": "&lsaquo; Prev",
      "truncate": "&hellip;"
    }
  },
  "vouchers": {
    "active_excerpt": "Here are the details of your vouchers. Please review and print them. It will be necessary to provide the vouchers, with the order number of the reservations.",
    "active_vouchers": "Active Vouchers",
    "activities": "Activities",
    "all_of_your_vouchers": "All of your vouchers",
    "complete": "Complete",
    "confirmed_excerpt": "Thank you for your purchase. We hope you have a beautiful trip. Do not forget to print your voucher and always take it with you. If you have any question or unexpected situation, do not hesitate to contact us.",
    "disclaimer": "In this PDF you have all the information about your product. Please check the document and if you have any questions, contact us. Enjoy your trip.",
    "error": "Something went wrong with your payment process, please try again later or contact us for other payment options.",
    "get_your_voucher": "Get your voucher",
    "home": "Home",
    "incomplete": "Incomplete",
    "order_number": "Order No:",
    "payment_error": "Payment error.",
    "payment_rejected": "Payment rejected",
    "print": "Print",
    "rejected": "PayU/Paypal has indicated that your payment was rejected, please check your details and try again, or contact us for other payment options.",
    "restart_payment": "Restart payment",
    "view": "View",
    "voucher": "Voucher",
    "was_partial_payment": "(2)"
  },
  "waiver": {
    "content": {
      "by_submitting_this_release": "By submitting this release form, I agree to release and hold harmless, Outdoor Index, its guides, suppliers, consultants, agents, and directors, for any damage or injuries, physical or mental, which might result from my participation in the Outdoor Index excursion.",
      "i_assume_full_responsibility": "I assume full responsibility for myself for bodily injury, death, loss of personal property, and expenses thereof, as a result of my negligence, or other risks, including but not limited to those caused by the trail, the terrain, the weather, my athletic and physical condition, other participants or guides.",
      "i_recognize_that_there": "I recognize that there is a significant risk in any adventure, sport, or activity associated with the outdoors. Knowing the inherent risks, dangers, and rigors involved in the activities, I certify that I am fully capable of participating in the activities. I understand that I will not be forced to participate in any element that I feel uncomfortable with. I further understand that my image, by photograph or video, may be used for promotional purposes.",
      "viewnia_com_excursions_you_are": "Outdoor Index excursions you are signing up for involves physically and emotionally demanding activities in an outdoor setting. It can sometimes include climbing, jumping, and other rigorous activities on natural and man-made structures that are on the ground or at low, medium, or high distances off the ground. You will be accompanied for a Guide and with others in your group. It is possible that you may be injured while participating in the excursions either because of your own conduct, conduct of others in the group, conduct of the guide, or the conditions of the premises. We want to make sure you understand the risks of injury before you decide to participate in the excursions. It is required that you read the following very carefully, make sure you understand it, and sign it before you begin participating in the www.Outdoor Index excursions."
    },
    "foot": {
      "i_acknowledge_that_i": "I ACKNOWLEDGE THAT I HAVE BEEN GIVEN THE OPPORTUNITY TO ASK QUESTIONS REGARDING ANY ASPECT OF THIS RELEASE FORM AND BY SUBMITTING THIS FORM I DO ACKNOWLEDGE THAT I HAVE READ COMPLETELY AND FULLY UNDERSTAND ALL ASPECTS OF THIS RELEASE FORM AND AGREE TO ITS TERMS IN ITS ENTIRETY."
    },
    "last": {
      "by_submitting_this_release": "By submitting this release form, i agree that if I do sustain physical injury or mental damage of any nature as a result of my decision to participate in the Outdoor Index excursions, I voluntarily agree to hold harmless the above-named parties from any liability therefore and that this release is binding on my heirs, personal representatives, and assigns."
    },
    "title": {
      "waiver_of_liability_agreement": "Waiver of liability agreement"
    }
  },
  "webpay": {
    "error_alert": "An error has occurred with Webpay, please check if your order is paid.",
    "error_dec": "There was an error in processing the payment, please try again. If the problem persists, contact us.",
    "error_title": "Transaction rejected"
  },
  "welcome": "Welcome to Outdoor Index!",
  "what": "What",
  "when": "When",
  "where": "Where",
  "zone": "Zone"
});

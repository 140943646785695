import { createTheme } from '@material-ui/core/styles';

const themeViewnia = createTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#212121',
    },
    tonalOffset: 0.2,
  },
});

export default themeViewnia

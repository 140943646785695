import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import {EXDefaultImage} from "components/utils/DefaultImage.js.erb";
import Skeleton from '@material-ui/lab/Skeleton';

function LandingImage(props){

    const [imageSRC, setImage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loadClass, setLoadClass] = useState("hidden");
    
    useEffect(() => {
        if(props.landing && props.landing.cover_url && props.landing.cover_url != "" ){
            setImage(props.landing.cover_url)
        }
    }, [props.landing]);

    function onError(){
        setImage(EXDefaultImage)
        setLoaded(true)
        setLoadClass("")
    }

    function onLoad(){
        setLoadClass("")
        setLoaded(true)
    }

    
  return (
            <a href={"/landings/" + (props.slug != null ? props.slug : props.landing?.id)} title="View Details" target="_blank">
                    <img className={"lazyload w-100 " + loadClass}
                        alt={props.landing?.title}
                        src={imageSRC}
                        onLoad={onLoad}
                        onError={onError}
                    />
                    {
                        !loaded &&

                        <Skeleton variant="rect" className="lazyload" height={(props.skeleton_height ? props.skeleton_height : 265)} />
                    }
            </a>
  
  );
}

export default LandingImage


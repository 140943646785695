import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'




export const EXDefaultImage = "//daes5xl8ji16n.cloudfront.net/production/assets/cover2-86d49ab227d3a9ededcefdd9a62f6ea1168c851525855600392e527706e95726.jpg";
export const DefaultImage = "//daes5xl8ji16n.cloudfront.net/production/assets/cover-c35ef046a70694da50f164f22c7254ea62d98cc5bf3302afa40325d5ba1f99e7.jpg";


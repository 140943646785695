

export const current_currency = Cookies.get("currency") || "usd";

export function money(object) {
	return (current_currency == 'clp' ? (object.clp_money) : (object.usd_money))
}

export function mkt_money(object) {
	return (current_currency == 'clp' ? (object.mkt_clp_price) : (object.mkt_usd_price))
}

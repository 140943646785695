import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import {EXDefaultImage} from "components/utils/DefaultImage.js.erb";
import Skeleton from '@material-ui/lab/Skeleton';
import { set } from 'date-fns';

function ExcursionImage(props){

    const [imageSRC, setImage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loadClass, setLoadClass] = useState("hidden");
    const [alt, setAlt] = useState("");
    useEffect(() => {
        if(props.product && props.product.excursion_images_cover_only.length > 0){
            setImage(props.image_format == "medium" ? props.product.excursion_images_cover_only_medium : props.product.excursion_images_cover_only_large)
        }else{
            setImage((props.image_format == "medium" ? props.product.excursion_images_medium : props.product.excursion_images_large ))
        }
        setAlt(props.product.excursion_image_alt)
    }, [props.product]);

    function onError(){
        setImage(EXDefaultImage)
        setLoaded(true)
        setLoadClass("")
    }

    function onLoad(){
        setLoadClass("")
        setLoaded(true)
    }

    function handleClick(){
        if(props.createSelectItemDataLayer){
            props.createSelectItemDataLayer()
        }
    }
    
  return (
        <figure>
            <a href={"/products/" + (props.product.slug != null ? props.product.slug : props.product.id)}  title="View Details" target="_blank" onClick={handleClick}>
                    <img className={"lazyload " + loadClass}
                        alt={alt}
                        src={imageSRC}
                        onLoad={onLoad}
                        onError={onError}
                    />
                    {
                        !loaded &&

                        <Skeleton variant="rect" className="lazyload" height={(props.skeleton_height ? props.skeleton_height : 265)} />
                    }
            </a>
        </figure>
  
  );
}

export default ExcursionImage


import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "about_us": {
    "content": {
      "from_the_inside_out": "Kilómetros y kilómetros recorridos de senderos fueron testigo de las incontables conversaciones sobre naturaleza, montañas, geografía, el carácter local y el modo de vida del “fin del mundo”. En todas estas conversaciones, con decenas de personas de todas partes del mundo, sin importar de dónde fueran, comencé a notar que siempre surgían las mismas preguntas. ¿Y qué más puedo conocer aquí? ¿Cómo puedo llegar allá? ¿Qué otra actividad se puede hacer? ¿Qué puedo hacer después de esto?",
      "in_this_way": "Así es como surgió la motivación necesaria y la problemática bien definida de lo que con el paso de los años se convertiría en Outdoor Index. Una empresa enfocada fuertemente en la necesidad de conectar al turista con la mejor experiencia en la naturaleza pensada para él, entregándole la más variada oferta de productos outdoors disponibles en cada lugar que visitara. Productos que son conscientes con su entorno y que activan el crecimiento de las comunidades de los lugares aledaños. Todo esto acompañado de un desarrollo tecnológico que pone en el centro al usuario, como una extensión del viaje mismo. Generando una experiencia lo más satisfactoria posible desde el momento en que viajas por el sitio."
    },
    "foot": {
      "viewnia_com_check_us_out": "<strong>Outdoor Index/ Puerto Natales, Patagonia. Chile.</strong>"
    },
    "head": {
      "about_us": "Sobre nosotros",
      "creating_a_quality_online": "“En todo paseo por la naturaleza uno recibe mucho más de lo que busca”. (John Muir)",
      "creating_a_quality_online_sub": "A los 29 años decidí dejar mi trabajo de diseñador gráfico y una depresión juvenil para aceptar un trabajo como guía turístico en la misteriosa Patagonia. De trekking no sabía nada, menos de turismo. En ese momento para mí solo consistía en ir a la montaña, caminar por el bosque o disfrutar paseos a caballo o en bicicleta. Todo era un poco ingenuo. Pero si entendía el principio básico de mi nuevo oficio de ser guía; Conectar: Es decir ser un facilitador que permitiera y amplificara la conexión de los turistas con el entorno, entregando la información necesaria y el soporte para que la experiencia sea lo más significativa posible. A veces la situación requería compartir una gran cantidad de información, otras veces solo compartir el silencio y dejar que el paisaje y la imponente naturaleza de la zona hiciera su trabajo y generara la magia."
    },
    "last": {
      "viewnia_com_believes_in_a": "<strong>Outdoor Index es un marketplace especializado en la promoción y venta de excursiones de aventura y actividades en la naturaleza en Chile, conectando a entusiastas del outdoor con experiencias significativas. Nos enfocamos en ofrecer experiencias locales y auténticas en destinos remotos, facilitando la búsqueda, información y reserva de actividades a través de una plataforma moderna y de calidad, que garantiza seguridad y facilidad de uso.</strong>",
      "viewnia_com_believes_in_a_10": "Una aventura puede ser una experiencia de cambio personal trascendental. Las mejores lecciones se obtienen de una crisis. Para algunos esa crisis o desafío puede ser una caminata medio día y para otros escalar en hielo. Para unos una bajada de río en kayak para otros una expedición de trekking de 8 días. Ayudarte a transformar tu viaje en una experiencia de crecimiento personal es una prioridad.",
      "viewnia_com_believes_in_a_11": "<strong>4. Activación de ecosistemas turísticos.</strong>",
      "viewnia_com_believes_in_a_12": "Al visitar un lugar como turista es importante tener en cuenta que tu visita no sólo representa una entrada de dinero para el guía con que harás una excursión. Es muy importante tener en cuenta que también aportarás por ejemplo comprando en el negocio de abarrotes del lugar, o también quizás echarás bencina. Visitarás una tienda de productos locales y souvenirs, y así tu visita se transforma en una activación completa de todo el ecosistema turístico.",
      "viewnia_com_believes_in_a_13": "Queremos constantemente ser activadores de estos ecosistemas, generar movimiento en todo el entorno y cadena turística de cada localidad, fomentando el consumo local y de pequeños negocios, generando exhibición y también promoviendo desde la colaboración. De esta manera promover la cultura local desde el desarrollo local para una conservación social endógena, de dentro hacia afuera.",
      "viewnia_com_believes_in_a_2": "<strong>1. Diversificar para proteger.</strong>",
      "viewnia_com_believes_in_a_3": "Siempre nos ha diferenciado nuestra amplia oferta de excursiones outdoor cubriendo cabalmente los lugares donde trabajamos. Fomentamos lugares poco conocidos para descongestionar los ya muy conocidos y así proteger atractivos que por su sobre demanda se han visto afectados, erosionados. Es una manera indirecta de conservación más muy importante y sobre todo trascendental en la experiencia de un viaje distinto.",
      "viewnia_com_believes_in_a_4": "¿Ayudas a conservar con un viaje distinto? La cantidad de rincones por contemplar en Patagonia es infinita, las oportunidades de encontrar ese momento WOW están a la vuelta de cada esquina, detrás de cada árbol. Tus decisiones y los lugares que visitas o dejas de visitar, son una forma de conservar importante, por eso siempre fomentaremos nuevos destinos, nuevas rutas, nuevos proveedores, nuevos lugares.",
      "viewnia_com_believes_in_a_5": "<strong>2. Ser agentes de cambio para un turismo consciente.</strong>",
      "viewnia_com_believes_in_a_6": "El turismo tiene efectos medioambientales, socioculturales y económicos. Se mueve e interactúa dentro de estos tres factores de manera activa. Nuestro propósito es ser un agente de cambio y educar hacia las prácticas responsables sobre estos temas.",
      "viewnia_com_believes_in_a_7": "Un agente de cambio es un rol activo, persistente y enfocado. El cambio hacia un turismo consciente es cambiar hacia decisiones responsables, tanto turistas como operadores. Un enfoque consciente permite valorizar un viaje desde el relato, permite aumentar tu capacidad de asombro, permite que tu viaje trascienda dándole un propósito y enseñanza, y eso, se queda por mucho más tiempo en ti que el mismo viaje.",
      "viewnia_com_believes_in_a_8": "<strong>3. Fomentar la aventura como una experiencia de cambio personal.</strong>",
      "viewnia_com_believes_in_a_9": "La interacción activa con la naturaleza es un tremendo recurso de desafíos y son los desafíos lo que nos empuja a ir más allá, a salir de nuestra zona de confort y testear nuestras capacidades, desiciones, nuestra cabeza y nuestra mente, para lograr visualizar y quizás integrar, una mejor versión de nosotros mismos."
    }
  },
  "accommodation": {
    "add_images_(minimum_3)": "Agregar imágenes (mínimo 3)",
    "category": "Categoría",
    "description": "Sobre el producto",
    "location": "Ubicación",
    "meeting_address": "Dirección",
    "our_location": "Nuestra ubicación",
    "policies": "Políticas de cancelación",
    "price": "Precio",
    "profiles": "Perfiles",
    "save_excursion": "Salvar producto",
    "title": "Título",
    "vacancies": "Cupos",
    "zone": "Zona"
  },
  "active_admin": {
    "access_denied": {
      "message": "No está autorizado/a a realizar esta acción."
    },
    "admin_users": "Administradores",
    "agencies": "Agencias",
    "agency": "Agencia",
    "any": "Cualquiera",
    "batch_actions": {
      "action_label": "%{title} seleccionados",
      "button_label": "Acciones en masa",
      "default_confirmation": "¿Seguro que quieres hacer esto?",
      "delete_confirmation": "Se eliminarán %{plural_model}. ¿Desea continuar?",
      "labels": {
        "destroy": "Borrar"
      },
      "selection_toggle_explanation": "(Cambiar selección)",
      "succesfully_destroyed": {
        "one": "Se ha destruido 1 %{model} con éxito",
        "other": "Se han destruido %{count} %{plural_model} con éxito"
      }
    },
    "blank_slate": {
      "content": "No hay %{resource_name} aún.",
      "link": "Añadir"
    },
    "blog": "Blog",
    "cancel": "Cancelar",
    "categories": "Categorias",
    "comments": {
      "add": "Comentar",
      "author": "Autor",
      "author_missing": "Anónimo",
      "author_type": "Tipo de autor",
      "body": "Cuerpo",
      "created_at": "Fecha de creación",
      "delete": "Borrar Comentario",
      "delete_confirmation": "¿Confirma que desea borrar este comentario?",
      "errors": {
        "empty_text": "El comentario no fue guardado, el texto estaba vacío."
      },
      "no_comments_yet": "No hay comentarios aún.",
      "resource": "Recurso",
      "resource_type": "Tipo de recurso",
      "title_content": "Comentarios (%{count})"
    },
    "comments_tab": "Comentarios",
    "covers": "Colecciones",
    "create_another": "Crear otro %{model}",
    "dashboard": "Inicio",
    "dashboard_welcome": {
      "call_to_action": "Para agregar secciones edite 'app/admin/dashboard.rb'",
      "welcome": "Bienvenido a Active Admin. Esta es la página de inicio predeterminada."
    },
    "delete": "Eliminar",
    "delete_confirmation": "¿Confirma que desea borrar este elemento?",
    "delete_model": "Eliminar %{model}",
    "details": "Detalles de %{model}",
    "devise": {
      "change_password": {
        "submit": "Cambiar mi contraseña",
        "title": "Cambie su contraseña"
      },
      "email": {
        "title": "Email"
      },
      "links": {
        "forgot_your_password": "¿Olvidó su contraseña?",
        "resend_confirmation_instructions": "Reenviar instrucciones de confirmación",
        "resend_unlock_instructions": "Reenviar instrucciones de desbloqueo",
        "sign_in": "Registrarse",
        "sign_in_with_omniauth_provider": "Conéctate con %{provider}",
        "sign_up": "Ingresar"
      },
      "login": {
        "remember_me": "Recordarme",
        "submit": "Iniciar Sesión",
        "title": "Iniciar Sesión"
      },
      "password": {
        "title": "Contraseña"
      },
      "password_confirmation": {
        "title": "Confirmar Contraseña"
      },
      "resend_confirmation_instructions": {
        "submit": "Reenviar instrucciones de confirmación",
        "title": "Reenviar instrucciones de confirmación"
      },
      "reset_password": {
        "submit": "Restablecer mi contraseña",
        "title": "¿Olvidó su contraseña?"
      },
      "sign_up": {
        "submit": "Registrarse",
        "title": "Registrarse"
      },
      "subdomain": {
        "title": "Subdominio"
      },
      "unlock": {
        "submit": "Reenviar instrucciones de desbloqueo",
        "title": "Reenviar instrucciones de desbloqueo"
      },
      "username": {
        "title": "Nombre de usuario"
      }
    },
    "download": "Descargar:",
    "dropdown_actions": {
      "button_label": "Acciones"
    },
    "edit": "Editar",
    "edit_model": "Editar %{model}",
    "empty": "Vacío",
    "excursion_images": "Imágenes Excursiones",
    "excursions": "Excursiones",
    "filters": {
      "buttons": {
        "clear": "Quitar Filtros",
        "filter": "Filtrar"
      },
      "predicates": {
        "contains": "Contiene",
        "ends_with": "Termina con",
        "equals": "Igual a",
        "from": "Desde",
        "greater_than": "Mayor que",
        "gteq_datetime": "Mayor o igual que",
        "less_than": "Menor que",
        "lteq_datetime": "Menor o igual que",
        "starts_with": "Empieza con",
        "to": "Hasta"
      }
    },
    "has_many_delete": "Eliminar",
    "has_many_new": "Añadir %{model}",
    "has_many_remove": "Quitar",
    "home": "Inicio",
    "home_collections": "Colecciones",
    "index_list": {
      "block": "Lista",
      "blog": "Blog",
      "grid": "Grilla",
      "table": "Tabla"
    },
    "kpis": {
      "abandoned_cart_subtitle": "Órdenes en tu mochila de viajes",
      "abandoned_cart_title": "Mochila de viajes abandonada",
      "conversion_chart_subtitle": "Indice de conversión el último año",
      "conversion_chart_title": "Gráfico de conversión",
      "conversion_last_year": "Indice de conversión el último año",
      "conversion_subtitle": "Actualizada a la fecha",
      "conversion_title": "Tasa de conversión",
      "countries": "Países",
      "countries_subtitle": "Los que más nos visitan",
      "country_anon": "Anónimo",
      "goals": "Objetivo",
      "last_day": "Día anterior",
      "new_orders_subtitle": "Registrados este mes",
      "new_orders_title": "Nuevas Órdenes",
      "new_users_subtitle": "Registrados el último mes",
      "new_users_subtitle_box": "Flujos para el día de hoy",
      "new_users_title": "Nuevos Usuarios",
      "new_users_title_box": "Usuarios de hoy",
      "no_sale_goal": "No se ha definido un objetivo de venta",
      "observed_dollar": "Valor dolar observado",
      "observed_dollar_subtitle": "Valor dolar definido por el Banco Central",
      "paid_orders_subtitle": "Registrados este mes",
      "paid_orders_title": "Órdenes Pagadas",
      "posts_viewed_subtitle": "Los más populares por ahora",
      "posts_viewed_title": "Post más vistos",
      "products_selled_subtitle": "En el último año",
      "products_selled_title": "Productos más vendidos",
      "products_viewed_subtitle": "Los más populares por ahora",
      "products_viewed_title": "Productos más vistos",
      "sale_goals_subtitle": "Rendimiento total de este mes",
      "sale_goals_title": "Objetivo de ventas",
      "show_more": "Ver más",
      "to": "hasta",
      "today_excursions_subtitle": "Turistas que hoy parten su tour.",
      "today_excursions_title": "Excursiones hoy",
      "users": "Usuarios",
      "users_month_subtitle": "Balance estadístico de usuarios mensuales.",
      "users_month_title": "Estadística mensual",
      "users_number": "Número de usuarios",
      "users_total_subtitle": "Registrados desde el inicio",
      "users_total_title": "Total Usuarios",
      "visitors": "Visitantes",
      "visits_today_subtitle": "Flujos para el día de hoy",
      "visits_today_title": "Visitas de hoy"
    },
    "landing": {
      "body": "Contenido",
      "cover": "Portada",
      "excerpt": "Aquí puedes completar toda la información necesaria para crear una Landing. Asegúrate siempre de que la foto de portada que subas tenga una buena resolución y sea atractiva para el turista. Recuerda brindar información clara y confiable.",
      "meta_description": "Descripción meta",
      "meta_title": "Título meta",
      "more_info": "Más info",
      "place": "Lugar",
      "product": "Excursión",
      "review": "Review",
      "save_landing": "Guardar landing",
      "tilte1": "Crear Landing",
      "title": "Título",
      "title2": "Meta",
      "title3": "Portada",
      "title4": "Reviews",
      "title5": "FAQ"
    },
    "landings": "Landings",
    "logout": "Salir",
    "macro_zones": "Macro Zonas",
    "mailer": "Creador mails",
    "main_content": "Por favor implemente %{model}#main_content para mostrar contenido.",
    "move": "Mover",
    "new_model": "Añadir %{model}",
    "newsletter_covers": "Portadas News",
    "next": "Siguiente",
    "notifications": "Notificaciones",
    "observed_dollar_update": "Actualizar",
    "orders": "Ordenes",
    "pages": "Páginas",
    "pagination": {
      "empty": "No se han encontrado %{model}",
      "entry": {
        "one": "registro",
        "other": "registros"
      },
      "multiple": "Mostrando %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> de un total de <b>%{total}</b>",
      "multiple_without_total": "Mostrando %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b>",
      "one": "Mostrando <b>1</b> %{model}",
      "one_page": "Mostrando <b>un total de %{n}</b> %{model}",
      "per_page": "Por página: "
    },
    "payment_methods": "Metodos de pago",
    "post": {
      "author_name": "Autor",
      "author_sm": "Autor red social",
      "content": "Contenido",
      "cover": "Portada",
      "label": "Etiqueta",
      "published": "Publicada",
      "recommended_posts": "Publicaciones recomendadas",
      "subtitle": "Subtítulo",
      "tags": "Etiquetas",
      "title": "Título",
      "unpublished": "No publicada"
    },
    "powered_by": "Funciona con %{active_admin} %{version}",
    "previous": "Anterior",
    "private": "Privado",
    "products": "Productos",
    "products_show_photos": "Ver (%{n}) fotos",
    "profiles": "Perfiles",
    "promotions": "Promociones",
    "properties": "Propiedades",
    "question_subjects": "Preguntas Ayuda",
    "questions": "Preguntas",
    "requests": "Solicitudes",
    "review": {
      "author": "Nombre",
      "body": "Texto review",
      "country": "País",
      "cover": "Cover",
      "excerpt": "Aquí puedes completar toda la información necesaria para crear una Reseña. Asegúrate siempre de que la foto de portada que subas tenga una buena resolución y sea correcta. Recuerda leer y verificar la información.season",
      "language": "Idioma",
      "rating": "Rating",
      "review_date": "Fecha de la reseña",
      "save_review": "Guardar reseña",
      "tilte1": "Crear Reseña",
      "title2": "Portada",
      "url": "Url"
    },
    "reviews": "Reseñas",
    "sale_goals": "Objetivos venta",
    "sales": "Ventas",
    "save_home_collections": "Guardar Colección",
    "scopes": {
      "all": "Todos"
    },
    "search_status": {
      "current_filters": "Filtros actuales:",
      "current_scope": "Alcance:",
      "headline": "Estado de la búsqueda:",
      "no_current_filters": "Ninguno"
    },
    "season": {
      "child_clp_price": "Niños en CLP",
      "child_usd_price": "Niños en dolares",
      "daily_quota": "Disponibilidad",
      "guardar_temporada": "Guardar temporada",
      "mkt_clp_price": "Precio CLP",
      "mkt_price": "Precio Marketing",
      "mkt_usd_price": "Precio USD",
      "name": "Nombre",
      "product": "Excursión",
      "single_supplement_clp_price": "CLP",
      "single_supplement_usd_price": "Dólares",
      "start_time": "Hora de inicio",
      "title_ages": "Edades",
      "title_daily_quota": "Cupo diario",
      "title_days": "Días",
      "title_prices_adults": "Precios adultos",
      "title_prices_kids": "Precios niños",
      "title_season": "1) Datos de la temporada",
      "title_single_supplement": "Suplemento individual",
      "valid_from": "Validez desde",
      "valid_until": "Validez hasta"
    },
    "seasons": "Temporadas",
    "settings": "Ajustes",
    "sidebars": {
      "filters": "Filtros",
      "search_status": "Estado de la búsqueda"
    },
    "status_tag": {
      "no": "No",
      "unset": "No",
      "yes": "Sí"
    },
    "suppliers": "Proveedores",
    "tags": "Etiquetas",
    "tourists": "Turistas",
    "unsupported_browser": {
      "headline": "Por favor tenga en cuenta que Active Admin no soporta versiones de Internet Explorer menores a 8.",
      "recommendation": "Recomendamos que actualice a la última versión de <a href=\"http://windows.microsoft.com/ie\">Internet Explorer</a>, <a href=\"https://chrome.google.com/\">Google Chrome</a>, o <a href=\"https://mozilla.org/firefox/\">Firefox</a>.",
      "turn_off_compatibility_view": "Si está usando IE 9 o superior, asegúrese de <a href=\"https://support.microsoft.com/es-es/help/17471\">apagar la \"Vista de compatibilidad\"</a>."
    },
    "users": "Usuarios",
    "view": "Ver",
    "visits": "Visitas",
    "zone": {
      "country": "País",
      "description": "Descripción",
      "name": "Nombre",
      "sort_order": "Orden",
      "state": "Región"
    },
    "zones": "Zonas"
  },
  "activerecord": {
    "attributes": {
      "active_admin/comment": {
        "author_type": "Tipo de autor",
        "body": "Cuerpo",
        "created_at": "Fecha de creación",
        "namespace": "Espacio de nombres",
        "resource_type": "Tipo de recurso",
        "updated_at": "Fecha de actualización"
      },
      "order": {
        "channel": "Canal:",
        "clp_charge": "Cargo CLP",
        "confirmed_at": "Compra:",
        "customer_phone": "Teléfono:",
        "departure_at": "Partida:",
        "email": "Email:",
        "extra": "Extra:",
        "instructions": "Instrucciones:",
        "language": "Idioma:",
        "manual_currency": "Moneda:",
        "manual_pax": "Nro Pax:",
        "manual_total": "Pagado:",
        "partial_payment": "Pago:",
        "payment_method": "Metodo:",
        "product": "Producto:",
        "product_name": "Producto",
        "usd_charge": "Cargo USD",
        "username": "Cliente:"
      },
      "season": {
        "fri_check": "Viernes",
        "mon_check": "Lunes",
        "name": "Nombre",
        "product": "Excursión",
        "sat_check": "Sábado",
        "start_time": "Hora de inicio",
        "sun_check": "Domingo",
        "thu_check": "Jueves",
        "tue_check": "Martes",
        "valid_from": "Válido desde",
        "valid_until": "Válido hasta",
        "wed_check": "Miercoles"
      },
      "user": {
        "confirmation_sent_at": "Confirmación enviada a",
        "confirmation_token": "Código de confirmación",
        "confirmed_at": "Confirmado en",
        "created_at": "Creado en",
        "current_password": "Contraseña actual",
        "current_sign_in_at": "Fecha del ingreso actual",
        "current_sign_in_ip": "IP del ingreso actual",
        "email": "Email",
        "encrypted_password": "Contraseña cifrada",
        "failed_attempts": "Intentos fallidos",
        "last_sign_in_at": "Fecha del último ingreso",
        "last_sign_in_ip": "IP del último inicio",
        "locked_at": "Fecha de bloqueo",
        "password": "Contraseña",
        "password_confirmation": "Confirmación de la contraseña",
        "remember_created_at": "Fecha de 'Recordarme'",
        "remember_me": "Recordarme",
        "reset_password_sent_at": "Fecha de envío de código para contraseña",
        "reset_password_token": "Código para restablecer contraseña",
        "sign_in_count": "Cantidad de ingresos",
        "unconfirmed_email": "Email no confirmado",
        "unlock_token": "Código de desbloqueo",
        "updated_at": "Actualizado en"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "La validación falló: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "No se puede eliminar el registro porque existen %{record} dependientes",
          "has_one": "No se puede eliminar el registro porque existe un %{record} dependiente"
        }
      }
    },
    "models": {
      "active_admin/comment": {
        "one": "Comentario",
        "other": "Comentarios"
      },
      "comment": {
        "one": "Comentario",
        "other": "Comentarios"
      },
      "season": "Temporada",
      "seasons": "Temporadas",
      "user": {
        "one": "Usuario",
        "other": "Usuarios"
      }
    }
  },
  "activities": {
    "bikes": "Bicicletas",
    "birdwatching": "Avistamiento Aves",
    "cruises": "Cruseros",
    "flyfishing": "Pesca con Mosca",
    "helicopter": "Helicoptero",
    "horseback": "Cabalgatas",
    "icehike": "Caminata en Hielo",
    "kayak": "Kayak",
    "mountaineering": "Montañismo",
    "multiactivity": "Multi Actividades",
    "navigations": "Navegaciones",
    "photosafari": "Safari Fotográfico",
    "rafting": "Rafting",
    "ski": "Ski",
    "snowshoes": "Raquetas de nieve",
    "trekking": "Caminatas"
  },
  "add_row": "Agregar",
  "address": "Dirección",
  "agencies": {
    "account_number": "Número de cuenta",
    "account_type": "Tipo de cuenta",
    "actions": "Acciones",
    "active": "Aprobada",
    "address": "Dirección",
    "agency": "Agencia",
    "agency_bank": "Datos de transferencia",
    "agency_info": "Información",
    "agency_information": "Información agencia",
    "agency_type": "Tipo de agencia",
    "apply_changes": "Aplicar cambios",
    "bank_name": "Nombre del banco",
    "change_availability": "Maneja tus fechas",
    "commercial_information": {
      "account_type": "Tipo de cuenta",
      "bank_account": "Cuenta",
      "bank_name": "Nombre banco",
      "commercial_name": "Nombre Comercial",
      "id_rut": "ID / Rut",
      "name": "Nombre",
      "self": "Información comercial"
    },
    "country": "País",
    "create_activity": "Crear actividad",
    "create_agency": "Crear nueva agencia",
    "create_excursion": "Crear excursión",
    "create_product": "Crear producto",
    "created_at": "Fecha creación",
    "date_range": "Modifica según rango de fechas",
    "duration": "Duración",
    "edit_excerpt": "Toda la información solicitada para crear el perfil del proveedor es de vital importancia para establecer el vínculo comercial con Outdoor Index. Por favor ingresa la información lo más precisa posible.",
    "english": "Traducir al ingles →",
    "excursions": "Excursiones",
    "for_booking": "para reservar",
    "holder_email": "Email del titular",
    "holder_id": "ID del titular",
    "holder_name": "Nombre del titular",
    "index_excerpt": "Aquí tienes una lista de todos tus productos. Puedes crear, editar y administrar las fechas de estos. Cada producto creado permanecerá en el estado \"Pendiente\" hasta que sea revisado por el equipo de Outdoor Index.",
    "list_title": "Lista de todas tus fechas",
    "manage_dates": "Fechas",
    "manage_dates_excerpt": "Aquí tienes un calendario para agregar todos tus productos. Puedes crear, editar y administrar las fechas de estos. Cada producto creado permanecerá en el estado \"Pendiente\" hasta que sea revisado por el equipo de Outdoor Index.",
    "max_words": "150 palabras",
    "name": "Nombre",
    "new_excursion_excerpt": "Aquí puedes completar toda la información necesaria para crear un producto. Siempre asegúrate de que las fotos que subas tengan buena resolución y sean atractivas para el turista. Recuerda dar información clara y confiable.",
    "no_dates": "Aún no hay fechas, por favor haz clic en el calendario para crear una.",
    "numbers": "Números",
    "owner": "Dueño",
    "pending_changes": "Pendiente",
    "person_in_charge": "Persona a cargo",
    "products": "Productos",
    "products_to_add": "Agregar productos",
    "products_to_remove": "Quitar productos",
    "review": "Revisión",
    "rooms_to_add": "Agregar habitaciones",
    "rooms_to_remove": "Quitar habitaciones",
    "set_price": "Definir precio",
    "spanish": "Translate to spanish →",
    "state": "Región",
    "supplier_info": "Información",
    "supplier_information": "Información proveedor",
    "supplier_review": "Reseña proveedor ",
    "update_info": "Guardar perfil",
    "website_url": "Dirección web",
    "zone": "Zona"
  },
  "alert": {
    "confirm_to": "Debes confirmar que no eres un robot.",
    "couldn't_save": "No se pudo guardar, inténtalo de nuevo más tarde.",
    "couldn't_send_review": "No se pudo enviar la reseña",
    "error": "La contraseña debe tener mínimo 8 caracteres, una letra y número",
    "invalid_action": "Acción inválida: la cantidad no puede ser menor que 0",
    "invalid_changes": "Cambios inválidos",
    "invalid_language": "Idioma inválido",
    "no_products_in_your_order": "No hay productos en tu orden",
    "not_enough_availability": "No hay suficiente disponibilidad para ese rango de fechas. Verifica otras acomodaciones o reserva menos unidades.",
    "please_sign_the_waiver": "Por favor firma el waiver con tu nombre.",
    "review_already_submitted": "Reseña ya enviada",
    "signature_and_tourist": "La firma y el nombre del turista deben ser idénticos.",
    "something_went_wrong": "Algo salió mal. Por favor intentalo de nuevo o contáctanos para ayudarte.",
    "you_must_agree_to": "Debes aceptar nuestros términos y condiciones"
  },
  "applicants": {
    "additional_info": "4) Información adicional",
    "applicants_number": "2) ¿Cuánta gente participará de la Excursión?",
    "applicants_phone": "1) Déjanos tus datos para contactarte",
    "dates_sub": "3) ¿Durante qué fechas quieres realizar la Excursión?",
    "email": "Email",
    "lastname": "Apellido",
    "name": "Nombre",
    "phone_placeholder": "Teléfono"
  },
  "back": "Volver",
  "back_to": "Volver a",
  "banner": {
    "do_you_need_travel_insurance": "¿Necesitas seguro de viaje?",
    "get_a_quote": "Obten cotización",
    "in_partnership_with_world": "Outdoor Index en asociación con World Nomads te brinda el mejor seguro disponible para la Patagonia chilena."
  },
  "blog": {
    "travel_nature_growth": "Viajes, naturaleza & crecimiento",
    "we_connect_you_with": "Te conectamos con destacados creadores de contenido, del mundo y múltiples disciplinas, para informarte y nutrirte sobre naturaleza, viajes y crecimiento, ¡leemos tus comentarios en rrss!"
  },
  "categories": {
    "general": "general",
    "general_category_search": "Búsqueda general de %{category_name}",
    "general_zone_search": "Búsqueda sugerencias en %{zone_name}",
    "other_excursions_to_do": "Otras experiencias para hacer en %{zone_name}",
    "recommended_for_you": "Experiencias para hacer en %{zone_name}",
    "search": "búsqueda"
  },
  "category": "Categoría",
  "charges": "Cargos:",
  "chat": {
    "begin": "Iniciar",
    "create_message": "Enviar mensaje",
    "enter_email": "Ingresa tu email",
    "enter_message": "Ingresa un mensaje...",
    "enter_your_name": "Ingresa tu nombre",
    "hi": "Hola",
    "messages": "Mensajes",
    "new-message": "Nuevo mensaje",
    "our_chat_service": "Nuestro servicio de chat está disponible de lunes a viernes desde las 10 am a las 6 pm (UTC/GMT -3). Si nos escribes en estos horarios te responderemos en unos minutos.",
    "user": "Usuario"
  },
  "chat_body": "Mensaje",
  "chat_btn": "Enviar",
  "chat_email": "Email",
  "chat_name": "Nombre",
  "chat_phone": "Teléfono",
  "chat_subject": "Asunto",
  "chat_subtitle": "Usualmente respondemos en unas horas",
  "chat_title": "¿Como podemos ayudarte?",
  "checkout": {
    "100_p": "100%",
    "100_per": "Pagado el 100%",
    "100_pers": "Pagado el 100%",
    "30_p": "30%",
    "30_per": "Pagado el 30%",
    "30_pers": "Pagado el 30%",
    "accept": "Acepto",
    "after_this_step": "Después de este paso, Outdoor Index se pondrá en contacto con todos los proveedores.",
    "all_transactions_have_an_extra": "Podrás pagar usando tu tarjeta de debito o crédito en las cuotas que pactes con tu banco. Outdoor Index recibirá el importe completo de la venta.",
    "cancel": "Cancelar",
    "choose_payment": "Elija el pago que desee",
    "complete_payment": "Pago completo",
    "confirm_checkout_excerpt": "Antes de continuar, comprueba que todo este correcto. Una vez que aceptes este pedido, no podrás cambiarlo.",
    "confirm_checkout_excerpt_2": "(Todavía puedes crear un nuevo pedido.)",
    "continue_shopping": "Continuar comprando",
    "disclaimer": "Sobre tu reserva",
    "do_checkout": "Realizar pedido",
    "everything_looks_fine": "Todo se ve bien",
    "get_a_refund_if": "Obten el reembolso de tu dinero si cancelas dentro de las 24 horas posteriores a la compra.",
    "i_hereby_accept": "Acepto los",
    "i_need_to_change_something": "Necesito cambiar algo",
    "important!": "Importante",
    "next_departure": "Próxima partida: 26/oct/2018",
    "not_available": "No disponible",
    "only_for_reservation": "Reserva",
    "pay_now": "Pagar",
    "payment_excerpt": "Antes de comprar, asegúrate de que todos los detalles de tus productos sean correctos. Verifica el número de reservas y los valores de estos. Recuerda leer nuestras políticas de cancelación.",
    "please_confirm_your_details": "Por favor confirma tu información",
    "providers_confirm_availability": "Cuando todos los proveedores confirmen tu disponibilidad, seras contactado.",
    "ready": "Listo",
    "review": "Reseña",
    "some_products_deleted": "Algunos productos han dejado de estar disponibles",
    "your_details_confirmation_and_payment": "Detalle de tus productos y pago",
    "your_itinerary": "Tu itinerario en detalle"
  },
  "checkout_bar": {
    "assign_tourists": "Asigna",
    "checkout": "Pago",
    "itinerary": "Itinerario",
    "tourists": "Grupo",
    "voucher": "Comprobante"
  },
  "child_guest": "Niño",
  "child_guests": "Niños",
  "clp": "CLP",
  "comments": {
    "body": "Escribe tu comentario aqui...",
    "comments": "Comentarios",
    "post_a_comment": "Publica un comentario!",
    "replies": "Respuestas",
    "reply": "Responder",
    "submit": "Enviar"
  },
  "continue": "Continuar",
  "coupon": "Cupón",
  "covid": {
    "are_we": "¿ESTAMOS HACIENDO RESERVAS?",
    "flights": "VUELOS",
    "for_more": "INFORMACIÓN EXPERTA",
    "for_more_expert": "Para mayor información sobre el COVID-19 y protocolos de seguridad para cuando viajes, recomendamos visitar los recursos entregados por el gobierno Chileno en los siguientes enlaces: <strong><a href=\"https://www.gob.cl/coronavirus/\">www.gob.cl/coronavirus</a></strong> - <strong><a href=\"https://www.sernatur.cl\">www.sernatur.cl</a></strong>",
    "for_the_latest": "Para la información más actualizada sobre tus vuelos, recomendamos contactar a tu aerolínea lo antes posible. Algunas compañías han cancelado viaje y/o establecido restricciones, protocolos y procesos de los que te deberías poner al tanto.",
    "hotels": "HOTELES",
    "if_for_some": "Si es que por alguna razón relacionada a COVID-19 se hace necesario re agendar tu viaje, podrás disponer de un 100% de crédito (del dinero ya pagado) para un viaje hasta abril del 2024, menos cualquier fee de hotel o de algún proveedor local que no sea posible de recuperar.</br></br>· Vamos ser insistentes en intentar recuperar la mayor cantidad posible de dinero teniendo en cuenta las circunstancias.</br></br>Para más detalles relacionados a las políticas de reserva de Outdoor Index durante tiempos de COVI-19 y para el futuro, puedes seguir leyendo nuestros <strong><a href=\"https://www.outdoorindex.cl/terms\">“Términos & Condiciones”</a></strong>.",
    "in_case_of_a": "En caso de que tu experiencia en Outdoor Index sea una experiencia multi-day o un programa, queremos comunicarte que los alojamientos han sido rigurosamente sanitizados y cuentan con protocolos establecidos de higiene más estrictos que nunca. Los organismos gubernamentales también han colaborado incisivamente con capacitaciones y fiscalización para una apropiada gestión acorde al contexto.",
    "in_case_of_your": "En caso de que tu experiencia en Outdoor Index cuente con transporte en vehículo en algún tramo de la excursión, al igual con los alojamientos, hemos respetado y seguido los protocolos exigidos por las agencias del gobierno, incluyendo la capacidad de personas por vehículo. Gel sanitizador estará al alcance, y mascarillas serán usadas durante los trayectos.",
    "keep_your": "MANTENTE INFORMADO",
    "make_your_reservation": "RESERVA CON CONFIANZA",
    "on_destiny": "EN EL DESTINO",
    "on_reaching_destiny": "Al llegar al destino y siempre, habrá un contacto directo de teléfono local con un encargado directo de Outdoor Index para ayudarte ante cualquier contexto. El referido de la empresa que te reciba seguirá los protocolos locales de bienvenida.",
    "our_here_described": "Las políticas aquí descritas respecto del COVID-19 están sujetas a cambio de acuerdo a las recomendaciones y requerimientos del gobierno chileno, del ministerio de salud, y las entidades gubernamentales que manejan temas relacionados al turismo. También procuramos ser líderes en el ámbito local buscando sinergia con los organismos locales y otras empresas. Estamos constantemente monitoreando el cambio de estados de fase de las zonas en las cuales nuestros operadores operan, como también actualizando nuestros protocolos hacia las mejores prácticas disponibles.",
    "our_main_policy": "Nuestra política en estos tiempos es la flexibilidad, por lo que queremos que puedas cancelar o re agendar tu viaje cerca de la fecha de inicio. Queremos que estés tranquilo respecto de este tema. Llevamos años trabajando en turismo y con mucho tiempo generando experiencia en Patagonia y el sur de Chile, conocemos los operadores de primera mano y nuestro prioridad es acompañarte desde el momento en que entras en nuestro sitio.</br></br>Si estas pensando viajar en 2022, no es muy temprano para empezar. De hecho, es mas importante que nunca por toda la gente re agendando sus viajes y los cupos limitados que tienen algunos de nuestros programas.",
    "read_about": "Informate sobre nuestra respuesta al <b>COVID-19</b>",
    "sub_text": "Sabemos que somos varios los que queremos volver a la naturaleza, el encierro que hemos vivido y estar limitados de lo que más nos gusta hacer sin duda hace soñar de tu próxima aventura. Queremos decirte que en Outdoor Index estamos listos para ti. Hemos gestionado e implementado protocolos propios y verificado las acciones de cada uno de nuestros proveedores para mantener a todos los involucrados; viajeros, guías y comunidades locales, seguros y sanos. Nuestros protocolos estarán siempre disponibles y te informaremos certeramente en el momento de tu viaje. Estaremos actualizando la información y te actualizaremos de los últimos sucesos cuando sea pertinente. Todo para cuando sea el momento apropiado; darte la bienvenida en tu próxima aventura.",
    "title": "Información Covid",
    "to_be_calm": "Para entregarte calma durante estos tiempos difíciles, hemos alineado nuestras política de cancelación con la de nuestros proveedores. Para cancelar tu viaje y recibir el 100% de tu dinero de vuelta tendrás que realizar la cancelación 45 días antes del comienzo del tour (previo certificado médico o comprobante que acredite la situación de anulación por motivos de Covid-19). De lo contrario Outdoor Index puede ofrecer las siguientes alternativas: - Reagendar la fecha de la reserva para una estadía hasta abril 2024. - Transferir la reserva a otro nombre.",
    "vehicles": "VEHÍCULOS",
    "we_sure_do": "Totalmente! Revisa nuestras excursiones aquí, o también ve directo hacia nuestros productos recomendados. Si quieres ideas puedes revisar nuestro <strong><a href=\"https://www.outdoorindex.cl/blog\">blog</a></strong> para inspirarte. Contactános para que un experto local te ayude desde ya en tu planificación, puedes escribirnos directamente a <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
    "what_happens_if": "¿QUÉ PASA SI ES QUE QUIERO CANCELAR MI VIAJE?",
    "what_if": "¿QUÉ PASA SI SE REAGENDA MI VIAJE POR RESTRICCIONES DE MOVILIDAD POR COVID-19?"
  },
  "create_an_account": "Crea tu cuenta",
  "currency": {
    "displaying_prices_in": "Cambio de moneda hecho!"
  },
  "date": {
    "abbr_day_names": [
      "Dom",
      "Lun",
      "Mar",
      "Mié",
      "Jue",
      "Vie",
      "Sáb"
    ],
    "abbr_month_names": [
      null,
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic"
    ],
    "day_names": [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado"
    ],
    "formats": {
      "daterangepicker": "DD/MM/YYYY",
      "default": "%d/%m/%Y",
      "long": "%d %B %Y",
      "moment": "%Y-%m-%d",
      "short": "%a %e %b"
    },
    "month_names": [
      null,
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "alrededor de 1 hora",
        "other": "alrededor de %{count} horas"
      },
      "about_x_months": {
        "one": "alrededor de 1 mes",
        "other": "alrededor de %{count} meses"
      },
      "about_x_years": {
        "one": "alrededor de 1 año",
        "other": "alrededor de %{count} años"
      },
      "almost_x_years": {
        "one": "casi 1 año",
        "other": "casi %{count} años"
      },
      "half_a_minute": "medio minuto",
      "less_than_x_minutes": {
        "one": "menos de 1 minuto",
        "other": "menos de %{count} minutos"
      },
      "less_than_x_seconds": {
        "one": "menos de 1 segundo",
        "other": "menos de %{count} segundos"
      },
      "over_x_years": {
        "one": "más de 1 año",
        "other": "más de %{count} años"
      },
      "x_days": {
        "one": "1 día",
        "other": "%{count} días"
      },
      "x_minutes": {
        "one": "1 minuto",
        "other": "%{count} minutos"
      },
      "x_months": {
        "one": "1 mes",
        "other": "%{count} meses"
      },
      "x_seconds": {
        "one": "1 segundo",
        "other": "%{count} segundos"
      },
      "x_years": {
        "one": "1 año",
        "other": "%{count} años"
      }
    },
    "prompts": {
      "day": "Día",
      "hour": "Hora",
      "minute": "Minutos",
      "month": "Mes",
      "second": "Segundos",
      "year": "Año"
    }
  },
  "days": "días",
  "delete": "Borrar",
  "departure": "Partida",
  "devise": {
    "already_have_an_account?": "¿Ya tienes una cuenta?",
    "confirmations": {
      "confirmed": "Tu cuenta ya ha sido confirmada. Has sido indentificado.",
      "new": {
        "resend_confirmation_instructions": "Reenviar instrucciones de confirmación"
      },
      "send_instructions": "Recibirás un correo electrónico en unos minutos con instrucciones sobre cómo reestablecer tu contraseña.",
      "send_paranoid_instructions": "Si tu correo electrónico existe en nuestra base de datos, recibirás un correo electrónico en unos minutos con instrucciones sobre cómo reiniciar tu contraseña."
    },
    "didn't_receive_confirmation_instructions?": "¿No recibiste las instrucciones de confirmación?",
    "didn't_receive_unlock_instructions?": "¿No recibiste las instrucciones de desbloqueo?",
    "don't_have_an_account?": "¿No tienes una cuenta?",
    "enter_email_associated": "Ingresa el correo electrónico asociado a tu cuenta y te enviaremos un email con un enlace para restablecer tu contraseña.",
    "facebook_sign_in": "Conectarse con Facebook",
    "failure": {
      "already_authenticated": "Ya estás identificado.",
      "inactive": "Tu cuenta aún no ha sido activada.",
      "invalid": "Correo o contraseña inválidos.",
      "invalid_token": "Cadena de autenticación invalida.",
      "last_attempt": "Tienes un intento más antes de que tu cuenta sea bloqueada.",
      "locked": "Tu cuenta está bloqueada.",
      "not_found_in_database": "Correo o contraseña inválidos.",
      "timeout": "Tu sesión ha expirado, por favor identifícate de nuevo para continuar.",
      "unauthenticated": "Necesitas iniciar sesión o registrarte para continuar.",
      "unconfirmed": "Debes de confirmar tu cuenta para continuar."
    },
    "forgot_your_password?": "¿Olvidaste tu contraseña?",
    "google_sign_in": "Conectarse con Google",
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirmar mi cuenta",
        "greeting": "¡Bienvenido %{recipient}!",
        "instruction": "Usted puede confirmar el correo electrónico de su cuenta a través de este enlace:",
        "subject": "Instrucciones de confirmación"
      },
      "email_changed": {
        "greeting": "¡Hola %{recipient}! ",
        "message": "Estamos contactando contigo para notificarte que tu email ha sido cambiado a %{email}.",
        "subject": "Email cambiado"
      },
      "password_change": {
        "greeting": "Hola %{recipient}!",
        "message": "Le estamos contactando para notificarle que su contraseña ha sido cambiada.",
        "subject": "Contraseña cambiada"
      },
      "reset_password_instructions": {
        "action": "Cambiar mi contraseña",
        "greeting": "¡Hola %{recipient}!",
        "instruction": "Alguien ha solicitado un enlace para cambiar su contraseña, lo que se puede realizar a través del siguiente enlace.",
        "instruction_2": "Si usted no lo ha solicitado, por favor ignore este correo electrónico.",
        "instruction_3": "Su contraseña no será cambiada hasta que usted acceda al enlace y cree una nueva.",
        "subject": "Instrucciones para reestablecer tu contraseña"
      },
      "unlock_instructions": {
        "action": "Desbloquear mi cuenta",
        "greeting": "¡Hola %{recipient}!",
        "instruction": "Haga click en el siguiente enlace para desbloquear su cuenta:",
        "message": "Su cuenta ha sido bloqueada debido a una cantidad excesiva de intentos infructuosos para ingresar.",
        "subject": "Instrucciones de desbloqueo"
      }
    },
    "omniauth_callbacks": {
      "failure": "No se te pudo autorizar de %{kind} debido a \"%{reason}\".",
      "success": "Identificado correctamente de %{kind}."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Cambiar mi contraseña",
        "change_your_password": "Cambie su contraseña",
        "confirm_new_password": "Confirme la nueva contraseña",
        "new_password": "Nueva contraseña"
      },
      "new": {
        "forgot_your_password": "¿Ha olvidado su contraseña?",
        "send_me_reset_password_instructions": "Envíeme las instrucciones para resetear mi contraseña"
      },
      "no_token": "No puedes acceder a esta página sino es por medio de un enlace para reestablecer de contraseña envíado a tu correo. Si accediste desde el enlace para reestablecer la contraseña, asegúrate de que la URL esté completa.",
      "send_instructions": "Recibirás un correo electrónico con instrucciones sobre cómo reestablecer tu contraseña en unos minutos.",
      "send_paranoid_instructions": "Si tu correo electrónico existe en nuestra base de datos, recibirás un enlace para reestablecer la contraseña en unos minutos.",
      "updated": "Tu contraseña se cambió correctamente. Has sido identificado.",
      "updated_not_active": "Tu contraseña se cambió correctamente."
    },
    "registrations": {
      "destroyed": "¡Adiós! Tu cuenta ha sido cancelada. Esperamos volver a verte pronto.",
      "edit": {
        "are_you_sure": "¿Está usted seguro?",
        "cancel_my_account": "Anular mi cuenta",
        "currently_waiting_confirmation_for_email": "Actualmente esperando la confirmacion de: %{email} ",
        "leave_blank_if_you_don_t_want_to_change_it": "dejar en blanco si no desea cambiarla",
        "title": "Editar %{resource}",
        "unhappy": "¿Disconforme?",
        "update": "Actualizar",
        "we_need_your_current_password_to_confirm_your_changes": "necesitamos su contraseña actual para confirmar los cambios"
      },
      "new": {
        "sign_up": "Registrarse"
      },
      "signed_up": "¡Bienvenido! Has sido identificado.",
      "signed_up_but_inactive": "Te has registrado correctamente. Pero no has podido iniciar sesión porque no has activado tu cuenta.",
      "signed_up_but_locked": "Te has registrado correctamente. Pero no has podido iniciar sesión porque tu cuenta está bloqueada.",
      "signed_up_but_unconfirmed": "Se te ha enviado un mensaje con un enlace de confirmación. Por favor visita el enlace para activar tu cuenta.",
      "update_needs_confirmation": "Actualizaste tu cuenta correctamente, pero necesitamos verificar tu nueva cuenta de correo. Por favor revisa tu correo electrónico y visita el enlace para finalizar la confirmación de tu nueva dirección de correo electrónico.",
      "updated": "Has actualizado tu cuenta correctamente.",
      "updated_but_not_signed_in": "Su cuenta se ha actualizado correctamente, pero como se cambió su contraseña, debe iniciar sesión nuevamente"
    },
    "sessions": {
      "already_signed_out": "Sesión finalizada.",
      "new": {
        "sign_in": "Iniciar sesión"
      },
      "signed_in": "Has ingresado iniciado sesión.",
      "signed_out": "Has cerrado la sesión."
    },
    "shared": {
      "links": {
        "back": "Atrás",
        "didn_t_receive_confirmation_instructions": "¿No ha recibido las instrucciones de confirmación?",
        "didn_t_receive_unlock_instructions": "¿No ha recibido instrucciones para desbloquear?",
        "forgot_your_password": "¿Ha olvidado su contraseña?",
        "sign_in": "Iniciar sesión",
        "sign_in_with_provider": "Iniciar sesión con %{provider}",
        "sign_up": "Registrarse"
      },
      "minimum_password_length": {
        "one": "(%{count} caractere como mínimo)",
        "other": "(%{count} caracteres como mínimo)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Reenviar instrucciones para desbloquear"
      },
      "send_instructions": "Recibirás un correo electrónico en unos minutos con instrucciones sobre cómo desbloquear tu cuenta.",
      "send_paranoid_instructions": "Si tu cuenta existe, recibirás un correo electrónico en unos minutos con instrucciones sobre cómo desbloquear tu cuenta.",
      "unlocked": "Tu cuenta ha sido desbloqueada. Por favor inicia sesión para continuar."
    }
  },
  "edit": "Editar",
  "email": "Email",
  "error": {
    "go": "ir al inicio",
    "improvements": "Si ves este mensaje es por que quizás escribiste mal la dirección web o por que estamos actualizando el sitio con nuevas funcionalidades y mejoras. Espera unos minutos y recarga la página nuevamente. Gracias!",
    "opps": "Puede que hayas escrito mal la dirección o que la página se haya movido.",
    "sorry": "Lo sentimos, esta página no existe.",
    "went_wrong": "Lo sentimos, pero algo salió mal."
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "debe ser aceptado",
      "already_confirmed": "ya ha sido confirmado, por favor intenta iniciar sesión",
      "blank": "no puede estar en blanco",
      "confirmation": "no coincide",
      "confirmation_period_expired": "necesita ser confirmado en %{period}, por favor vuelve a solicitarla",
      "empty": "no puede estar vacío",
      "equal_to": "debe ser igual a %{count}",
      "even": "debe ser par",
      "exclusion": "está reservado",
      "expired": "ha expirado, por favor vuelve a solicitarla",
      "greater_than": "debe ser mayor que %{count}",
      "greater_than_or_equal_to": "debe ser mayor que o igual a %{count}",
      "inclusion": "no está incluido en la lista",
      "invalid": "no es válido",
      "less_than": "debe ser menor que %{count}",
      "less_than_or_equal_to": "debe ser menor que o igual a %{count}",
      "model_invalid": "La validación falló: %{errors}",
      "must_contain_lower": "debe contener al menos 1 minúscula",
      "must_contain_number": " debe contener al menos un número y una letra",
      "must_contain_special": "debe contener un carácter especial",
      "must_contain_upper": "debe contener al menos 1 mayúscula",
      "not_a_number": "no es un número",
      "not_an_integer": "debe ser un entero",
      "not_found": "no se encontró",
      "not_locked": "no estaba bloqueado",
      "not_saved": {
        "one": "1 error impidió que este %{resource} fuese guardado:",
        "other": "%{count} errores impidieron que este %{resource} fuese guardado:"
      },
      "odd": "debe ser impar",
      "other_than": "debe ser distinto de %{count}",
      "present": "debe estar en blanco",
      "required": "debe existir",
      "taken": "ya está en uso",
      "too_long": {
        "one": "es demasiado largo (1 carácter máximo)",
        "other": "es demasiado largo (%{count} caracteres máximo)"
      },
      "too_short": {
        "one": "es demasiado corto (1 carácter mínimo)",
        "other": "es demasiado corto (%{count} caracteres mínimo)"
      },
      "wrong_length": {
        "one": "no tiene la longitud correcta (1 carácter exactos)",
        "other": "no tiene la longitud correcta (%{count} caracteres exactos)"
      }
    },
    "template": {
      "body": "Se encontraron problemas con los siguientes campos:",
      "header": {
        "one": "No se pudo guardar este/a %{model} porque se encontró 1 error",
        "other": "No se pudo guardar este/a %{model} porque se encontraron %{count} errores"
      }
    }
  },
  "excursions": {
    "add_extras": "Agregar extras",
    "add_images_(minimum_3)": "Agregar imágenes (mínimo 3)",
    "best_excursions_for_the_next_season": "Recomendados para esta temporada",
    "body": "Nuestro Itinerario",
    "body_old": "Nuestro Itinerario (antiguo)",
    "category": "Categoría",
    "clp_price": "Precio (en pesos)",
    "create_excursions": "Crear excursión",
    "days": {
      "one": "Día",
      "other": "Días",
      "zero": "Día"
    },
    "description": "Visión general",
    "details": "Detalles",
    "disclaimer": "Disclaimer",
    "duration": "Duración",
    "duration_days": "Días",
    "duration_hours": "Horas",
    "edit_excerpt": "Toda la información solicitada para crear el perfil del proveedor es de vital importancia para establecer el vínculo comercial con Outdoor Index. Por favor ingresa la información lo más precisa posible.",
    "extra_description": "Actividad",
    "faq": {
      "body": "Cuerpo",
      "sub_title": "Subtítulo",
      "title": "Título"
    },
    "faqs": "FAQs",
    "full_day": "Día completo",
    "half_day": "Medio día",
    "hours": {
      "one": "Hora",
      "other": "Horas",
      "zero": "Hora"
    },
    "images": "Imágenes",
    "included": "¿Qué está incluido?",
    "itinerary": {
      "body": "Cuerpo",
      "sub_title": "Subtítulo",
      "title": "Título"
    },
    "less": "Menos",
    "location": "Lugar",
    "locations": "Locación",
    "macro_zone": "Macro Zona",
    "meeting_address": "Dirección",
    "meeting_instruction": "Lugar de partida",
    "meta_description": "Meta descripción",
    "meta_title": "Meta título",
    "minimum_reservations": "N° pasajeros",
    "mkt_clp_price": "Valor base clp",
    "mkt_usd_price": "Valor base usd",
    "more": "Más",
    "multiple_days": "Varios días",
    "other_excursions_from_this_tour_company": "Otras experiencias de este proveedor",
    "our_itinerary": "Itinerario",
    "policies": "Políticas de cancelación",
    "price": "Precio (en dólares)",
    "price_per_reservations": "Precio por reserva",
    "prices": "Precios",
    "profiles": "Tipo de viaje",
    "save_excursion": "Salvar excursión",
    "time": "Tiempo",
    "title": "Título",
    "usd_price": "Precio (en dólares)",
    "useful_information": "Info de utilidad",
    "vacancies": "Cupos",
    "what's_included?": "Incluido",
    "what's_not_included?": "No incluido",
    "what_we'll_do": "Visión general",
    "zone": "Zona"
  },
  "footer": {
    "about_us": "Nosotros",
    "all_rights_reserved": "Todos los derechos reservados. Ninguna parte de este sitio puede ser reproducida sin el permiso correspondiente.",
    "clp": "Chilean pesos CLP $",
    "contact_us": "Contáctanos",
    "covid": "Covid",
    "edit_endorsement": "Outdoor Index tiene sede en Puerto Natales (Patagonia Chilena Sur) y cuenta con el apoyo de más de 40 proveedores.",
    "endorsement": {
      "by_entering_your": "Antes, durante y después para todas tus dudas y ayudarte en la planificación de tu próxima aventura.",
      "instant_booking": "Guías certificados 100% disponibles para tu asistencia",
      "payment_protection": "Confirma tu reserva pagando sólo el 30%",
      "support_local_comunity": "Apoya la economía local, fomenta enfoques sustentantes",
      "we_comply_with_all": "Planifica tu aventura sin stress, de forma más liviana pagando sólo una parte ahora y el resto más cerca de la fecha.",
      "when_you_buy": "Al comprar con nosotros fomentas más de 35 proveedores en su gestión sustentable y de calidad."
    },
    "english": "English",
    "english_corto": "ENG",
    "lang-currency": "Language & Currency",
    "privacy_&_cookies": "Privacidad",
    "secure": "Las reservas procesadas a través de este sitio web están protegidas por nuestros socios.",
    "spanish": "Español",
    "spanish_corto": "ESP",
    "statement": "Lee sobre nuestros pilares",
    "survey": "Encuesta",
    "terms_&_conditions": "Términos",
    "titles": {
      "activities": "Actividades populares",
      "book": "Reserva con Confianza",
      "destinations": "Destinos populares"
    },
    "usd": "USA Dollars USD $"
  },
  "formtastic": {
    "cancel": "Cancelar",
    "create": "Guardar %{model}",
    "no": "No",
    "required": "requerido",
    "reset": "Restablecer %{model}",
    "submit": "Aceptar",
    "update": "Guardar %{model}",
    "yes": "Sí"
  },
  "free": {
    "add_images_(minimum_3)": "Agregar imágenes (mínimo 3)",
    "description": "Descripción",
    "important_information": "Datos importantes",
    "meeting_address": "Dirección",
    "meeting_point": "¿Cómo llegar?",
    "more": "Más...",
    "save_activity": "Salvar actividad",
    "thumbnail_description": "Descripción foto portada",
    "title": "Título",
    "what's_included?": "¿Qué está incluido?",
    "zone": "Zona"
  },
  "from": "Desde ",
  "go_to_home_page": "Volver al inicio",
  "guest": "Viajero",
  "guests": "Viajeros",
  "guidebook": {
    "download_our_guide_book": "Descarga nuestro libro guía y comienza tu viaje con Outdoor Index",
    "find_out_everything": "Entérate de todo sobre la Patagonia Chilena Sur."
  },
  "header": {
    "agency_panel": "Panel agencia",
    "ask_us_a_question": "Contáctanos",
    "blog": "Blog",
    "config": "Ajustes",
    "help": "Ayuda",
    "itinerary": "Itinerario",
    "join_our_newsletter": "Unirte al newsletter",
    "log_in": "Entrar",
    "orders": "Vouchers",
    "partner": "Partner",
    "sign_out": "Salir",
    "sign_up": "Regístrate",
    "supplier_panel": "Panel proveedor"
  },
  "header_cat": {
    "accommodation": "Alojamientos",
    "excursions": "Excursiones",
    "other": "Otros",
    "recommendations": "Sugerencias"
  },
  "help": "Preguntas frecuentes",
  "helpers": {
    "select": {
      "prompt": "Por favor seleccione"
    },
    "submit": {
      "create": "Crear %{model}",
      "submit": "Guardar %{model}",
      "update": "Actualizar %{model}"
    }
  },
  "home": {
    "adventure_excursions": "Da más de ti mismo",
    "adventures_by_region": "Aventuras por región",
    "download": "Descarga",
    "excursions": "Excursiones",
    "explore_your_interests": "Explora tus intereses",
    "family": "Grupos",
    "hard_adventurous": "Activos",
    "lakes_and_rivers": "Región Lagos y Ríos",
    "luxury": "Sofisticados",
    "luxury_experience": "Vive exclusivo, solo para ti",
    "north_patagonia": "Patagonia Norte",
    "products": "productos",
    "promotions": "Promocionales",
    "scientific": "Descubrimiento",
    "scientific_excursions": "En profundo contacto con la naturaleza",
    "site_title": "Outdoor Index: Reserva tours de aventura en Patagonia y Chile",
    "soft_adventurous": "Transformador",
    "south_patagonia": "Patagonia Sur",
    "suggestions": "Sugerencias",
    "the_best_excursions": "Las mejores excursiones para ésta temporada",
    "things_to_do_in": "Sugerencias en"
  },
  "home_collections": {
    "carousel_activities": "Aventuras por actividades",
    "carousel_region": "Aventuras por región",
    "carousel_time_preference": "Aventuras por preferencia de tiempo",
    "find_your_experience": "Busca tu excursión",
    "home_reviews": "350+ personas al año viajan con Outdoor Index",
    "sub-landing": "Descubre las mejores opciones turísticas en Chile con Outdoor Index. Compara y explora una amplia variedad de programas desde Atacama hasta la Patagonia. ¡Reserva hoy para vivir experiencias únicas!"
  },
  "hotels": {
    "double": "Matrimonial",
    "double_twins": "Dos individuales",
    "edit_your_booking": "Edita tu reserva",
    "room_reserved": "habitación reservada",
    "twin": "Individual"
  },
  "hours": "horas",
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x000055c87e9d2910@/var/www/html/viewnia/current/vendor/bundle/ruby/2.6.0/gems/rails-i18n-5.1.3/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    },
    "transliterate": {
      "rule": {
        "Á": "A",
        "É": "E",
        "Í": "I",
        "Ñ": "N",
        "Ó": "O",
        "Ú": "U",
        "Ü": "U",
        "á": "a",
        "é": "e",
        "í": "i",
        "ñ": "n",
        "ó": "o",
        "ú": "u",
        "ü": "u"
      }
    }
  },
  "image_not_found": "Imagen no encontrada",
  "in": "en",
  "key": "Ej: Elevación",
  "language": "Lenguage",
  "last_name": "Apellido",
  "location": "Ubicación",
  "log_in": "Iniciar sesión",
  "mail": {
    "leave_your_review": "%{user_name}, deja una reseña de tu experiencia",
    "voucher": {
      "new_sold": "Nueva venta %{reference_code}",
      "your_order_is_ready": "Gracias por tu compra %{reference_code}",
      "your_sold_product": "Hola %{user_provider}, hemos vendido una de tus experiencias"
    },
    "welcome_user_name": "Bienvenido a Outdoor Index! %{user_name}"
  },
  "meesage_captcha": "Debe verificar el captcha",
  "meesage_sended": "Mensaje Enviado",
  "menu_welcome": "Bienvenido, %{user_name}",
  "modal": {
    "add": "Agregar",
    "add_reserves": "Número de reservas",
    "add_rooms_for": "Agrega tus habitaciones para",
    "add_to_itinerary": "Elegir",
    "check_in": "Llegada",
    "check_out": "Salida",
    "child_guests": "Niños",
    "child_text": "De a a 12 años",
    "date": "Fecha",
    "departure_time": "Salida:",
    "describe_your_experience": "Describe tu experiencia en general",
    "doubles": "Dobles",
    "doubles_twin": "2 simples'",
    "ends": "Término",
    "first_section": "Salida",
    "guest": "huéspedes",
    "guest_text": "Edad 13 años o más",
    "guests": "Adultos",
    "is_not_resident": "No residentes en Chile",
    "is_resident": "Residentes en Chile",
    "leave_your_review": "Deja tu reseña",
    "load_earlier_dates": "Cargar fechas anteriores ...",
    "load_later_dates": "Cargar fechas posteriores ...",
    "next": "Siguiente",
    "no_daily_quota": "No hay hay suficientes cupos para este día",
    "per_person": " c/u",
    "second_section": "Pasajeros",
    "select": "Elegir",
    "select_date": "Selecciona Fecha",
    "select_date_text": "Selecciona una fecha disponible en el calendario y sigue con los pasos para generar tu reserva.",
    "spot_left": "Cupos disponibles",
    "start": "Comienzo",
    "twins": "Simple",
    "wrong_name": "Nombre o contraseña equivocada"
  },
  "modal_manage_date": {
    "available_ammount": "Cantidad disponible",
    "cancel_date": "Cancelar fecha",
    "date": "Fecha",
    "discount": "Agrega un descuento",
    "manage_date": "Administrar fecha",
    "month_form": {
      "accept": "Aceptar",
      "back": "Volver",
      "confirm": "Guardar",
      "continue": "Continuar",
      "delete_month": "Borrar mes",
      "delete_month_disclaimer": "Estás a punto de eliminar todas las salidas del mes, esta acción es <strong>irreversible</strong>.",
      "disclaimer_1": "Estas a punto de editar <strong>un mes completo</strong>, esta acción es <strong>dificil de revertir</strong>.",
      "disclaimer_2": "Por favor revisa tu calendario actual antes de continuar para así evitar duplicados u otros problemas.",
      "edit_month": "Editar mes",
      "final_notice_1": "Añadir una fecha de salida para la excursión <strong id='fn_title'></strong>, cada <strong id='fn_week_days'></strong>  de <strong id='fn_month'></strong>.",
      "final_notice_2": "Cada salida comenzará a las <strong><span id='fn_starting_time_4i'></span>:<span id='fn_starting_time_5i'></span></strong>, tiene <strong id='fn_vacancies'></strong> y un descuento de <strong id='fn_discount'></strong>.",
      "final_notice_header": "Por favor lee con atención:",
      "month": "Mes",
      "populate_month": "Llenar mes",
      "vacancies": "cupos",
      "week_days": "Días de la semana"
    },
    "price": "Precio",
    "save": "Guardar",
    "starting_time": "Hora de partida",
    "vacancies": "Cupos"
  },
  "more": {
    "a_selection_of_the_best": "Una selección hecha por Outdoor Index",
    "all_adventure_products": "Todas las experiencias activas",
    "all_family_products": "Todas las experiencias familiares y de grupos",
    "all_lakes_and_rivers_products": "Todas las experiencias en la Región de los Lagos y Ríos",
    "all_luxury_products": "Todas las experiencias sofisticadas",
    "all_north_patagonia_products": "Todas las experiencias en Patagonia Norte",
    "all_ota_products": "Todas las experiencias OTA",
    "all_promotions_products": "Todas las experiencias en promoción",
    "all_recommendations_in_puerto_natales": "Todas las experiencias en Puerto Natales",
    "all_recommendations_in_punta_arenas": "Todas las experiencias en Punta Arenas",
    "all_recommendations_in_zone": "Todas las sugerencias en %{zone_name}",
    "all_scientific_products": "Todas las experiencias de descubrimiento",
    "all_south_patagonia_products": "Todas las experiencias en Patagonia Sur",
    "all_standard_products": "Todas las experiencias transformadoras",
    "other_activities_that": "Otras actividades que complementan tu experiencia",
    "the_best_products": "Las mejores experiencias en Outdoor Index"
  },
  "name": "Nombre",
  "newsletter": {
    "email_address": "Dirección Email",
    "sign_up_at": "La solución local para viajes de aventura en la Patagonia",
    "sign_up_now": "Regístrate",
    "subscribe": "Suscríbete",
    "subscribe_to_our": "Suscríbete a nuestro Newsletter",
    "view_preferences": "Preferencias",
    "we_are_travel_and_growth": "Viajes y Crecimiento basados en la Naturaleza. Te conectamos con proveedores locales, informando y protegiendo nuestra naturaleza.",
    "we_value_your_privacy": "Sostenible, personalizado y lleno de aventuras para que disfrutes"
  },
  "notice": {
    "account_updated": "Cuenta actualizada!",
    "availability_updated": "Disponibilidad actualizada",
    "bookings_made_for": "Tu reserva fue realizada con exito!",
    "changes_submitted": "¡Cambios enviados!",
    "date_no_longer": "La fecha ya no está disponible",
    "diabled": "deshabilitado",
    "enabled": "habilitado",
    "have_excursions": "La agencia tiene excursiones, asignelas a otra antes de eliminarla",
    "invalid_request": "Solicitud no válida. Por favor intentalo de nuevo o contáctanos para ayudarte.",
    "language_updated": "Language updated!",
    "new_product_created": "Nuevo producto creado, esperando aprobación",
    "product_was_disabled": "Producto deshabilitado",
    "product_was_enabled": "Producto habilitado",
    "profile_picture_updated": "Foto de perfil actualizada!",
    "reserves_added_for": " reservas añadidas para ",
    "review_sent": "¡Revisión enviada!",
    "session_expired": "La sesión expiró, por favor inicia sesión.",
    "settings_updated": "Ajustes actualizados!",
    "tourist_information_saved": "Tu información a sido guardada",
    "updated_information": "Información actualizada",
    "was": "fue",
    "your_account_has": "Tu cuenta no tiene agencias, contáctenos"
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 3,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "mil millones",
          "million": {
            "one": "millón",
            "other": "millones"
          },
          "quadrillion": "mil billones",
          "thousand": "mil",
          "trillion": {
            "one": "billón",
            "other": "billones"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n %"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ondemand": {
    "explanation": "!Excursión exclusiva! contáctate con nuestro especialista en terreno.",
    "label": "Privado"
  },
  "optional": "Opcional",
  "or": "o",
  "order": {
    "already_paid": "Esta orden ya ha sido pagada",
    "cancel_order": "Cancelar",
    "confirm_payment": "Confirmar",
    "confirmed": "Confirmado",
    "edit": "Editar",
    "email_footer": "a continuación te enviamos un recordatorio de tu excursión. Si tienes alguna pregunta, no dudes en contactarnos a info@outdoorindex.cl o al +56991337099.",
    "email_title": "Recordatorio de excursión",
    "mail__hi": "Hola!",
    "mail_subject": "Outdoor Index Orden N°",
    "mail_thanks": "gracias por tu compra en Outdoor Index",
    "order_t": "Orden",
    "promo": "Visita www.outdoorindex.cl para más excursiones y programas.",
    "recover_order": "Recuperar",
    "see_purchase": "Revisa tus compras",
    "send_reminder": "Recordar"
  },
  "partners": {
    "add_your_bank": "Agrega tu información bancaria para establecer el vínculo comercial con Outdoor Index.",
    "banners": "Banners",
    "commercial_information": "Información comercial",
    "communicate_with_viewnia": "Conversemos",
    "copy_code": "Copiar Código",
    "copy_the_link_you": "Copia el enlace que deseas usar del sitio web Outdoor Index y pégalo en el recuadro a continuación. Tu enlace personalizado se generará para que lo agregues a tu sitio web, redes sociales o correos electrónicos.",
    "copy_url": "Copiar URL",
    "embed_this_code": "Pega este código en tu sitio web:",
    "generate": "Generar URL",
    "here_your_custom": "2) Aquí está tu enlace personalizado.",
    "horizon": "2) Horizontal 90 x 728 px",
    "insert_link": "Inserta enlace...",
    "numbers": "Números",
    "our_tutorial_video": "Nuestros videos te ayudará a integrar banners o enlaces de texto en tu sitio web o correos electrónicos paso a paso. Echa un vistazo y descubre cómo.",
    "paste_the_viewnia": "1) Pega el enlace de Outdoor Index aquí (incluido https: //).",
    "play_video": "Reproducir Video",
    "raised_money": "Dinero Recaudado",
    "references": "Referencias",
    "request_money_transfer": "Pedir transferencia",
    "select_the_banner": "Selecciona el Banner de tu preferencia. El que mejor se adapta al diseño de tu sitio web.",
    "square": "1) Cuadrada 300 x 300 px",
    "text_links": "Enlaces de texto",
    "these_are_all": "Estas son todas las personas que han entrado a Outdoor Index desde tus Banners o Enlaces de texto.",
    "these_are_the_numbers": "Estos son los números generados por tus referencias desde tu sitio web, redes sociales o correos electrónicos.",
    "this_is_a_detail": "Este es un detalle del dinero que has ganado con tus referencias a Outdoor Index.",
    "vertical": "3) Vertical 300 x 600 px",
    "watch_our_step": "Revisa nuestra guía sobre cómo usar banners o enlaces de texto.",
    "wellcome_partners": "Bienvenido Partner",
    "wellcome_partners_text": "En esta sección podrás encontrar varias herramientas para comenzar a promocionar actividades outdoors en Patagonia. Esto te permitirá complementar tu propio negocio ofreciendo ese servicio extra que tu público agradecerá y recordará para siempre.",
    "you_are_not": "No estás registrado como partner"
  },
  "password": "Contraseña (min 8 caracteres, letra, número)",
  "password_confirmation": "Confirmación de la contraseña",
  "paypal": {
    "description": "Outdoor Index: Pagando la orden %{reference_code}"
  },
  "payu": {
    "description": "Outdoor Index: Pagando la orden %{reference_code}"
  },
  "pdf": {
    "about_your_host": "SOBRE TU ANFITRIÓN",
    "according_to_the_reservation": "De acuerdo con tu solicitud de reserva, confirmamos la siguiente información:",
    "activity": "Actividad",
    "address": "Dirección:",
    "allergies": "Alergias:",
    "applied": "APLICADO",
    "at_all_times": "en todo momento del viaje. Preséntalo al hacer",
    "child_guest": "Niños",
    "child_guest_price": "Precio Niño",
    "childen_pax": "Niños",
    "client": "Cliente",
    "congratulations_agency_name": "Felicidades %{agency_name}",
    "congratulations_user_name": "Felicidades %{user_name}",
    "contact_us": "Contáctanos",
    "date_of_birth": "Fecha de nacimiento:",
    "dear_user_name": "Estimado %{user_name}",
    "departure": "Partida",
    "description": "Descripción",
    "document_number": "Número de documento:",
    "email": "Email",
    "emergency_contacts": "Contacto de emergencia",
    "emergency_number": "Número de emergencia:",
    "excursion": "ACTIVIDAD %{number}",
    "food_restrictions": "Restricciones alimenticias:",
    "from": "Fecha de inicio del servicio",
    "guest": "Adultos",
    "guest_price": "Precio Adulto",
    "guests": "Viajeros",
    "here_we_attach": "Aquí te adjuntamos toda la información de tu viaje. En este voucher podrás ver el detalle de tus experiencias, el contacto de los proveedores, el waiver firmado, como también el comprobante de tu producto pagado (Paypal o PayU). Sientete libre de contactarnos ante cualquier duda.",
    "in_this_section_appear": "En esta seccion aparece el detalle del pago recibido por Outdoor Index. Recuerda imprimir este voucher y llevarlo siempre contigo. Muchas gracias por preferir Outdoor Index",
    "initial_payment": "Pago inicial",
    "medical_conditions": "Condición medica:",
    "name": "Persona a cargo:",
    "next_payment": "SIGUIENTE PAGO (70%)",
    "no_resident": "No Residente",
    "order_date": "Fecha de orden",
    "origin": "Origen:",
    "pax": "Pax",
    "payment_complete": "Pago completo",
    "payment_date": "Fecha de pago",
    "payment_details": "DETALLES DE PAGO",
    "payment_method": "Medio de pago",
    "payment_type": "Tipo de pago",
    "payments_detail": "Detalles de pagos",
    "phone": "Teléfono",
    "print_this_voucher": "Imprime este voucher y llevalo contigo",
    "problem_with_order": "¿Problemas con su orden?",
    "product_detail": "Detalle del producto",
    "purchase_date": "Fecha compra",
    "reference_code": "Nro reserva",
    "reservation_number": "Número reserva:",
    "resident": "Residente",
    "service_confirmation": "Confirmación de Servicio",
    "starting_date": "Fecha servicio",
    "starting_point": "Lugar",
    "starting_time": "Hora servicio",
    "tax": "Tax",
    "to": "Fecha de término del servicio",
    "total_order": "Total orden:",
    "total_paid": "Total pagado",
    "tourists": "Turistas",
    "unit_price": "Precio unitario",
    "unit_price_child": "Precio unitario niño",
    "we_have_received": "Hemos recibido una nueva solicitud de reserva de tus productos. A continuación se especifica el detalle de esta y la información de tus pasajeros. Desde este momento puedes contactar a tus pasajeros directamente o si prefieres, instruir a Outdoor Index para que lo haga. Siempre es recomendable enviar un mail informando que la reserva fue realizada con éxito. Te destacarás como un buen proveedor. Recuerda actualizar los cupos de reserva en tus propios sistemas para que no hayan problemas de sobrecupos. Buen trabajo!",
    "you_check": "tu check in.",
    "your_guests": "Tus viajeros",
    "your_suppliers": "Tus proveedores"
  },
  "pending": "Pendiente",
  "phone": "Teléfono",
  "price": "Precio",
  "privacy": {
    "content": {
      "contests": "<strong>VII) CONCURSOS, ENCUESTAS Y OPINIONES:</strong>",
      "cookies": "<strong>V) COOKIES:</strong>",
      "from_time_to_time": "De tiempo en tiempo, Outdoor Index puede patrocinar o ser anfitrión de concursos, sorteos, promociones y encuestas para mejorar el ofrecimiento de productos y servicios de Outdoor Index. Si se recolectare información a propósito de dichas prácticas comerciales, y ésta llegare a incluir datos de contacto de los eventuales ganadores o usuarios, aquella se registrará para los fines indicados en esta Política de Privacidad y será tratada en las mismas condiciones informadas. Outdoor Index podrá subir resultados, testimonios, imágenes, comentarios e informaciones derivadas de las contestaciones de las encuestas en su sitio web, a modo de promocionar testimonios reales de experiencias de los servicios de Outdoor Index, identificándolo a Usted con nombre completo, foto, comentario y nacionalidad. Usted acepta y está de acuerdo en que todas las opiniones que nos envíe serán propiedad única y exclusiva de Outdoor Index, y por el presente cede de manera irrevocable a Outdoor Index, todos sus derechos de propiedad sobre la totalidad de sus opiniones y renuncia a cualquier derecho moral que pudiera tener sobre dichas opiniones.",
      "google_analytics": "<strong>VI) GOOGLE ANALYTICS:</strong>",
      "if_you_have_questions": "Si tiene preguntas sobre esta Política de Privacidad, puede contactarnos en la sección de “Contáctenos” o en la siguiente dirección de correo electrónico <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "links": "<strong>VIII) ENLACES A PÁGINAS WEB:</strong>",
      "means_of_obtainment": "<strong>IV) MEDIOS DE OBTENCIÓN:</strong>",
      "password": "<strong>II) CONTRASEÑA, PERFIL DE USUARIO Y REDES SOCIALES:</strong>",
      "possibility": "<strong>I) POSIBILIDAD DE CONTACTO:</strong>",
      "purpose": "<strong>III) FINALIDAD DE LOS DATOS Y SEGURIDAD:</strong>",
      "the_personal_data": "Los datos personales que recabamos de usted, con los fines antes descritos, son recopilados de manera personal, cuando usted nos los proporciona directamente por vía de nuestro Perfil de Usuario en nuestra Plataforma web (<a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a>), cuando los proporciona para participar en promociones, ofertas o cuando contrata con nosotros algún producto o servicio. Además le hacemos presente que cuando accede a nuestra Plataforma web recibimos información referente a sus cookies que nos da acceso a obtener información como su tipo de navegador y sistema operativo, las páginas de Internet que ha visitado recientemente, los vínculos que recientemente ha seguido, la dirección IP de su computadora y el sitio que cerró antes de entrar a nuestro portal en Internet.",
      "this_page_contains": "Esta página tiene vínculos (links) con otras páginas que tienen sus propias políticas de privacidad y confidencialidad. Por ello le recomendamos que si Ud. visita esas otras páginas web revise cuidadosamente sus prácticas y políticas de confidencialidad.",
      "viewnia_com_asks_you": "Outdoor Index le solicita elegir una identificación de usuario y una contraseña segura. Recuerde que la contraseña que escoja es de uso personal y que debe custodiarla. No la entregue o facilite a terceros, porque pone en riesgo el control de su información personal y se expone a transacciones llevadas a cabo en su nombre y sin su autorización. Por lo tanto, si por cualquier razón su contraseña llega a estar comprometida, Ud. debe de inmediato cambiarla e informarnos de lo sucedido. Recuerde además que Ud. puede acceder, actualizar y corregir su información del Perfil de Usuario en cualquier momento. A su vez, como parte de la funcionalidad del Sitio Web y para brindar una mejor experiencia personalizada, puede enlazar su cuenta con Redes Sociales proporcionando la información de inicio de sesión de su cuenta de Red Social a través del Sitio Web y/o Versión Móvil. Usted declara que tiene derecho a revelar la información de inicio de sesión de su Red Social a Outdoor Index y/o conceder el acceso a Outdoor Index a su cuenta, sin que ello suponga un incumplimiento por su parte de ninguno de los términos y condiciones que rigen el uso que Usted hace de la correspondiente Red Social y sin que Outdoor Index quede obligado a pagar ninguna tasa o a respetar ninguna limitación de uso impuesta por los proveedores externos de servicios de la Red Social. Por el hecho de conceder a Outdoor Index acceso a cualquier Red Social, Usted entiende que Outdoor Index accederá, hará disponible y almacenará todos los contenidos que Usted haya proporcionado y almacenado en su Red Social para que esté disponible en el Sitio Web y/o la Aplicación. De esa forma, es más fácil personalizar nuestra página web según tus preferencias, conectarte con tus amigos para hablar de destinos y analizar y mejorar nuestros servicios de excursiones y actividades al aire libre.",
      "we_utilize_cookies": "Utilizamos cookies para hacer nuestra web lo más atractiva posible y para permitir el uso de ciertas funciones. Las cookies son pequeños archivos de texto que se almacenan en su ordenador. La mayoría de las cookies que utilizamos se aplican solo para una sesión y se eliminan del navegador de su disco duro después de finalizar la sesión. Sin embargo, otras cookies permanecen permanentemente en el ordenador para permitirnos reconocer cuando usted vuelve a visitar el sitio. Usted tiene la posibilidad de impedir el almacenamiento de cookies mediante la selección de la configuración apropiada en su navegador. Sin embargo, durante su visita a Outdoor Index, es posible que algunas secciones de la plataforma no funcionen de la manera prevista o no funcionen en absoluto.",
      "we_utilize_the_web_analysis": "Utilizamos el servicio de análisis web (\"Google Analytics\") de la compañía, Google Inc. (\"Google \"). La información obtenida en este caso se transfiere a un servidor de Google en EE.UU, donde queda almacenada. Google usará esta información para evaluar el uso del cliente de la página web y para confeccionar informes sobre las actividades del sitio web Outdoor Index. Dado el caso, este dato será transferido por Google a terceros, si así lo requiera la ley. Lo mismo se aplica en el caso de la divulgación a terceros como consecuencia de la puesta en servicio de los mismo Google. A menos que se impida la instalación de cookies modificando la configuración del navegador, pero se enfrentarán a limitaciones de uso del sitio web. Mediante el uso de nuestro sitio web usted está de acuerdo, en principio, con el tratamiento de información de usted por Google en la forma descrita anteriormente y para los fines arriba indicados.",
      "when_you_accept_this": "Cuando Ud. acepta esta Política de Privacidad, autoriza expresamente a Outdoor Index a tratar sus datos, es decir, registrarlos; transmitir sus antecedentes personales a los proveedores finales del servicio(s), para gestionar sus reservas y/o solicitudes de compra; a enviarle comunicaciones publicitarias, promocionales u ofertas y a recolectar datos estadísticos. En su calidad de usuario, Ud. tendrá la oportunidad de suministrar a Outdoor Index información relacionada con sus hábitos de viaje, dirección de email para entrega de voucher, información sobre facturación de tarjetas de crédito, y otra información personal de interés. Los datos que usted nos suministra nos ayuda a servir sus necesidades de planificación y seguridad de su viaje. La naturaleza “extrema” de algunas de las excursiones y actividades que se comercializan en nuestra plataforma web Outdoor Index nos exigen tener presentes esos datos. Le contactaremos a través de su dirección de correo electrónico para enviarle mensajes relacionados a sus ordenes de compra y preparación del viaje."
    },
    "foot": {
      "elimination": "<strong>X) ELIMINACIÓN Y REVOCACIÓN DEL CONSENTIMIENTO:</strong>",
      "if_you_request": "Si usted lo solicita, le daremos en cualquier momento y gratuitamente, información sobre qué datos se almacenan en Outdoor Index sobre usted. Usted podrá, cuando así lo desea, desde su perfil de usuario, eliminar o corregir sus datos. También puede retirar su consentimiento para la recopilación, el uso y el tratamiento de sus datos. Ante cualquier pregunta o comentario podrá contactarnos en <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>.",
      "newsletter": "<strong>XI) Mails de Noticias</strong>",
      "viewnia_com_has_decided": "Outdoor Index ha decidido inscribir por defecto al mail de noticias, a todos los usuarios al momento de registrarse. Esto con el fin de mantener a los usuarios informados de las ofertas, promociones y productos recomendados que esta plataforma ofrece. En el caso de que algún usuario quisiera desuscribirse puede modificar sus preferencias en el panel de usuario, dentro de su sesión o directamente en la opción desuscribirse ubicado en la zona inferior de los mails."
    },
    "head": {
      "cookie_preferences": "<strong>Actualiza las preferencias de tus cookies</strong>",
      "responsible_for_the_protection": "<strong>Responsable de la protección de sus datos personales:</strong> Garals Turismo Austral Limitada con domicilio en Blanco Encalada 722, Natales, Región de Magallanes, Chile.",
      "title": "Declaración de Privacidad",
      "viewnia_com_values_its": "Outdoor Index valora a sus usuarios y proveedores. Por lo cual está comprometido a salvaguardar su confidencialidad. En el desempeño de dicho compromiso, Outdoor Index ha desarrollado esta Política de Privacidad, que describe las políticas y prácticas de Outdoor Index en lo que se refiere a la recolección y divulgación de información personal en su plataforma web."
    },
    "last": {
      "keep_in_mind_that": "Tenga presente que cuando coloca información en un área pública de esta página (por ejemplo: comentarios sobre excursiones, fotografías electrónicas con hashtag, etc) la está poniendo a disposición de otros miembros, usuarios de la página y al público en general; lo cual queda fuera del ámbito de control de Outdoor Index. Por favor, recuerde lo anterior y sea cuidadoso con la información que decide divulgar.",
      "public_information": "<strong>IX) INFORMACIÓN PÚBLICA:</strong>"
    }
  },
  "products": "Productos",
  "products_search": {
    "activities": "Actividades",
    "activities_sub_label": "Escoge tu actividad",
    "all_adventures": "Todas las aventuras",
    "clear": "Limpiar selección",
    "date": "Fecha",
    "date_sub_label": "¿Cuando quieres ir?",
    "in": "en",
    "regions": "Regiones",
    "regions_sub_label": "¿Qué región quieres visitar?",
    "search": "Buscar",
    "subtext": "Explora todos los tours y excursiones disponibles. Planifica tu itinerario, reserva y disfruta de las maravillas de nuestra naturaleza.",
    "time": "Tiempo",
    "time_sub_label": "Selecciona tu preferencia de horario!",
    "tours": "tours"
  },
  "profiles": "Perfiles",
  "promotions": {
    "coupon_apply": "Aplicar",
    "coupon_code_already": "El cupón ya ha sido aplicado",
    "coupon_code_destroy_confirm": "¿Esta seguro que quiere eliminarlo?",
    "coupon_code_destroy_error": "No se ha podido eliminar el código",
    "coupon_code_destroy_ok": "Código eliminado satisfactoriamente",
    "coupon_code_error": "El cupón no existe",
    "coupon_code_max": "Máximo un cupón por órden",
    "coupon_code_ok": "Cupón aplicado correctamente",
    "coupon_form_palceholder": "Código de descuento",
    "coupon_have_a_coupon": "¿Tiene un cupón de descuento?"
  },
  "puerto_natales": "Puerto Natales",
  "puerto_williams": "Puerto Williams",
  "punta_arenas": "Punta Arenas",
  "ransack": {
    "all": "todos",
    "and": "y",
    "any": "cualquier",
    "asc": "ascendente",
    "attribute": "atributo",
    "combinator": "combinador",
    "condition": "condición",
    "desc": "descendente",
    "or": "o",
    "predicate": "predicado",
    "predicates": {
      "blank": "está en blanco",
      "cont": "contiene",
      "cont_all": "contiene todos",
      "cont_any": "contiene cualquier",
      "does_not_match": "no coincide con",
      "does_not_match_all": "no coincide con todos",
      "does_not_match_any": "no coincide con ninguno",
      "end": "termina en",
      "end_all": "termina en todos",
      "end_any": "termina en cualquier",
      "eq": "es igual a",
      "eq_all": "es igual a todos",
      "eq_any": "es igual a cualquier",
      "false": "es falso",
      "gt": "mayor que",
      "gt_all": "mayor que todos",
      "gt_any": "mayor que cualquier",
      "gteq": "mayor que o igual a",
      "gteq_all": "mayor que o igual a todos",
      "gteq_any": "mayor que o igual a cualquier",
      "in": "en",
      "in_all": "en todos",
      "in_any": "en cualquier",
      "lt": "menor que",
      "lt_all": "menor o igual a",
      "lt_any": "menor que cualquier",
      "lteq": "menor que o igual a",
      "lteq_all": "menor o igual a todos",
      "lteq_any": "menor o igual a cualquier",
      "matches": "coincide con",
      "matches_all": "coincide con todos",
      "matches_any": "coincide con cualquier",
      "not_cont": "no contiene",
      "not_cont_all": "no contiene todos",
      "not_cont_any": "no contiene ninguno",
      "not_end": "no termina en",
      "not_end_all": "no termina en todos",
      "not_end_any": "no termina en cualquier",
      "not_eq": "no es igual a",
      "not_eq_all": "no es igual a todos",
      "not_eq_any": "no es igual a cualquier",
      "not_in": "no en",
      "not_in_all": "no en todos",
      "not_in_any": "no en cualquier",
      "not_null": "no es nulo",
      "not_start": "no comienza con",
      "not_start_all": "no comienza con todos",
      "not_start_any": "no comienza con cualquier",
      "null": "es nulo",
      "present": "está presente",
      "start": "comienza con",
      "start_all": "comienza con todos",
      "start_any": "comienza con cualquier",
      "true": "es verdadero"
    },
    "search": "buscar",
    "sort": "ordenar",
    "value": "valor"
  },
  "region": {
    "lakes_and_rivers_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "north_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc.",
    "south_patagonia_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit dui, egestas scelerisque sapien et, interdum lobortis nunc."
  },
  "registration": {
    "all_vouchers": "Mis vouchers",
    "change_my_password": "Cambiar mi contraseña",
    "change_your_password": "Cambio de contraseña",
    "confirm_new_password": "Confirmar nueva contraseña",
    "confirm_password": "Confirmar contraseña",
    "current_password": "Contraseña actual",
    "edit_user": "Editar usuario",
    "email": "Email",
    "interests": "Intereses (Puedes escoger más de uno)",
    "lastname": "Apellido",
    "name": "Nombre",
    "new_password": "Nueva contraseña (min 8 caracteres, letra, número)",
    "notification": "Configuración de notificaciones",
    "profile_picture": "Foto perfil",
    "profiles": "Perfiles (Puedes escoger más de uno)",
    "registration_excerpt": "Administra tu perfil de la manera que quieras. Habilita o deshabilita tus notificaciones y define tus intereses. De esta forma, podrás personalizar los contenidos que ves en Outdoor Index.",
    "save_settings": "Guardar",
    "update_account": "Actualizar cuenta",
    "vouchers": "Vouchers"
  },
  "remember_me": "Recuérdame",
  "rental": {
    "add_images_(minimum_3)": "Agregar imágenes (mínimo 3)",
    "category": "Categoría",
    "description": "Sobre el producto",
    "meeting_address": "Dirección",
    "policies": "Políticas de cancelación",
    "price": "Precio",
    "save_product": "Salvar producto",
    "title": "Título",
    "vacancies": "Cupos",
    "zone": "Zona"
  },
  "request": {
    "applicants": "Participantes",
    "comments": "Comentarios",
    "comments_placeholder": "Deja tus preguntas, dudas o lo que sea que quieras decirnos. Te contactaremos lo antes posible!",
    "contact_email": "Correo contacto",
    "contact_name": "Nombre contacto",
    "contact_phone": "Teléfono contacto",
    "email_footer": "Este formulario ha sido enviado a nuestros ejecutivos. Serás contactado a través de los datos que ingresaste en el formulario de contacto. Si existe algún error u observación en el formulario, por favor, envíanos un correo a info@outdoorindex.cl y te responderemos a la brevedad.",
    "email_title": "Solicitud de Excursión Privada",
    "excursion": "Excursion",
    "from": "Desde",
    "greeting": "Hola! Esta informacción ha sido enviada a tu bandeja de correo registrada con copia a uno de nuestros agentes. Nos contactaremos contigo lo antes posible a través de la información que nos enviaste.",
    "must_log_in": "Debes iniciar sesión antes de poder pagar",
    "order_mail": {
      "disclaimer": "Serás redirigido a la ventana de pagos de Outdoor Index a través de una conexión segura.",
      "excursion": {
        "agency": "Operador local",
        "title": "Excursión"
      },
      "footer": "Ahora puedes ir a la sección de pagos con el botón de pago.                                                                                                                                                                                                                                                       .",
      "header": {
        "hi": "Hola! Tenemos noticias sobre tu solicitud de excursión!",
        "ready": "está disponible para ser pagada.",
        "title": "Tu solicitud ha sido aceptada por el operador local. Por favor, lee la información que se muestra a continuación y asegúrate que todo esté correcto.",
        "your_order": "Tu orden N°"
      },
      "manual_order": {
        "observations": "Lee los comentarios que te dejó el agente Outdoor Index!",
        "price": "Precio",
        "price_clp": "Precio CLP",
        "price_usd": "Precio USD"
      },
      "pay": "Pagar",
      "request_details": "Los detalles de tu solicitud"
    },
    "phone": "Teléfono de contacto",
    "product_required": "Para solicitar pedido, debes hacer login previamente",
    "requester": "Solicitante",
    "singular": "Solicitud",
    "submit": "Enviar Solicitud",
    "to": "Hasta"
  },
  "request_form": {
    "contact_viewnia_subtitle": "Completa este formulario para empezar el proceso que nos permitirá coordinar tu excursión con nuestro especialista en terreno.",
    "title": "Solicitar Excursión"
  },
  "reservations": {
    "(me)": "(Yo)",
    "a_list_of_all_your_reservations": "Todas tus reservas",
    "actions": "Acciones",
    "add_guest_details": "Agregar info turista",
    "assign_excerpt": "En esta sección debes indicar qué viajero va a qué excursión. De esta manera, tendremos toda la información necesaria para brindarte la mejor experiencia posible.",
    "assign_tourists": "3) Asigna turistas",
    "assing_tourists": "Asigna turistas",
    "cancel_date": "Cancelar reserva",
    "cart_empty": "Tu mochila de viajes está vacía",
    "cart_subtitle": "Lista de productos en tu mochila de viajes",
    "cart_title": "Información de tu viaje",
    "confirmed_excerpt": "¡Gracias por viajar con nosotros!",
    "confirmed_excerpt_litle": "Consulta el voucher para ver los detalles de tu compra.",
    "create_your_travel_group": "2) Grupo de viaje >",
    "departure_date": "Día de partida",
    "details_of_purchase": "Detalle de tu compra",
    "edit_your_reservation": "Edita tu reserva",
    "go_to_checkout": "Continuar",
    "itinerary_excerpt": "Aquí puedes encontrar una lista de todos tus productos. Asegúrate de que toda la información sea correcta para continuar con los próximos pasos. Importante: todas las horas y fechas se muestran en el horario chileno.",
    "list_of_products": "Lista de productos",
    "many_few_tourists": "Los turistas asignados no coincide con el número de reservas",
    "my_group": "Mi grupo",
    "new_checkout_excerpt": "Aquí tienes la lista de productos en tu mochila de viajes. No te olvides de agregar a las o los pasajeros en las Excursiones en que participarán. Antes de comprar, revisa bien la cantidad de reservaciones y sus costos. Recuerda leer nuestra Política de cancelación.",
    "not_enough": "no tiene suficientes",
    "organize_your_itinerary": "1) Itinerario >",
    "pay_with": "Pagar con",
    "people": "Pasajeros:",
    "people_(including_you)": "Participantes (Incluido tu)",
    "price_chilean": "Valor total residentes en Chile:",
    "price_no_chilean": "Valor total no residentes en Chile:",
    "product_for": "El producto",
    "reservations_made": "#",
    "reserves": "Reservas",
    "see_details": "Ver detalles",
    "select_your_extras": "Selecciona tus extras:",
    "single_supplement": "Suplemento individual:",
    "team_member": "Miembros del grupo",
    "too_many": "tiene demasiados",
    "total_price": "Precio total",
    "tourists_assigned": "turistas asignados",
    "tourists_group": "En esta sección tienes que agregar la información de todas las personas que te acompañarán. Es muy importante que la información adjunta sea lo más confiable posible.",
    "update": "Actualizar",
    "you_have_no_reservations": "No tienes ninguna reserva"
  },
  "reset_password": "Restablecer contraseña",
  "responsive": {
    "more": "Más..."
  },
  "reviews": {
    "footer": "Ver en TrustPilot",
    "more": "Más",
    "review": "Reseña",
    "reviewing_excursion": "Deja tu reseña",
    "thanks_for_submitting": "Gracias por tu reseña",
    "title": "Reseñas"
  },
  "search": "Buscar",
  "searches": {
    "all_category_products": "Todas las experiencias de \"%{category_name}\"",
    "all_category_products_no_result": "Perdona, por ahora no hay experiencias de \"%{category_name}\"",
    "all_products_in_zone": "Todos las experiencias en \"%{zone_name}\"",
    "all_products_in_zone_no_result": "Perdona, no hay experiencias en \"%{zone_name}\"",
    "category_products_in_zone": "\"%{category_name}\" en \"%{zone_name}\"",
    "category_products_in_zone_no_result": "Perdona, por ahora no hay \"%{category_name}\" en \"%{zone_name}\"",
    "no_result_to": "Perdona, no hay resultados para \"%{category_name}\" en \"%{zone_name}\"",
    "please_select_category_or_zone": "Por favor selecciona una categoría o zona",
    "recommended_for_you": "Experiencias para hacer en \"%{zone_name}\""
  },
  "select_multiple": "Selecciona varios",
  "select_one": "Selecciona uno",
  "select_two": "2 max",
  "send": "Enviar",
  "sidebar_single": {
    "contact_viewnia": "Contáctanos",
    "disclaimer": "Asegura tu lugar ahora pagando solo <strong>el 30% del valor total de la reserva.</strong>",
    "from": "desde",
    "if_you_are": "si eres nuevo.",
    "log_in": "Inicia sesión",
    "no_dates_available": "No hay fechas disponibles",
    "or": "o",
    "price_for": "Por persona",
    "select_dates": "Selecciona fecha",
    "share": "Tienes preguntas sobre la excursión? Contáctanos aquí:",
    "sign_up": "Regístrate",
    "to_make_a": "Para hacer una reserva por favor "
  },
  "sign_up": "Regístrate",
  "signature": {
    "content": {
      "by_applying_my_electronic": "Al utilizar mi firma electrónica en este acuerdo, acepto que esta sea el vínculo legal equivalente a mi firma manuscrita en papel. No reclamaré, en ningún momento futuro, que mi firma electrónica no sea legalmente vinculante a este acuerdo. Al firmar electrónicamente y enviar este acuerdo, yo 1) reconozco que he leído y entiendo completamente los términos del acuerdo; 2) voluntariamente acepto respetar este acuerdo; y 3) certifico que soy mayor de 18 años de edad o más. Mi firma se aplica a todas las páginas de este documento."
    },
    "foot": {
      "apply_signature": "Aplicar ahora",
      "i_understand_that_i_will": "Entiendo que recibiré, al momento de firmar, una versión de este acuerdo en formato de archivo PDF en la dirección de correo electrónico que proporcioné. Para ver el documento PDF, entiendo que necesitaré un software que me permita recibir y acceder a archivos PDF, como por ejemplo software Adobe Reader u otro software capaz de leer un archivo PDF. Para imprimir y conservar una copia impresa de este acuerdo, entiendo que también necesitaré una impresora conectada a mi computadora. Entiendo que si deseo firmar una copia impresa de este acuerdo en lugar de una versión electrónica, debo comunicarme directamente con la parte que requiere mi firma en este acuerdo.",
      "save": "Guardar",
      "yes_i_agree": "Si, acepto."
    },
    "title": {
      "electronic_signature_authorization": "Autorización de firma electrónica:"
    }
  },
  "social_share_button": {
    "delicious": "Delicious",
    "douban": "Douban",
    "email": "Correo electrónico",
    "facebook": "Facebook",
    "google_bookmark": "Google Bookmark",
    "google_plus": "Google+",
    "hacker_news": "Hacker News",
    "linkedin": "Linkedin",
    "odnoklassniki": "Odnoklassniki",
    "pinterest": "Pinterest",
    "qq": "Qzone",
    "reddit": "Reddit",
    "share_to": "Compartir vía %{name}",
    "telegram": "Telegram",
    "tumblr": "Tumblr",
    "twitter": "Twitter",
    "vkontakte": "Vkontakte",
    "wechat": "WeChat",
    "wechat_footer": "Abra su WeChat, haga clic en el botón \"Descubrir\" y luego haga clic en el menú \"Escanear código QR\".",
    "weibo": "Sina Weibo",
    "whatsapp_app": "WhatsApp",
    "whatsapp_web": "WhatsApp",
    "xing": "Xing"
  },
  "soon": "Próximamente",
  "subtotal": "Subtotal:",
  "support": {
    "array": {
      "last_word_connector": " y ",
      "two_words_connector": " y ",
      "words_connector": ", "
    }
  },
  "survey_page": {
    "description": "Nos gustaría conocer tu opinión sobre el servicio que recibiste. Tu retroalimentación es muy importante para nosotros.",
    "tilte": "Outdoor Index - Encuesta de postventa"
  },
  "terms": {
    "content": {
      "any_purchase_made": "Con 90 días o más antes de la fecha de inicio del programa, se emitirá un reembolso del 100%. Entre 89 y 70 días antes de la fecha de inicio del programa, solo se reembolsará el 50% del monto pagado. Entre 69 y 45 días antes de la fecha de inicio del programa, solo se reembolsará el 20% del monto pagado. 44 días antes de la fecha de inicio del programa o menos, no se realizarán reembolsos. En caso de no presentarse (no-show), no se emitirán reembolsos.",
      "cancellation": "<strong>5) ANULACIÓN, CANCELACIÓN O CAMBIOS:</strong>",
      "example": "En caso de cancelación:",
      "general_info": "<strong>1) INFORMACIÓN GENERAL:</strong>",
      "in_the_case_of_annulment": "Las reservas son nominativas y no son transferibles.",
      "methods": "<strong>3) MEDIOS DE PAGO:</strong>",
      "of_the_100%_paid": "En cuanto a las tarifas de entrada al Parque Nacional Torres del Paine, dado que se adquieren a una entidad externa que no permite modificaciones de fechas, nombres ni cancelaciones, su costo no será reembolsado en ningún caso.",
      "our_cancellation_system": "Nuestro sistema de cancelación se basa en porcentajes entre el día de la compra y el día inicial de la reserva.",
      "payment": "<strong>2) PAGO Y RESERVAS:</strong>",
      "purchase": "<strong>4) PROCESO DE COMPRA / RESERVA:</strong>",
      "purchase-one": "<strong>4.1) PAGO:</strong>",
      "the_payment_methods": "Outdoor Index utiliza dos opciones de pago validadas internacionalmente PayPal y WebPay. Estas aparecerán en forma previa a su solicitud de compra. Cuando el consumidor ingresa los datos de su cuenta o tarjeta y acepta la operación, con ello autoriza el cargo por precio y/o tarifa, el pago del cargo por nuestro servicio y el cargo por uso de la tarjeta de crédito o débito. Si Ud. efectúa una compra en cuotas mediante una tarjeta de crédito, tenga presente que: El cargo por nuestro servicio y el impuesto relacionado al uso de la tarjeta de crédito se aplica en la primera cuota; Los intereses, impuestos, comisiones u otros cargos que pudieran generar las compras en cuotas mediante tarjetas de crédito, dependerán de las condiciones que el consumidor haya pactado con cada banco o emisor de tarjeta. Le recomendamos informarse de las condiciones de uso y de interés de su tarjeta de crédito antes de utilizar modalidad de compra en cuotas.",
      "the_process_begins": "El proceso se inicia con la compra generada por el usuario desde nuestro sitio. Validada la compra, en nuestros sistemas de pago, el usuario recibirá en su correo electrónico la confirmación de la compra y un archivo pdf con su Voucher. En este se detallará toda la información sobre su producto y se adjuntarán los datos de la agencia local de turismo. Ante cualquier inconveniente nuestro equipo de atención al cliente se pondrá en contacto con el turista.",
      "the_quotes_related": "Los impuestos relacionados al uso de tarjeta de crédito varían según el tipo: Si utiliza WebPay pagarás 2,95% (pesos chilenos) por la transacción, y 5.4% + 0,30 USD si utiliza PayPal.",
      "the_reservations": "Las reservas realizadas con menos de 70 días antes de la fecha de inicio del programa deben pagarse en su totalidad (100%) dentro de las siguientes 48 horas hábiles después de la confirmación por parte del ejecutivo. Una vez recibido el pago, no se pueden realizar cambios ni cancelaciones.",
      "the_total_cost": "El 30% del costo total del programa se solicitará para iniciar el proceso de reserva. El 70% restante debe pagarse a más tardar 70 días antes de la fecha de inicio del programa, que también es la fecha límite para realizar cambios en la reserva.",
      "viewnia_com_is_an_online": "Outdoor Index es una plataforma de venta de excursiones outdoors que presta el servicio de intermediación entre las agencias locales de turismo y los clientes (turistas) que hacen uso de la web Outdoor Index. Informamos sobre los productos que los proveedores turísticos realizan especificando sus características y condiciones, gestionamos sus solicitudes de reservas y compras, recaudamos los valores correspondientes a los precios e intermediamos en el caso de dudas o en la búsqueda de soluciones cuando se presenta una inquietud o un inconveniente. Los proveedores turísticos (Agencias de turismo), son quienes realizan finalmente el producto comprado por el consumidor en la plataforma <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong>.",
      "when_you_contract": "Cuando contratas un servicio a través de nuestra de Outdoor Index, debes pagar el equivalente a la suma del precio o tarifa del servicio turístico, más los impuestos correspondientes por el uso de una tarjeta de crédito (según se aplique). El pago se puede realizar de dos formas en nuestra plataforma: pagando el 100% del valor del producto o pagando el 30% del producto para generar la reserva. Solicitaremos el 70% restante del pago dentro de un período de tiempo variable, dependiendo de la proximidad de la fecha solicitada. Esto puede variar desde 48 horas después de la confirmación de la disponibilidad hasta con 70 días de anticipación. Es importante que, antes de aceptar la transacción, prestes atención y leas detenidamente la información relacionada con las tarifas válidas y las políticas de cancelación o cambios descritas en cada excursión o servicio turístico ofrecido en Outdoor Index, antes de instruirnos a proceder con tu compra."
    },
    "foot": {
      "all_images_and_texts": "Todas las imágenes y textos subidos a la plataforma van a ser cuidadosamente revisados por el equipo de Outdoor Index con el objetivo de que cumplan todos los estándares de calidad propuestos por la marca. No se permitirán imágenes de baja resolución, oscuras, borrosas, collages, con marcas de agua, con logotipos, con filtros, en blanco y negro, con textos escritos, donde gente haga poses, etc. Como tampoco se permitirán textos que pretendan hacer link directos con las empresas (nombres, sitios web, emails, etc) saltándose la estructura comercial propuesta por Outdoor Index. Outdoor Index se reserva el derecho a modificar o eliminar cualquier información subida a la plataforma que no cumpla con estos requerimientos.",
      "information_on_the": "<strong>8) INFORMACIÓN EN LA PLATAFORMA:</strong>"
    },
    "head": {
      "conditions": "Términos del Servicio",
      "you_as_a_consumer": "Ud. como consumidor tiene el deber de informarse sobre la documentación que podría necesitar para poder realizar su viaje conforme a lo planeado. Verifique con tiempo si requiere pasaporte, visa, vacunas, permiso para menores u otras exigencias de documentos tanto para la entrada a Chile, como los países en tránsito. Si la requiere y no dispone de ella, haga los trámites necesarios para obtenerla con anticipación."
    },
    "last": {
      "all_cancellation_requests": "Toda solicitud de cancelación y eventual devolución debe de hacerse por escrito al correo electrónico <a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a>. Outdoor Index mediará entre el consumidor y el proveedor del servicio con el objetivo de hacer valer los términos que rigen en caso de anulaciones o cancelaciones.",
      "card": "<strong>7) TARJETA MIGRATORIA:</strong>",
      "changes_covid": "<strong>5.2) CAMBIOS EN LA RESERVA:</strong>",
      "changes_covid_text": "Se puede reagendar sin costo con opción de cambio de fecha de estadia hasta Abril de la misma temporada o anular una reserva hasta 45 días antes de la fecha de check-in, Individuales y Grupos. Todas las modificaciones en las reservas, fuera de los plazos de anulación y pago, que generen un valor inferior a la cotización original, no serán reembolsables. Si el cambio de reserva fuese un valor superior a la cotización original, esta diferencia de precio deberá ser pagado.",
      "claims": "<strong>6) RECLAMOS:</strong>",
      "covid": "<strong>5.1) ANULACIONES POR COVID-19:</strong>",
      "covid_text": "En caso de rebrote por COVID-19, que implique cierre de fronteras; cierre del Parque Nacional Torres del Paine, cuarentena nacional o el cliente se contagie con COVID-19 (presentando el debido certificado médico que lo acredite), fuera de los plazos de anulación establecidos, Outdoor Index ofrece reagendar la fecha de la reserva sin costo para una estadía hasta Abril de la misma temporada.",
      "migratory_card": "Pasajeros extranjeros: Recuerde si no presenta su pasaporte y tarjeta migratoria (papel blanco entregado por la policía al entrar a Chile), deberá pagar el IVA correspondiente.",
      "no_show": "<strong>5.3) NO SHOW:</strong>",
      "no_show_text": "El check-in es a las 15:00 hrs y check-out a las 09:30 hrs. Los huéspedes deben presentarse en los alojamientos antes de las 21:00 hrs. en la fecha indicada en la reserva. Si no se presenta en el horario indicado, Outdoor Index se reservará el derecho de realizar el cobro del 100% del valor de la reserva, por motivo de No Show, además se puede disponer nuevamente de la reserva para la venta.",
      "the_consumer_will_be": "El consumidor podrá enviar su reclamo al correo electrónico <strong><a href=\"mailto:info@outdoorindex.cl\">info@outdoorindex.cl</a></strong>. Se recomienda adjuntar de inmediato la copia de los antecedentes de respaldo, explicando lo ocurrido y formulando en términos claros y simples la solución que desea. De esta manera podremos revisar su caso en forma más rápida y eficiente. Outdoor Index analizará el reclamo y dará respuesta al mismo correo electrónico del consumidor dentro de un plazo de 7 días hábiles a contar de la recepción de los antecedentes. En caso de requerir un plazo superior para recopilar antecedentes o más información, le informará al consumidor, especificando el plazo adicional. El propósito de Outdoor Index es ejercer el servicio de intermediario entre las partes con el objetivo de resolver lo mas rápidamente cualquier problema o molestia generada por alguno de los productos o servicios contratados a través de la plataforma web <strong><a href=\"https://www.outdoorindex.cl\">www.outdoorindex.cl</a></strong>.",
      "the_previous_statement": "* En caso de anulación o cancelación de reservas confirmadas, el cargo por servicio de intermediación y el impuesto pagado por la transferencia bancaria no están sujetos a reintegro, ya que corresponden a un servicio efectivamente prestado por Outdoor Index. Lo anterior se aplica a todos los productos y actividades siempre y cuando no se diga lo contrario en la descripción de los productos en el ítem “políticas de cancelación”."
    }
  },
  "tierra_del_fuego": "Tierra del Fuego",
  "time": {
    "am": "am",
    "formats": {
      "daterangepicker": "DD/MM/YYYY",
      "default": "%d/%m/%Y",
      "full": "%a, %d %B / %Y",
      "long": "%d %B %Y",
      "short": "%a %e %b"
    },
    "pm": "pm"
  },
  "total": "Total a pagar:",
  "total_final": "Total final",
  "total_only": "Total",
  "total_paid": "Total pagado",
  "tourist": {
    "allergies": "Alergias",
    "date_of_birth": "Fecha de nacimiento",
    "email": "Email",
    "emergency_contact": "Contacto de emergencia",
    "food_restrictions": "Restricciones alimenticias",
    "hometown": "Ciudad donde vive",
    "last_name": "Apellido",
    "medical_conditions": "Condición medica",
    "name": "Nombre",
    "nationality": "Nacionalidad",
    "new_tourist_excerpt": "En esta sección puedes agregar la información de todas las personas que te acompañarán. Es muy importante que la información adjunta sea lo más confiable posible.",
    "no_reservations_to_assign": "No hay reservas para asignar",
    "passport": "Número de pasaporte",
    "pending": "Pendiente",
    "personal_info": "Información personal",
    "phone": "Teléfono",
    "ready": "Listo",
    "you_have_no_vouchers": "No tienes vouchers activos"
  },
  "tourists": {
    "first_create_group": "Ooops! No olvides llenar la información de los pasajeros."
  },
  "uploader": {
    "drop_files_here": "Arrastra tu archivo aquí",
    "processing_dropped_files": "Procesando archivos...",
    "upload_a_file": "Sube un archivo"
  },
  "usd": "USD",
  "vacancies": "Cupos",
  "value": "1300 mt",
  "value_included": "Ej: Guía bilingue",
  "view_more": "Ver más...",
  "view_more_excursions": "Ver más excursiones...",
  "view_more_suggestions": "Ver más sugerencias...",
  "vouchers": {
    "active_excerpt": "Aquí se muestra un detalle de tus vouchers. Puedes verlos e imprimirlos. Recuerda presentarte el día de la reserva con tu voucher impreso, indicando el número de registro de este.",
    "active_vouchers": "Vouchers activos",
    "activities": "Actividades",
    "all_of_your_vouchers": "Todos tus comprobantes",
    "complete": "Completo",
    "confirmed_excerpt": "Muchas gracias por tu compra. Esperamos que tengas un hermoso viaje. No olvides imprimir tu comprobante y siempre llévalo contigo. Ante cualquier consulta o situación inesperada no dudes en contactarnos.",
    "disclaimer": "En este PDF tienes toda la información de tus productos. Por favor revisa el documento y si tienes alguna pregunta, contáctanos. Disfruta tu viaje.",
    "error": "Algo salió mal con el proceso de pago. Vuelve a intentarlo más tarde o contáctenos para ver otras opciones de pago.",
    "get_your_voucher": "Obten tu voucher",
    "home": "Inicio",
    "incomplete": "Incompleto",
    "order_number": "Nro Orden:",
    "payment_error": "Error de pago",
    "payment_rejected": "Pago rechazado",
    "print": "Imprimir",
    "rejected": "PayU / Paypal ha indicado que tu pago fue rechazado, verifica tus datos y vuelve a intentarlo, o contáctanos para otras opciones de pago.",
    "restart_payment": "Volver a realizar pago",
    "view": "Ver",
    "voucher": "Comprobante",
    "was_partial_payment": "(2)"
  },
  "waiver": {
    "content": {
      "by_submitting_this_release": "Al aceptar este formulario, estoy de acuerdo en liberar de cualquier responsabilidad a Outdoor Index, sus guías, proveedores, consultores, agentes y directores, por cualquier daño o lesión, físico o mental, que puede resultar de mi participación en las excursiones y actividades contratadas a través de la plataforma www.outdoorindex.cl.",
      "i_assume_full_responsibility": "Asumo toda la responsabilidad por posible lesión corporal, muerte, pérdida de bienes personales o cualquier otro riesgo, incluyendo pero no limitado a los causados por el sendero, el terreno, el tiempo, mi condición física y mental u otros participantes o guías.",
      "i_recognize_that_there": "Reconozco que hay un riesgo significativo en cualquier aventura, deporte o actividad asociada con la vida al aire libre. Conozco los riesgos, peligros y rigores inherentes implicados en las excursiones. Certifico que soy totalmente capaz de participar en las actividades. Entiendo que no seré forzado a participar en ninguna actividad que me haga sentir incomodo. Además entiendo que mi imagen, por fotografía o vídeo, puede ser utilizado para propósitos promocionales.",
      "viewnia_com_excursions_you_are": "Las excursiones de Outdoor Index, a las que te estas inscribiendo, implican actividades exigentes desde el punto de vista físico y emocional en un entorno al aire libre. A veces puede incluir escalar, saltar y otras actividades rigurosas en lugares naturales o en estructuras hechas por el hombre que se encuentran en el suelo o a bajas, medianas o altas distancias del suelo. Serás acompañado por un Guía y por otras personas en un grupo. Existe la posibilidad que te lesiones mientras participas en las excursiones, debido a tu propia conducta, conducta de otros en el grupo, conducta del guía o debido a las condiciones de las instalaciones. Queremos asegurarnos de que comprendes los riesgos implícitos de esta actividad antes de decidir participar en ella. Es necesario que leas cuidadosamente lo siguiente, asegúrate de entenderlo y firmarlo antes de comprar alguna de las excursiones de Outdoor Index."
    },
    "foot": {
      "i_acknowledge_that_i": "RECONOZCO QUE HE TENIDO LA OPORTUNIDAD DE HACER PREGUNTAS CON RESPECTO A CUALQUIER ASPECTO DE ESTE WEIVER Y AL ACEPTAR ESTE FORMULARIO CONFIRMO QUE LO HE LEÍDO COMPLETAMENTE Y ENTIENDO TODOS LOS ASPECTOS CONTENIDOS EN EL. ESTOY DE ACUERDO CON SUS TÉRMINOS EN SU TOTALIDAD."
    },
    "last": {
      "by_submitting_this_release": "Al aceptar este formulario, asumo que si sufro lesión física o daños mentales de cualquier naturaleza como resultado de mi decisión de participar en las actividades de Outdoor Index. Yo, voluntariamente, libero a las partes nombradas anteriormente de cualquier responsabilidad. Esta liberación también se aplica a posibles acciones a realizar por mis herederos, representantes personales y asociados."
    },
    "title": {
      "waiver_of_liability_agreement": "Acuerdo de responsabilidades"
    }
  },
  "webpay": {
    "error_alert": "Ha ocurrido un error con Webpay, por favor verifique si su orden se encuentra pagada.",
    "error_dec": "Hubo un error en el procesamiento del pago, por favor inténtalo nuevamente. Si el problema persiste ponte en contacto con nosotros.",
    "error_title": "Transacción rechazada"
  },
  "welcome": "¡Bienvenido a Outdoor Index!",
  "what": "¿Qué?",
  "when": "¿Cuando?",
  "where": "¿Dónde?",
  "zone": "Zona"
});

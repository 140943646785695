import i18n from 'i18n-js'
export function duration_time_by_duration_type(product, should_show_duration_type) {
    let duration_type = i18n.t("excursions." + product.duration_type)
    let duration_text = []

    if(product.duration_days > 0){
        duration_text.push(`${product.duration_days} ${i18n.t("excursions.days", {count: product.duration_days})}`)    
    }
    if(product.duration_hours > 0){
        duration_text.push(`${product.duration_hours} ${i18n.t("excursions.hours", {count: product.duration_hours})}`)
    }

	return duration_text.join(", ")  + (should_show_duration_type ? (". " + duration_type) : "")
}
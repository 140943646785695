import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'

function Guidebook(props){
  let guide_image = "//daes5xl8ji16n.cloudfront.net/production/assets/guidebook-9bbe9e9ef7a5d4df4cc91ef3df511a619008833cbb121bed941b8c796c65e4c3.jpg";
  return (
    <section className="advertise">
        <div className="guidebook">
            <div className="image">
            <img src={guide_image} alt="Guide book" width="230" height="230" />
            </div>
            <div className="text">
                <h1>{ I18n.t('guidebook.download_our_guide_book')}</h1>
                <p>{ I18n.t('guidebook.find_out_everything')}</p>
                
                <div className="actions">
                    {props.current_user && 
                        <button type='submit' className="primary"><a target="_blank" href="https://s3.amazonaws.com/view-patagonia/manual_uploads/GuideBook+Viewnia.com.zip">{I18n.t('home.download')}</a></button>
                        ||
                        <button type='submit' className="primary"><a href="/users/sign_up">{I18n.t("home.download") }</a></button>
                    }	
                </div>
            </div>
        </div>
    </section>
  );
}

export default Guidebook


import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import Post from "components/posts/Post";

function Posts(props){
  
  const [last_post, setLastPost] = useState({});
  const [posts, setPosts] = useState([]);
  

  useEffect(() => {
    get_posts();
  }, []);

  useEffect(() => {
  }, [last_post]);

  function get_posts(){
    return fetch(`posts.json`)
      .then(response => response.json())
      .then(json => {
        setLastPost(json.data.last_post)
        setPosts(json.data.posts)
      }
      )
      .catch(error => console.log(error));
  }
  return (

        
        <section className="posts-blog">
          <header><h1>{I18n.t('blog.travel_nature_growth')}</h1></header>
          {last_post && 
            <section className="first-post-grid-container">
              <section className="first-post-image">
                <figure>
                  <a href="#" title="View Details">
                    <img className="lazyload" 
                    src={last_post.cover_f}
                    data-original={last_post.cover_f}/>
                  </a>
                </figure>
              </section>
              <section className="first-post-body">
                  <div className="first-post-info">
                      <h4>{last_post.label}</h4>
                      <h3><a href="#" title="View Details">{last_post.title}</a></h3>
                      <p className="subtile">
                          {last_post.subtitle}
                      </p>
                      <p className="author">
                          <strong>{last_post.author_name}</strong> | {last_post.author_sm}
                      </p>
                      <p className="date">
                          {last_post.created_at_f}
                      </p>
                  </div>
              </section>
            </section>
          }

          {posts && 
            <section className="posts-grid-container">
              {posts.map((post) => {
                  const labelId = `post-label-${post.id}`;
                  return (
                    <Post 
                      key={labelId}
                      post={post}
                    />
                  );
              })}
            </section>
          }
        </section>

  
  );
}

export default Posts
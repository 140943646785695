import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'

function Carousel(props){

  return (
    <section className="caroussel">
        {props.carousel_type}
    </section>
  );
}

export default Carousel
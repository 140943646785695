import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';
import Rating from '@material-ui/lab/Rating';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';


// Import Swiper styles
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

function CarouselHomeReviews(props){
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    get_reviews();
  }, []);

  function get_reviews(){
    setLoading(true)
    return fetch(`/get_reviews.json${props.home_collection_id ? '?home_collection_id='+props.home_collection_id : ''}`)
      .then(response => response.json())
      .then(json => {
        setReviews(json.reviews)
        setLoading(false)
      }
      )
      .catch(error => console.log(error));
  }
  
  
  return (
    <ThemeProvider theme={themeViewnia}>

        {!props.home_collection_id &&
            <setion className='posts'>
              <header key={"home_collection_id" + props.home_collection_title}>
                  <h2>{props.home_collection_title}</h2>
                  <div className="subtitle">{props.home_collection_subtitle}</div>
              </header>
            </setion>
          }
        <section className="caroussel">

            <section className="products-section">
            {loading && 
                <section id="skeletons-loader" className={classes.root}>     
                    <Skeleton variant="rect" className="region-loader" width={424} height={275} />
                    <Skeleton variant="rect" className="region-loader" width={424} height={275} />
                    <Skeleton variant="rect" className="region-loader" width={424} height={275} />
                </section> ||
                <>
                <div className="swiper-button-prev" ref={navigationPrevRef}></div>
                <div className="swiper-button-next" ref={navigationNextRef}></div>
                <Swiper
                navigation
                mousewheel={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log('swiper')}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) =>
                  {       swiper.params.navigation.prevEl = navigationPrevRef.current;
                          swiper.params.navigation.nextEl = navigationNextRef.current;
                  }}

                breakpoints={{
                  "425": {
                    "slidesPerView": 1,
                    "spaceBetween": 34
                  },
                  "640": {
                    "slidesPerView": 2,
                    "spaceBetween": 34
                  },
                  "768": {
                    "slidesPerView": 2,
                    "spaceBetween": 34
                  },
                  "1024": {
                    "slidesPerView": 2.5,
                    "spaceBetween": 34
                  },
                  "1440": {
                    "slidesPerView": 3,
                    "spaceBetween": 34
                  }
                }}
                >
                    {reviews && reviews.map((review) => {
                        const labelId = `reviews-label-${review.id}`;
                            return (
                                <SwiperSlide key={labelId}>
                                    <div className="review-card" >
                                        <div className="review-content">
                                            <div className="review-head">
                                                <figure className={props.home_class}>

                                                <img className={""}
                                                    alt={props.alt ? props.alt : ""}
                                                    src={review.cover_url}
                                                    
                                                />
                                                </figure>
                                                <div className="review-author">
                                                    <span className="review-name">{review.author}</span>
                                                    <p className="review-country">{review.country}</p>
                                                    <p className="review-date">{ review.review_date && new Date(review.review_date).toLocaleDateString(review.language)}</p>
                                                </div>    
                                            </div>
                                            <Rating name="read-only" className='rating-in-front' value={review.rating} readOnly />
                                            <p className="review-text"><span dangerouslySetInnerHTML={{ __html: review.body }} /></p>
                                            <p className="review-footer"><a href={review.url} target="_blank">★ {i18n.t('reviews.footer')}</a></p>   
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                
                </Swiper>
                </>
                }

           
            </section>
        </section>

    </ThemeProvider>
  );
}

export default CarouselHomeReviews

import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'

function Newsletter(props){
  const [newsletter, setNewsletter] = useState({});

  useEffect(() => {
    get_newsletter();
  }, []);

  function get_newsletter(){
    return fetch(`newsletter.json`)
      .then(response => response.json())
      .then(json => {
        setNewsletter(json)
      }
      )
      .catch(error => console.log(error));
  }
    let botton = null
    if(props.current_user){
      botton = <button type='submit' className="primary"><a href="/users/sign_up">{I18n.t("newsletter.view_preferences") }</a></button>					 
    }else{
      botton = <button type='submit' className="primary"><a className="" href="/users/edit">{I18n.t("newsletter.sign_up_now") }</a></button>
    }
   
  return (
        
   <section className="enrollment">
        { newsletter &&
            <img src={newsletter.cover} alt="" />
        }
      
        <div className="signup">
            <h1>{ I18n.t('newsletter.sign_up_at')}</h1>
            <p>{ I18n.t('newsletter.we_value_your_privacy')}</p>
            {botton}  
        </div>
    </section>
  );
}

export default Newsletter
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';


// Import Swiper styles
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);
import HomeImage from "components/home/HomeImage";
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

function CarouselActivities(props){
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    get_activities();
  }, []);

  function get_activities(){
    setLoading(true)
    return fetch(`/get_activities.json`)
      .then(response => response.json())
      .then(json => {
        setActivities(json.activities)
        setLoading(false)
      }
      )
      .catch(error => console.log(error));
  }
  
  
  return (
    <ThemeProvider theme={themeViewnia}>

        <section className="caroussel">
            <section className="products-section">
            {loading && 
                <section id="skeletons-loader" className={classes.root}>     
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                  <Skeleton className='activity-loader' />
                </section> ||
                <>
                <div className="swiper-button-prev" ref={navigationPrevRef}></div>
                <div className="swiper-button-next" ref={navigationNextRef}></div>
                <Swiper
                navigation
                mousewheel={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log('swiper')}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) =>
                  {       swiper.params.navigation.prevEl = navigationPrevRef.current;
                          swiper.params.navigation.nextEl = navigationNextRef.current;
                  }}

                breakpoints={{
                  "320": {
                    "slidesPerView": 2,
                    "spaceBetween": 12
                  },
                  "425": {
                    "slidesPerView": 2,
                    "spaceBetween": 12
                  },
                  "640": {
                    "slidesPerView": 3.5,
                    "spaceBetween": 12
                  },
                  "768": {
                    "slidesPerView": 3.5,
                    "spaceBetween": 12
                  },
                  "1024": {
                    "slidesPerView": 5.5,
                    "spaceBetween": 12
                  },
                  "1440": {
                    "slidesPerView": 8,
                    "spaceBetween": 12
                  }
                }}
                >
                    {activities && activities.map((activities) => {
                        const labelId = `activities-label-${activities.id}`;
                            return (
                                <SwiperSlide key={labelId}>
                                    <a href={ `/categories/${activities.slug ? activities.slug : activities.id}` /*`/products?activity=${activities.id}`*/} className="product-activities-image flex-center" target="_blank" title="View Details" target="_blank">
                                        <span className="product-white-label flex-center-absolute">{activities.name}</span>    
                                    </a>
                                </SwiperSlide>
                            );
                        })}
                
                </Swiper>
                </>
                }

           
            </section>
        </section>

    </ThemeProvider>
  );
}

export default CarouselActivities

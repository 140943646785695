import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import LandingImage from "components/products/LandingImage";

function Landing(props){
   const [slug, setSlug] = useState("");
   useEffect(() => {
    if(props.landing){
        if(props.landing?.slug){
            setSlug(props.landing?.slug)
        }
    }
}, [props.landing]);

  return (
        <>
                <div className="landing-card w-100">
                    <LandingImage
                        key={"landing-image-"+ props.landing?.id}
                        landing = {props.landing}
                        image_format={props.image_format}
                        skeleton_height={(props.skeleton_height ? props.skeleton_height : 320)}
                        slug={slug}
                    />
                    <div className="landing-text-block">
                        {
                        props.landing.place &&
                            <h3 className="zone"><a href={"/landings/" + (slug != null ? slug : props.landing?.id)} title="View Details" target="_blank">{props.landing.title}</a></h3>
                        }
                        
                        <p><a href={"/landings/" + (slug != null ? slug : props.landing?.id)} title="View Details" target="_blank">{props.landing?.place}</a></p>
                        
                    </div>
                </div>
        </>
  );
}

export default Landing

import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import {DefaultImage} from "components/utils/DefaultImage.js.erb";
import Skeleton from '@material-ui/lab/Skeleton';
function HomeCover(props){

    const [cover, setCover] = useState({});
    const [imageSRC, setImage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loadClass, setLoadClass] = useState("hidden");



    function onError(){
      //setImage(DefaultImage)
      //setLoaded(true)
      //setLoadClass("")
    }

    function onLoad(){
        setLoadClass("")
        setLoaded(true)
    }

  

  useEffect(() => {
    get_cover();
  }, []);


  function get_cover(){
    return fetch(`covers.json`)
      .then(response => response.json())
      .then(json => {
          setCover(json)
          setImage(json.cover_url)
      }
      )
      .catch(error => console.log(error));
  }
    
  return (
    <section className="carousel">
        {
            !loaded &&
              <Skeleton variant="rect" className="lazyload" height={459} />
        }
        <section className="hero-slider ready home-slides-container">
            <article className={"slide "  + loadClass}>
                <img 
                  className={""} 
                  alt="1"
                  alt={"Outdoor index"}
                  src={imageSRC}
                  loading="eager"
                  onError={ () => onError()}
                  onLoad={ () => onLoad()}
                  style={{ width: "1343px", height: "459px" }}
                />
                <div className="slide-info">
                  <p>{cover.message}</p>
                  <h1>{cover.title}</h1>
                  <a className="call-to-action" title="" href={cover.button_url} target="_blank" rel="noopener noreferrer">  
                    {cover.button_message}
                    <span className="material-symbols-outlined">arrow_right_alt</span>
                  </a>
                </div>
            </article>
        </section>
    </section>
  );
}

export default HomeCover


import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'

function Post(props){
  
  const [post, setPost] = useState(props.post);

  return (
        
    <article className="post-grid">	
        {post.cover_f &&
            <figure>
                <a href="#" title="View Details">
                    <img className="lazyload" 
                    src={post.cover_f}
                    data-original={post.cover_f}/>
                </a>
            </figure>
        }
        <div className="info-slide">
            <h4>{post.label} </h4>
            <h3><a href="#" title="View Details">{post.title}</a></h3>
            <p className="subtile">
                {post.subtitle} 
            </p>
            <p className="author">
                <strong>{post.author_name} </strong> | {post.author_sm}
            </p>
            {/* <p className="date">
                {post.created_at_f} 
            </p> */}
        </div>
    </article>

  
  );
}

export default Post
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import Landing from "components/products/Landing";
function HomeLanding(props){
  const [home_collection, setHomeCollection] = useState([]);
  const [landings, setlandings] = useState([]);


    useEffect(() => {
      if(props.home_collection){
          setHomeCollection(props.home_collection)
      }
    }, [props.home_collection]);

    useEffect(() => {
        if(props.landings){
            setlandings(props.landings)
        }
    }, [props.landings]);
  

    const labelId = `home_collection-child-label-${home_collection.id}`;
    let slide_subclass = "slide-container-two" 
    let image_format = "medium"
    let skeleton_height = 320
    if (landings.length == 5){ 
        slide_subclass = "col-xs-12 col-sm-4 col-md" 
        image_format = "medium"
        skeleton_height = 342
    } else if (landings.length > 4){ 
        slide_subclass = "col-xs-12 col-sm-4 col-md-3" 
        image_format = "medium"
        skeleton_height = 433
    }else if (landings.length == 4) {
        slide_subclass = "col-xs-12 col-sm-4 col-md-3" 
        image_format = "medium"
        skeleton_height = 433
    }else if (landings.length == 3){ 
        slide_subclass = "col-xs-12 col-sm-6 col-md-4" 
        image_format = "large"
        skeleton_height = 330
    }else if (landings.length == 2){ 
        slide_subclass = "col-xs-12 col-sm-6 col-md-6" 
        image_format = "large"
        skeleton_height = 500
    }else{ 
        slide_subclass = "col-xs-12 col-sm-12 col-md-12" 
        image_format = "large"
    }
    
  return (
    <section className='posts'>
        {home_collection &&
            <>
              {props.is_from_home != false &&
                <header key={labelId}>
                    <h2>{home_collection.name}</h2>
                    <div className="subtitle">{home_collection.subtitle}</div>
                    <div className="more_index"><a className="more" href={"/landings?home_collection_id="+ home_collection.id} target="_blank" rel="noopener noreferrer">{home_collection.link_text}</a> <span className="material-symbols-outlined">arrow_right_alt</span></div>
                </header>
              }

              <section id="landing" className={"row"}>



                {landings && landings.length > 0 && landings.map((landing) => {
                  return (
                    <article className={"col " + slide_subclass}>
                        <Landing
                        key={"landing" + landing.id}
                        landing={landing}
                        image_format={image_format}
                        skeleton_height={skeleton_height}
                        curent_locale={props.current_locale}
                        />
                    </article>
                  );
                })}
              </section>
            </>

        }
    </section>
  );
}

export default HomeLanding